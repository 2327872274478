import React, { useEffect, useState } from 'react';
import { authUtil } from '../../../SharedLib/Util/AuthUtil';
import { AuthError, isAuthError } from '../../../SharedLib/Util/Interfaces';
import CustomButton from '../../CustomButton/CustomButton';
import styles from './ChangePasswordForm.module.scss';
import { PWD_RULES } from '../../../SharedLib/Util/ValidationUtil';
import { getQueryParamNamed } from '../../../SharedLib/Util/QueryParamsUtil';
import { messageForError } from '../../../SharedLib/Util/ErrorUtil';
import { EyeSlash, Eye } from 'react-bootstrap-icons';
import Alert from '../../Alert/Alert';
import { LoginView } from '../LoginPanel/LoginPanel';

export interface IResetPasswordFormProps {
  className?: string;
  setView: React.Dispatch<React.SetStateAction<LoginView>>;
}

const ChangePasswordForm: React.FC<IResetPasswordFormProps> = ({
  className,
  setView,
}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [rules, setRules] = useState(
    PWD_RULES.map((rule) => ({ ...rule, isSatisfied: false })),
  );
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [anyErrors, setAnyErrors] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = (
    event: React.MouseEvent<HTMLSpanElement>,
  ) => {
    event.preventDefault();
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisibility = (
    event: React.MouseEvent<HTMLSpanElement>,
  ) => {
    event.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    const updatedRules = rules.map((rule) => ({
      ...rule,
      isSatisfied: rule.regexp.test(password),
    }));
    setRules(updatedRules);
    if (password && confirmPassword) {
      setPasswordsMatch(password === confirmPassword);
    }
  }, [password, confirmPassword]);

  const allRulesSatisfied =
    rules.every((rule) => rule.isSatisfied) && passwordsMatch;

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const token = getQueryParamNamed('ref');
    const uname = getQueryParamNamed('uname');

    if (token === undefined || uname === undefined) {
      setAnyErrors(true);
      setError(
        'Sorry, looks like the reset password link has expired or is incorrect. Please try again.',
      );
      return;
    }
    const response = await authUtil.verifyResetPassword(
      uname.value,
      token.value,
      password,
    );

    if (isAuthError(response)) {
      setAnyErrors(true);
      const errorMsg = messageForError(response as AuthError);
      setError(errorMsg.msg);
      return;
    }

    setAnyErrors(false);
    setError('');
    setSuccess(true);
    return;
  }

  return (
    <div
      className={
        styles.reset_password_form + (className ? ` ${className}` : '')
      }
    >
      {success ? (
        <>
          <div className={styles.reset_password_header + ' ' + styles.text}>
            Done!
          </div>
          <div className={styles.reset_pasword_done}>
            <Alert
              message={'Success! Your password has been updated.'}
              type="success"
            />
            <CustomButton
              variant="primary"
              className={styles.login_button}
              onClick={() => setView(LoginView.LOGIN)}
            >
              Return to login
            </CustomButton>
          </div>
        </>
      ) : (
        <>
          <div className={styles.reset_password_header + ' ' + styles.text}>
            Reset your password
          </div>
          <form className={styles.password_form} onSubmit={handleSubmit}>
            <div className={styles.password_container}>
              <label className={styles.password_label + ' ' + styles.text}>
                New password
              </label>
              <div className={styles.password_input_group}>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  placeholder="password"
                  className={styles.password_input}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                />
                <span
                  className={styles.password_toggle_icon}
                  onClick={(event) => togglePasswordVisibility(event)}
                >
                  {passwordShown ? <EyeSlash /> : <Eye />}
                </span>
              </div>
            </div>

            <ul className={styles.validationHelper}>
              {rules.map((rule, index) => (
                <li
                  key={index}
                  className={rule.isSatisfied ? styles.satisfied : ''}
                >
                  {rule.title}
                </li>
              ))}
            </ul>

            <div className={styles.password_container}>
              <label className={styles.password_label + ' ' + styles.text}>
                Confirm new password
              </label>
              <div className={styles.password_input_group}>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="password"
                  className={styles.password_input}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setConfirmPassword(e.target.value)
                  }
                />
                <span
                  className={styles.password_toggle_icon}
                  onClick={(event) => toggleConfirmPasswordVisibility(event)}
                >
                  {showConfirmPassword ? <EyeSlash /> : <Eye />}
                </span>
              </div>
            </div>

            {password !== '' && confirmPassword !== '' && !passwordsMatch && (
              <div className={styles.passwordError}>Passwords do not match</div>
            )}

            {anyErrors && <div className={styles.passwordError}>{error}</div>}

            <div className={styles.reset_password_buttons}>
              <CustomButton
                width={'167px'}
                type="submit"
                className={styles.reset_password_button}
                isDisabled={!allRulesSatisfied}
              >
                Reset Password
              </CustomButton>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ChangePasswordForm;
