import React from 'react';
import { Textarea } from '@chakra-ui/react';
import { AccordionSection } from './AccordionSection';
import { IUserProfile } from '../../../userlib_new/utils/profile-interfaces';
import { useProfile } from '../../../../context/profileProvider';

interface IPersonaliaProps {
  userProfile: IUserProfile;
  inputBgColor?: string;
}
export const AboutMe = ({ userProfile, inputBgColor }: IPersonaliaProps) => {
  const profileData = useProfile();
  return (
    <AccordionSection title="About Me" sectionKey="aboutMe">
      <Textarea
        id="about-me"
        placeholder="Write a short description about yourself..."
        bg={inputBgColor}
        value={userProfile.aboutMe ?? ''}
        onChange={(e) => profileData.handleAboutMeChange(e.target.value)}
        size="lg"
        minHeight="150px"
        maxLength={1000}
        _dark={{ bg: '#3E4252' }}
      />
    </AccordionSection>
  );
};
