import React, { useEffect, useRef, useState } from 'react';
import styles from './Toast.module.scss';

export interface IToastProps {
  title: string;
  message: string;
  type: 'success' | 'warning' | 'danger' | 'info';
  displayToast?: boolean;
  onToastClosed: () => void;
}

const Toast: React.FC<IToastProps> = ({
  title,
  message,
  type,
  displayToast,
  onToastClosed,
}) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const clearToastTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  useEffect(() => {
    if (displayToast) {
      timerRef.current = setTimeout(onToastClosed, 6000);
    } else {
      clearToastTimer();
    }

    // Cleanup
    return clearToastTimer;
  }, [displayToast, onToastClosed]);

  if (!displayToast) return null;

  return (
    <div
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      style={{ position: 'relative' }}
    >
      <div
        className={`${styles.toast} ${styles[type]} show`}
        style={{ position: 'fixed', top: '1rem', right: '1rem' }}
      >
        <div className={styles['toast-header']}>
          <strong className="mr-auto">{title}</strong>
          <button
            type="button"
            className={styles.close}
            aria-label="Close"
            onClick={onToastClosed}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className={styles['toast-body']}>{message}</div>
      </div>
    </div>
  );
};

export default Toast;
