import React from 'react';
import {
  Box,
  Text,
  Switch,
  Input,
  Icon,
  InputGroup,
  InputRightElement,
  Grid,
  FormHelperText,
  FormControl,
} from '@chakra-ui/react';
import { IUserProfile } from '../../../userlib_new/utils/profile-interfaces';
import { useProfile } from '../../../../context/profileProvider';
import { BsCheck, BsX } from 'react-icons/bs';
import { getPublicProfileUrl } from '../../../../utils/pathsUtil';

interface IProfileProps {
  userProfile: IUserProfile;
  borderColor?: string;
  inputBgColor?: string;
}

const PublicProfile = ({ borderColor, inputBgColor }: IProfileProps) => {
  const profileData = useProfile();

  return (
    <>
      <Box
        mx="auto"
        my={5}
        p={{ base: 2, md: 6 }}
        width={{ base: '99%', '2xl': 'full' }}
        maxW={'1150px'}
        borderWidth="1px"
        borderRadius="lg"
        borderColor={borderColor}
        boxShadow="lg"
        _dark={{ borderColor: 'white', bg: '#282A35' }}
        bg={'white'}
      >
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'auto minmax(auto, 1fr) auto',
          }} // Adjust for medium and above screens
          gap={4}
          alignItems="center"
        >
          <Text>Public Profile</Text>
          <Box
            display={{ base: 'block', md: 'flex' }}
            alignItems="center"
            gap={4}
          >
            <Text
              fontSize="xs"
              wordBreak="keep-all"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              minWidth="180px"
            >
              {getPublicProfileUrl()}/
            </Text>

            <FormControl isInvalid={profileData.isNicknameAvailable === false}>
              <InputGroup flexGrow={1}>
                <Input
                  id="contact"
                  placeholder="Public Profile URL"
                  bg={inputBgColor}
                  value={profileData.nickname || ''}
                  onChange={(e) =>
                    profileData.handleNicknameChange(e.target.value)
                  }
                  isInvalid={profileData.isNicknameAvailable === false}
                  _dark={{ bg: '#3E4252' }}
                />
                {profileData.isNicknameAvailable !== null && (
                  <InputRightElement mr={10}>
                    <Icon
                      as={profileData.isNicknameAvailable ? BsCheck : BsX}
                      color={
                        profileData.isNicknameAvailable
                          ? 'green.500'
                          : 'red.500'
                      }
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Switch
                    id="switch"
                    size="md"
                    colorScheme="green"
                    isChecked={profileData.publicProfileVisibility || false}
                    onChange={(e) =>
                      profileData.handlePublicProfileVisibility(
                        e.target.checked,
                      )
                    }
                  />
                </InputRightElement>
              </InputGroup>
              {profileData.isNicknameAvailable === false && (
                <FormHelperText color="#ec6f6f">
                  Your Nick is in use- please change
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default PublicProfile;
