import React, { useEffect } from 'react';
import LogoutFullPage from '../../SharedLib/Component/LogoutFullPage/LogoutFullPage';

function Logout() {
  useEffect(() => {
    document.title = 'Log out - W3Schools';
  });

  return (
    <>
      <LogoutFullPage />
    </>
  );
}
export default Logout;
