import React from 'react';
import { Box, VStack, HStack, Text, Icon } from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';
import { IconType } from 'react-icons';
import { IActivityScore } from '../../../userlib_new/utils/profile-interfaces';
import { AccordionSection } from './AccordionSection';

interface IActivityScoreProps {
  activityScores: IActivityScore;
}

export const ActivityScore = ({ activityScores }: IActivityScoreProps) => {
  const renderScoreItem = (label: string, score: number, icon?: IconType) => (
    <VStack spacing={0}>
      <Text fontSize={{ base: 'xs', md: 'sm' }} fontWeight="bold">
        {icon && <Icon as={icon} color="yellow.400" />} {label}
      </Text>
      <Text fontSize={{ base: 'lg', md: 'xl' }}>{score}</Text>
    </VStack>
  );

  return (
    <AccordionSection title="Activity Score" sectionKey="activity">
      <Box boxShadow="md" p={3} my={2}>
        <HStack
          justifyContent="space-between"
          spacing={{ base: 2, md: 8 }}
          wrap="wrap"
        >
          {renderScoreItem('STARS', activityScores.stars, FaStar)}
          {renderScoreItem('LESSONS', activityScores.lessonsRead)}
          {renderScoreItem('EXERCISES', activityScores.exercisePoints)}
          {renderScoreItem('QUIZZES', activityScores.quizPoints)}
          {renderScoreItem('TOTAL', activityScores.totalPoints)}
        </HStack>
      </Box>
    </AccordionSection>
  );
};
