import { EnvType, getEnv } from './environment';

export const getApiBaseUrl = (env?: EnvType): string => {
  switch (env ?? getEnv()) {
    case 'dev':
      return 'https://api.w3sapi-dev.com';
    case 'stage':
      return 'https://api.w3sapi-stage.com';
    default: // prod
      return 'https://api.w3sapi.com';
  }
};

export const getKaiApiBaseUrl = (env?: EnvType): string => {
  switch (env ?? getEnv()) {
    case 'dev':
      return 'https://api.kai.w3sapi-dev.com';
    case 'stage':
      return 'https://api.kai.w3sapi-stage.com';
    default: // prod
      return 'https://api.kai.w3sapi.com';
  }
};

export const getPathfinderApiUrl = (env?: EnvType): string => {
  return `${getKaiApiBaseUrl(env)}/pathfinder`;
};

export const getGroupsApiUrl = (env?: EnvType): string => {
  switch (env ?? getEnv()) {
    case 'dev':
      return 'https://groups-api.w3sapi-dev.com';
    case 'stage':
      return 'https://groups-api.w3sapi-stage.com';
    // case 'pilot':
    //   return 'https://groups-api.w3spilot.com';
    default: // prod
      return 'https://groups-api.w3sapi.com';
  }
};

export const getOrganizationsApiUrl = (env?: EnvType): string => {
  switch (env ?? getEnv()) {
    case 'dev':
      return 'https://organizations-api.w3sapi-dev.com';
    case 'stage':
      return 'https://organizations-api.w3sapi-stage.com';
    default: // prod
      return 'https://organizations-api.w3sapi.com';
  }
};

export const getKaiApiUrl = (env?: EnvType): string => {
  return `${getKaiApiBaseUrl(env)}/kai`;
};

export const getGoalIdentificationUrl = (env?: EnvType) => {
  return getPathfinderApiUrl(env);
};

export const getCompetencyUrl = (env?: EnvType) => {
  return getPathfinderApiUrl(env);
};

export const getUserSkillsUrl = (env?: EnvType) => {
  return getPathfinderApiUrl(env);
};

export const getInterviewsBaseUrl = (env?: EnvType) => {
  return getPathfinderApiUrl(env);
};

export const getMessagingApiUrl = (env?: EnvType): string => {
  return `${getApiBaseUrl(env)}/messaging`;
};

export const getNotificationUrl = (env?: EnvType) => {
  return getMessagingApiUrl(env);
};

export const getMessageUrl = (env?: EnvType) => {
  return getMessagingApiUrl(env);
};

export const getBillingUrl = (env?: EnvType) => {
  switch (env ?? getEnv()) {
    case 'dev':
      return 'https://billing-dev.w3schools.com';
    case 'stage':
      return 'https://billing-stage.w3schools.com';
    default: // prod
      return 'https://billing.w3schools.com';
  }
};

export const getUpgradeLink = () => {
  return `${getBillingUrl()}/products/spaces`;
};

export const getBuyTokensUrl = () => {
  return `${getBillingUrl()}/addons/tenk-kai-tokens`;
};

export const getSpacesUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://spaces.w3schools.com';

    case 'stage':
      return 'https://spaces-stage.w3schools.com';

    case 'dev':
      return 'https://spaces-dev.w3schools.com';

    default:
      return 'https://spaces.w3schools.com';
  }
};

export const getSpacesPreviewUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'w3spaces.com';

    case 'stage':
      return 'w3stages.com';

    case 'dev':
      return 'w3sdevelop.com';

    default:
      return 'w3spaces.com';
  }
};

export const getMyLearningUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://my-learning.w3schools.com';

    case 'stage':
      return 'https://myl-stage.w3schools.com';

    case 'dev':
      return 'https://myl-dev.w3schools.com';

    default:
      return 'https://my-learning.w3schools.com';
  }
};

export const getClassicUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://www.w3schools.com';

    case 'stage':
      return 'https://ssf18kfg1.w3schools.com';

    case 'dev':
      return 'https://kte9uk46s.w3schools.com';

    default:
      return 'https://www.w3schools.com';
  }
};

export const getMyCertificatesUrl = () => {
  const myUrl = `${getMyLearningUrl()}/certificates`;
  return myUrl;
};

export const getTopNavBarUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://nav.w3schools.com';

    case 'stage':
      return 'https://nav-stage.w3schools.com';

    case 'dev':
      return 'https://nav-dev.w3schools.com';

    default:
      return 'https://nav.w3schools.com';
  }
};

export const getSpacesApiBaseUrl = (): string => {
  return `${getApiBaseUrl()}/spaces`;
};

/**
 * Get the public profile base url
 * @param myEnv OPTIONAL! Current environment
 * @returns
 */
export const getPublicProfileUrl = (myEnv?: EnvType): string => {
  const environment = myEnv ?? getEnv();
  switch (environment) {
    case 'dev':
      return 'https://www.w3profile-dev.com';
    case 'stage':
      return 'https://www.w3profile-stage.com';
    default:
      return 'https://www.w3profile.com';
  }
};

export const getProfileUrl = (myEnv?: EnvType): string => {
  const environment = myEnv ?? getEnv();
  switch (environment) {
    case 'dev':
      return 'https://profile-dev.w3schools.com';
    case 'stage':
      return 'https://profile-stage.w3schools.com';
    default:
      return 'https://profile.w3schools.com';
  }
};

export const getBillingApiUrl = (myEnv?: EnvType): string => {
  const environment = myEnv ?? getEnv();
  switch (environment) {
    case 'dev':
      return 'https://billing-api-dev.w3schools.com';
    case 'stage':
      return 'https://billing-api-stage.w3schools.com';
    default:
      return 'https://billing-api.w3schools.com';
  }
};

export const getJobAdPageBillingUrl = (myEnv?: EnvType): string => {
  const environment = myEnv ?? getEnv();
  switch (environment) {
    case 'dev':
      return 'https://billing-dev.w3schools.com';
    case 'stage':
      return 'https://billing-stage.w3schools.com';
    default:
      return 'https://billing.w3schools.com';
  }
};

/**
 * Get the My Learning api base url
 * @param myEnv OPTIONAL! Current environment
 * @returns
 */
export const getMyLearningApiUrl = (myEnv?: EnvType): string => {
  const environment = myEnv ?? getEnv();
  switch (environment) {
    case 'dev':
      return 'https://myl-api-dev.w3schools.com';
    case 'stage':
      return 'https://myl-api-stage.w3schools.com';
    default:
      return 'https://myl-api.w3schools.com';
  }
};

export const getCampusUrl = (myEnv?: EnvType): string => {
  const environment = myEnv ?? getEnv();
  switch (environment) {
    case 'dev':
      return 'https://w3schools.beta.instructure.com';
    case 'stage':
      return 'https://w3schools.beta.instructure.com';
    default:
      return 'https://campus.w3schools.com';
  }
};

export const getShareApiUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://share.w3profile.com';

    case 'stage':
      return 'https://share.w3profile-stage.com';

    case 'dev':
      return 'https://share.w3profile-dev.com';

    default:
      return 'https://share.w3profile.com';
  }
};

export const getSpacesLabApiUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://spaces-labs-api.w3spaces.com';

    case 'stage':
      return 'https://spaces-labs-api.w3stages.com';

    case 'dev':
      return 'https://spaces-labs-api.w3sdevelop.com';
    // return 'https://spaces-labs-api.w3sandbox.net';

    default:
      return 'https://spaces-labs-api.w3spaces.com';
  }
};

export const getCertApiUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://cert-api.w3schools.com';

    case 'stage':
      return 'https://cert-api-stage.w3schools.com';

    case 'dev':
      return 'https://cert-api-dev.w3schools.com';

    default:
      return 'https://cert-api.w3schools.com';
  }
};
