import React from 'react';
import { chakra } from '@chakra-ui/react';

const LowFiIconComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <rect
        x="0.87561"
        y="0.46875"
        width="27"
        height="27"
        rx="13.5"
        fill="#D3D8DC"
      />
      <path
        d="M14.3755 12.1506C14.3755 11.5923 14.8281 11.1398 15.3864 11.1398C15.9446 11.1398 16.3972 11.5923 16.3972 12.1506C16.3972 12.7088 15.9446 13.1614 15.3864 13.1614C14.8281 13.1614 14.3755 12.7088 14.3755 12.1506Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5492 9.52246C12.9981 9.52246 12.5598 9.52246 12.2062 9.55135C11.8441 9.58093 11.5353 9.64282 11.2526 9.78687C10.7961 10.0195 10.425 10.3906 10.1924 10.847C10.0483 11.1298 9.98645 11.4385 9.95686 11.8007C9.92797 12.1543 9.92798 12.5926 9.92798 13.1437V14.7964C9.92798 15.3475 9.92797 15.7858 9.95686 16.1394C9.98645 16.5015 10.0483 16.8103 10.1924 17.093C10.425 17.5495 10.7961 17.9206 11.2526 18.1532C11.5353 18.2972 11.8441 18.3591 12.2062 18.3887C12.5598 18.4176 12.9981 18.4176 13.5492 18.4176H15.2019C15.753 18.4176 16.1913 18.4176 16.5449 18.3887C16.907 18.3591 17.2158 18.2972 17.4985 18.1532C17.955 17.9206 18.3261 17.5495 18.5587 17.093C18.7027 16.8103 18.7646 16.5015 18.7942 16.1394C18.8231 15.7858 18.8231 15.3475 18.8231 14.7964V13.1437C18.8231 12.5926 18.8231 12.1543 18.7942 11.8007C18.7646 11.4385 18.7027 11.1298 18.5587 10.847C18.3261 10.3906 17.955 10.0195 17.4985 9.78687C17.2158 9.64282 16.907 9.58093 16.5449 9.55135C16.1913 9.52246 15.753 9.52246 15.2019 9.52246H13.5492ZM11.6197 10.5074C11.7694 10.4311 11.9599 10.3828 12.272 10.3573C12.5888 10.3314 12.9941 10.3311 13.5669 10.3311H15.1842C15.757 10.3311 16.1622 10.3314 16.4791 10.3573C16.7912 10.3828 16.9817 10.4311 17.1314 10.5074C17.4357 10.6624 17.6831 10.9099 17.8382 11.2142C17.9145 11.3639 17.9628 11.5544 17.9883 11.8665C18.0141 12.1833 18.0145 12.5886 18.0145 13.1614V14.7787C18.0145 14.9236 18.0144 15.0578 18.014 15.1825L17.3733 14.5418C17.0575 14.226 16.5455 14.226 16.2297 14.5418L15.3271 15.4444C15.2482 15.5233 15.1202 15.5233 15.0412 15.4444L12.5214 12.9245C12.2056 12.6087 11.6936 12.6087 11.3778 12.9245L10.7366 13.5657V13.1614C10.7366 12.5886 10.7369 12.1833 10.7628 11.8665C10.7883 11.5544 10.8366 11.3639 10.9129 11.2142C11.068 10.9099 11.3154 10.6624 11.6197 10.5074Z"
        fill="white"
      />
    </svg>
  );
};

const LowFiIcon = chakra(LowFiIconComponent);
export default LowFiIcon;
