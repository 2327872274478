import { extendTheme } from '@chakra-ui/react';
import type { ThemeConfig } from '@chakra-ui/react';
import ButtonStyleConfig from './overrides/Button';
import CloseButtonStyleConfig from './overrides/CloseButton';
import ModalStyleConfig from './overrides/Modal';

const fonts = {
  heading: `'Source Sans Pro', sans-serif`,
  text: `'Source Sans Pro', sans-serif`,
  body: `'Source Sans Pro', sans-serif`,
};

const colors = {
  progressBarDark: {
    500: '#282A35',
  },
  progressBarWarning: {
    200: '#ffb800',
    500: '#ffb800',
  },
};

const breakpoints = {
  base: '0px',
  sm: '320px',
  menu: '689px',
  md: '768px',
  lg: '993px',
  xl: '1200px',
  '2xl': '1536px',
};

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const chakraTheme = extendTheme({
  config,
  fonts,
  colors,
  breakpoints,
  components: {
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: 'progressBarDark',
        },
      },
    },
    Button: ButtonStyleConfig,
    CloseButton: CloseButtonStyleConfig,
    Modal: ModalStyleConfig,
  },
});

export default chakraTheme;
