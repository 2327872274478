import React from 'react';
import { useColorMode } from '@chakra-ui/react';

export interface IW3Info {
  className?: string;
  size?: string;
  color?: string;
}

function W3Info({ className, size, color }: IW3Info) {
  const { colorMode } = useColorMode();
  color = color ? color : colorMode === 'dark' ? '#fff' : 'grey';
  const iconSize = size ?? '28';
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={'0 0 1024 1024'}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ''}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M512 819.2a307.2 307.2 0 1 1 307.2-307.2 307.2 307.2 0 0 1-307.2 307.2z m0-573.44a266.24 266.24 0 1 0 266.24 266.24A266.6496 266.6496 0 0 0 512 245.76z"/>
      <path xmlns="http://www.w3.org/2000/svg" d="M491.52 450.56h40.96v204.8h-40.96zM491.52 368.64h40.96v40.96h-40.96z"/>
    </svg>
  );
}

export default W3Info;
