import React from 'react';

import Button from '../Button/Button';

import styles from './BackArrowButton.module.scss';

interface IBackArrowButtonProps {
  text: string;
  disabled?: boolean;
  handleOnClick?: (event: any) => void;
}

const BackArrowButton = ({
  text,
  disabled,
  handleOnClick,
}: IBackArrowButtonProps) => {
  const handleClick = (event: any) => {
    if (!!handleOnClick && !disabled) {
      handleOnClick(event);
    }
  };

  return (
    <Button
      variant="noframe"
      text={text}
      prefix="back"
      disabled={disabled}
      className={styles.back_button}
      onClick={handleClick}
    />
  );
};

BackArrowButton.defaultProps = {
  disabled: false,
  handleOnClick: () => {
    //
  },
};

export default BackArrowButton;
