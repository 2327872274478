// SkillsProvider.tsx
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useSkillsImmutable } from './SkillsImmutableProvider';
import { getMySkills } from '../utils/skills';
import { logging } from '../utils/LoggingUtil';
import {
  ParentSkillWithLevel,
  SkillWithLevel,
  filterSkillsWithLevel,
} from '../Component/userlib_new/utils/types-interfaces';

interface SkillsContextType {
  /**
   * A list of the users skills
   */
  skills: readonly SkillWithLevel[];
  filteredSkills: readonly ParentSkillWithLevel[];

  isLoading: boolean;
  getSkillLevel: (skillId: string) => number;
  /**
   * Fetch the users skills
   * @returns
   */
  getUserSkills: () => Promise<void>;

  /**
   * Boolean to check if my learning and certificate Migrated/convertion has been done
   */
  migrated:
    | {
        mylMigrated: boolean;
        certMigrated: boolean;
      }
    | undefined;

  setMigrated: (migrated: { mylMigrated: true; certMigrated: true }) => void;

  migrationInitiated?: true;
  setMigrationInitiated: (migrationInitiated: true) => void;
}

const SkillsContext = createContext<SkillsContextType | undefined>(undefined);

export const SkillsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getSkillById, isLoading: skillsImmutableLoading } =
    useSkillsImmutable();

  const [skills, setSkills] = useState<SkillWithLevel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [migrated, setMigrated] = useState<{
    mylMigrated: boolean;
    certMigrated: boolean;
  }>();
  const [migrationInitiated, setMigrationInitiated] = useState<true>();

  const getSkillLevel = useCallback(
    (skillId: string) => {
      const skill = skills.find((skill) => skill.id === skillId);
      return skill?.level ?? 0;
    },
    [skills],
  );

  const filteredSkills = useMemo(
    () =>
      skills.filter(
        (skill): skill is ParentSkillWithLevel =>
          getSkillById(skill.id)?.type === 'skill',
      ),
    [getSkillById, skills],
  );

  const getUserSkills = useCallback(async () => {
    try {
      setIsLoading(true);

      const { userSkills } = await getMySkills();
      setSkills(filterSkillsWithLevel(userSkills));
    } catch (error) {
      logging.logError('Error fetching skills:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const getAllSkillsData = async () => {
      try {
        setIsLoading(true);

        const { userSkills, ...migrated } = await getMySkills();

        setMigrated(migrated);
        setSkills(filterSkillsWithLevel(userSkills));
      } catch (error) {
        logging.logError('Error fetching skills:', error);
      } finally {
        setIsLoading(false);
      }
    };

    return void getAllSkillsData();
  }, []);

  return (
    <SkillsContext.Provider
      value={{
        skills,
        filteredSkills,
        isLoading: isLoading || skillsImmutableLoading,
        getSkillLevel,
        getUserSkills,
        setMigrated,
        migrated,
        migrationInitiated,
        setMigrationInitiated,
      }}
    >
      {children}
    </SkillsContext.Provider>
  );
};

export const useSkills = () => {
  const context = useContext(SkillsContext);
  if (!context) {
    throw new Error('useSkills must be used within a SkillsProvider');
  }
  return context;
};

export default SkillsProvider;
