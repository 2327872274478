import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const xxl = defineStyle({
  h: 'auto',
  w: '70%',
});

const ModalStyleConfig = defineStyleConfig({
  sizes: { xxl },
});

export default ModalStyleConfig;
