import React from 'react';

import BackArrow from '../../../userlib_new/assets/icons/userlib/BackArrow';
import ExternalLink from '../../../userlib_new/assets/icons/userlib/ExternalLink';
import W3Logo from '../../../userlib_new/assets/icons/userlib/W3Logo';

import Spinner, {
  SpinnerVariants,
} from '../../../../SharedLib/Component/Spinner/Spinner';

import styles from './Button.module.scss';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'destructive'
  | 'noframe'
  | 'pink'
  | 'dark';

type ButtonPrefix = 'back' | 'logo';

type ButtonSuffix = 'extlink';

type ButtonElemType = 'a' | 'button';

export interface IButtonProps {
  variant: ButtonVariant;
  text?: string;
  as?: ButtonElemType;
  onClick?: (e?: React.BaseSyntheticEvent) => void;
  href?: string;
  loading?: boolean;
  disabled?: boolean;
  prefix?: ButtonPrefix;
  suffix?: ButtonSuffix;
  fullwidth?: boolean;
  target?: string;
  children?: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button = ({
  variant,
  type = 'button',
  text = '',
  as = 'button',
  onClick = () => {
    return;
  },
  href = '',
  loading = false,
  disabled = false,
  prefix = undefined,
  suffix = undefined,
  fullwidth = false,
  target = undefined,
  className = undefined,
  children = undefined,
}: IButtonProps) => {
  const classes = () => {
    let myClasses = styles.button;

    myClasses += ` ${styles[variant]}`;

    if (fullwidth) {
      myClasses += ` ${styles.fullwidth}`;
    }

    if (!!prefix && prefix === 'logo') {
      myClasses += ` ${styles.centerItems}`;
    }

    if (disabled) {
      myClasses += ` ${styles.disabled}`;
    }

    if (className) {
      myClasses += ` ${className}`;
    }
    return myClasses;
  };

  const prefixElement = () => {
    if (prefix === undefined) {
      return undefined;
    }
    switch (prefix) {
      case 'back':
        return <BackArrow size={16} className={styles.prefix_icon} />;
      default:
        return (
          <W3Logo className={styles.prefix_icon} size="small" fill="white" />
        );
    }
  };

  const suffixElement = () => {
    if (suffix === undefined) {
      return undefined;
    }
    switch (suffix) {
      case 'extlink':
        return <ExternalLink className={styles.suffix_icon} size={16} />;
      default:
        return undefined;
    }
  };

  const getSpinnerVariant = () => {
    switch (variant) {
      case 'noframe':
      case 'pink':
        return SpinnerVariants.Dark;
      case 'tertiary':
      case 'secondary':
        return SpinnerVariants.Tertiary;
      default:
        return SpinnerVariants.Secondary;
    }
  };

  if (as === 'a') {
    return (
      <a href={href} target={target} className={classes()}>
        <div className={styles.wrapper}>
          {prefixElement()}

          <span>{text}</span>

          {children}

          {suffixElement()}

          {loading ? (
            <div className={styles.spin_wrapper}>
              <Spinner variant={getSpinnerVariant()} />
            </div>
          ) : (
            ''
          )}
        </div>
      </a>
    );
  }

  return (
    <button
      type={type || 'button'}
      className={classes()}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={styles.wrapper}>
        {prefixElement()}

        <span>{text}</span>

        {children}

        {suffixElement()}

        {loading ? (
          <div className={styles.spin_wrapper}>
            <Spinner variant={getSpinnerVariant()} />
          </div>
        ) : (
          ''
        )}
      </div>
    </button>
  );
};

export default Button;
