import React from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Divider,
  VStack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
  Center,
  Tooltip,
} from '@chakra-ui/react';
import { BsDownload, BsUpload } from 'react-icons/bs';
import { IUserProfile } from '../../../userlib_new/utils/profile-interfaces';
import { useProfile } from '../../../../context/profileProvider';
import CropperOriginal from 'react-easy-crop';
import W3sAvatar from '../../../W3sAvatar/W3sAvatar';
import {
  getPublicProfileUrl,
  getUpgradeLink,
} from '../../../../utils/pathsUtil';
import W3CoinIcon from '../../../icons/W3CoinIcon/W3CoinIcon';

interface IProfileProps {
  userProfile: IUserProfile;
  borderColor?: string;
}

const uniqueId = new Date().getTime();

const Cropper = CropperOriginal as any;
const ProfileHeader = ({ userProfile, borderColor }: IProfileProps) => {
  const profileData = useProfile();

  const triggerProfileImageInput = () => {
    const profileImageInput = document.getElementById('profileImageInput');
    if (profileImageInput) {
      profileImageInput.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      profileData.onFileSelected(event.target.files[0]);
    }
  };

  const triggerFileInput = () => {
    if (
      confirm(
        'Please note that uploading a new CV will overwrite existing profile details such as experience and education. Do you want to proceed?',
      )
    ) {
      const fileInput = document.getElementById('fileInput');
      if (fileInput) {
        fileInput.click();
      }
    }
  };

  return (
    <>
      <Box
        mx="auto"
        my={5}
        p={{ base: 2, md: 6 }}
        width={{ base: '99%', '2xl': 'full' }}
        maxW={'1150px'}
        borderWidth="1px"
        borderRadius="lg"
        borderColor={borderColor}
        boxShadow="lg"
        _dark={{ borderColor: 'white', bg: '#282A35' }}
        bg={'white'}
      >
        <VStack spacing={{ base: 2, md: 4 }} align="stretch">
          <Flex
            direction={{ base: 'column', md: 'row' }}
            align="center"
            gap={4}
          >
            <Box
              position="relative"
              borderRadius="full"
              boxSize="100px"
              overflow="hidden"
              className="group"
            >
              <W3sAvatar
                borderRadius="full"
                boxSize="100px"
                src={profileData.userProfile?.personal?.profilePicture ? `${getPublicProfileUrl()}/${profileData.userProfile?.personal?.profilePicture}?key=${uniqueId}` : ''}
              />
              {profileData.isProfileImageUploading && (
                <Center
                  position="absolute"
                  top="0"
                  right="0"
                  bottom="0"
                  left="0"
                  bg="rgba(255, 255, 255, 0.6)"
                >
                  <Spinner size="lg" />
                </Center>
              )}

              <Button
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                opacity={
                  profileData.userProfile?.personal?.profilePicture ? 0 : 1
                }
                _groupHover={{ opacity: 1 }}
                onClick={triggerProfileImageInput}
                display={
                  !profileData.userProfile?.personal?.profilePicture
                    ? 'block'
                    : ['none', 'none', 'block']
                }
                variant="outline"
                style={{
                  border: 'none',
                }}
                _hover={{
                  bg: 'transparent',
                  color: 'white',
                  border: 'none',
                }}
              >
                <Text fontSize="14">Upload</Text>
              </Button>

              <input
                type="file"
                id="profileImageInput"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={profileData.handleProfileImageChange}
              />
            </Box>

            <Modal
              isOpen={profileData.isModalOpen}
              onClose={() => profileData.setIsModalOpen(false)}
              size={['full', 'md', 'lg', 'xl']}
            >
              <ModalOverlay />
              <ModalContent maxW="90vw" maxH="80vh">
                <ModalHeader>Edit Image</ModalHeader>
                <ModalCloseButton />
                <ModalBody
                  minH="300px"
                  minW="200px"
                  maxH="70vh"
                  style={{ position: 'relative' }}
                >
                  {profileData.selectedImage && (
                    <Cropper
                      image={profileData.selectedImage}
                      crop={profileData.crop}
                      zoom={profileData.zoom}
                      aspect={1}
                      onCropChange={profileData.setCrop}
                      onZoomChange={profileData.setZoom}
                      onCropComplete={profileData.onCropComplete}
                      style={{ width: '100%', height: '100%' }}
                    />
                  )}
                </ModalBody>
                <ModalFooter>
                  <Flex justify="space-between" align="center" width="100%">
                    <Text textAlign="left" align="left" fontSize={12}>
                      Adjust the image by zooming in or out and dragging it to
                      the desired position.
                    </Text>
                    <Button
                      colorScheme="blue"
                      onClick={profileData.handleEditConfirm}
                    >
                      Confirm Edit
                    </Button>
                  </Flex>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Text fontSize="2xl" fontWeight="bold">
              {userProfile.personal?.firstName} {userProfile.personal?.lastName}
            </Text>
          </Flex>

          <Divider my={4} />

          <HStack justify="left" spacing={4}>
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              accept=".pdf" // Accept only PDF files
              onChange={handleFileChange}
            />
            <Button
              leftIcon={<BsUpload />}
              variant="outline"
              borderColor={borderColor}
              onClick={triggerFileInput}
              isLoading={profileData.isCVUploading}
              isDisabled={profileData.wallet <= 0}
            >
              Upload CV
            </Button>

            <Button
              leftIcon={<BsDownload />}
              variant="outline"
              borderColor={borderColor}
              onClick={profileData.handleDownloadCV}
              ml={4}
              isLoading={profileData.isDownloading}
            >
              Download CV
            </Button>
          </HStack>
          <>
            <Text fontSize={'xs'}>
              To upload your Curriculum Vitae (CV), kAI tokens are required.
              These tokens enable our system to efficiently parse and analyze
              your CV. Please ensure you have sufficient kAI tokens available
              before proceeding with the upload.
            </Text>
            <Tooltip label="Upgrade to get more tokens" placement="top-start">
              <Box
                as="a"
                href={getUpgradeLink()}
                target="self"
                rel="noopener noreferrer"
                textDecoration={'underline'}
                display={'flex'}
                alignItems={'center'}
                gap={'5px'}
                flexWrap={'nowrap'}
                _hover={{ color: '#04AA6D' }}
                aria-label={`Wallet: ${profileData.wallet} tokens. Click to buy tokens`}
              >
                {`${profileData.wallet <= 0 ? 'Upgrade to get more tokens ' : ''}${profileData.wallet}`}
                <W3CoinIcon />
              </Box>
            </Tooltip>
          </>
        </VStack>
      </Box>
    </>
  );
};

export default ProfileHeader;
