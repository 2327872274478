import { CustomTypeMod } from '../InterfaceAndTypeUtil';
import { fetch, IFetchPrs, IFetchResult } from '../FetchUtil';
import { EnvType, getEnv } from '../EnvironmentUtil';

export const getOrganizationsApiBaseUrl = (env?: EnvType): string => {
  switch (env ?? getEnv()) {
    case 'develop':
      return 'https://organizations-api.w3sapi-dev.com';
    case 'staging':
      return 'https://organizations-api.w3sapi-stage.com';
    default: // prod
      return 'https://organizations-api.w3sapi.com';
  }
};

export const fetchOrganizations = async<T>(prs: IFetchPrs): Promise<CustomTypeMod<IFetchResult, {
  data: T,
}>> => {
  prs.baseURL = getOrganizationsApiBaseUrl();

  if (typeof prs.authHeaderKey === 'undefined') {
    prs.authHeaderKey = 'Authorization';
  }

  return await fetch(prs);
};
