export type EnvType = 'prod' | 'stage' | 'dev';

export const getEnv = (): EnvType => {
  // logging.logDebug('getEnv -> _cachedEnv(1): ', _cachedEnv);

  if (typeof window !== 'undefined') {
    const host = window.location.hostname;

    switch (host) {
      case 'profile-dev.w3schools.com':
      case 'profile-local.w3schools.com':
      case 'localhost':
      case '127.0.0.1':
        return 'dev';
      case 'profile-stage.w3schools.com':
        return 'stage';
      default:
        return 'prod';
    }
  }

  return 'prod';
};
