import React from 'react';

export const W3sSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="125"
    height="117"
    viewBox="0 0 125 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_830_144)">
      <path
        d="M104.962 24.6237C104.962 24.6237 108.096 27.9815 112.473 27.9815C115.93 27.9815 118.442 25.8922 118.442 23.0319C118.442 19.5249 115.234 17.9082 111.354 17.9082H109.066L107.698 14.824L113.766 7.63584C115.06 6.09375 116.154 5.12372 116.154 5.12372C116.154 5.12372 115.184 5.17347 113.219 5.17347H103.345V0L123.566 0V3.78061L115.458 13.1575C120.034 13.8042 124.511 17.0625 124.511 22.808C124.511 28.4541 120.233 33.6773 112.846 33.6773C105.807 33.6773 101.902 29.25 101.902 29.25L104.962 24.6237Z"
        fill="black"
      />
      <path
        d="M65.514 31.6129L92.227 79.169L105.409 55.6645L80.1888 10.7697H50.8393L38.6767 32.4336L26.514 10.7697H0.124359L38.5274 79.169L38.6767 78.9203L38.8259 79.169L65.514 31.6129Z"
        fill="black"
      />
      <path
        d="M0.397888 111.404H4.30286C4.30286 112.548 5.29776 113.642 7.0637 113.642C8.70529 113.642 9.79967 112.821 9.79967 111.727C9.79967 110.782 9.07837 110.359 7.83475 110.085L5.59623 109.513C1.81562 108.518 0.845592 106.504 0.845592 104.365C0.845592 101.753 3.43233 99.3654 7.08858 99.3654C10.0733 99.3654 13.4559 100.883 13.4062 104.614H9.45146C9.45146 103.469 8.40682 102.748 7.21294 102.748C5.91957 102.748 5.02416 103.494 5.02416 104.539C5.02416 105.409 5.84495 105.907 6.81498 106.131L9.50121 106.852C13.2818 107.822 13.9534 110.185 13.9534 111.727C13.9534 115.135 10.5458 117 7.13832 117C3.83029 117 0.472506 114.985 0.397888 111.404Z"
        fill="black"
      />
      <path
        d="M17.4107 108.17C17.4107 102.848 21.6888 99.3654 26.5389 99.3654C29.449 99.3654 31.8119 100.659 33.3539 102.599L30.419 104.937C29.5485 103.892 28.1307 103.246 26.6135 103.246C23.7034 103.246 21.6141 105.335 21.6141 108.17C21.6141 110.981 23.7034 113.12 26.6135 113.12C28.1307 113.12 29.5485 112.473 30.419 111.429L33.3539 113.767C31.8119 115.707 29.449 117 26.5389 117C21.6888 117 17.4107 113.493 17.4107 108.17Z"
        fill="black"
      />
      <path
        d="M53.4508 107.151V116.552H49.2722V107.449C49.2722 104.763 47.6555 103.32 45.7901 103.32C43.8749 103.32 41.512 104.439 41.512 107.698V116.577H37.3335V90.6601H41.5369V102.325C42.3577 100.286 45.0688 99.3406 46.7353 99.3406C51.0631 99.3654 53.4508 102.276 53.4508 107.151Z"
        fill="black"
      />
      <path
        d="M57.8284 108.17C57.8284 102.848 61.9573 99.3654 66.7328 99.3654C71.5083 99.3654 75.6868 102.848 75.6868 108.17C75.6868 113.493 71.5083 117 66.7328 117C61.9573 117 57.8284 113.493 57.8284 108.17ZM71.4834 108.17C71.4834 105.285 69.3195 103.246 66.7328 103.246C64.146 103.246 62.0319 105.285 62.0319 108.17C62.0319 111.105 64.146 113.12 66.7328 113.12C69.3195 113.12 71.4834 111.105 71.4834 108.17Z"
        fill="black"
      />
      <path
        d="M79.2435 108.17C79.2435 102.848 83.3724 99.3654 88.1479 99.3654C92.9234 99.3654 97.1019 102.848 97.1019 108.17C97.1019 113.493 92.9234 117 88.1479 117C83.3972 117 79.2435 113.493 79.2435 108.17ZM92.8985 108.17C92.8985 105.285 90.7346 103.246 88.1479 103.246C85.5611 103.246 83.447 105.285 83.447 108.17C83.447 111.105 85.5611 113.12 88.1479 113.12C90.7595 113.12 92.8985 111.105 92.8985 108.17Z"
        fill="black"
      />
      <path
        d="M101.728 116.577V90.6601H105.907V116.577H101.728Z"
        fill="black"
      />
      <path
        d="M110.458 111.404H114.363C114.363 112.548 115.358 113.642 117.124 113.642C118.766 113.642 119.86 112.821 119.86 111.727C119.86 110.782 119.139 110.359 117.895 110.085L115.657 109.513C111.876 108.518 110.906 106.504 110.906 104.365C110.906 101.753 113.493 99.3654 117.149 99.3654C120.134 99.3654 123.516 100.883 123.467 104.614H119.487C119.487 103.469 118.443 102.748 117.249 102.748C115.955 102.748 115.06 103.494 115.06 104.539C115.06 105.409 115.881 105.907 116.851 106.131L119.537 106.852C123.318 107.822 123.989 110.185 123.989 111.727C123.989 115.135 120.582 117 117.174 117C113.866 117 110.533 114.985 110.458 111.404Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_830_144">
        <rect width="124.611" height="117" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
