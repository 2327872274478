import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { authUtil } from '../../../SharedLib/Util/AuthUtil';
import { getCognitoUserStatusByEmail } from '../../../SharedLib/Util/CognitoUtil';
import { messageForError } from '../../../SharedLib/Util/ErrorUtil';
import { logging } from '../../../SharedLib/Util/LoggingUtil';
import { validateEmail } from '../../../SharedLib/Util/ValidationUtil';
import { handleLoggedIn } from '../../../utils/handle-logged-in';
import CustomButton from '../../CustomButton/CustomButton';
import { LoginView } from '../LoginPanel/LoginPanel';
import styles from './LoginForm.module.scss';

export interface ILoginFormProps {
  setView: React.Dispatch<React.SetStateAction<LoginView>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
}

const LoginForm: React.FC<ILoginFormProps> = ({ setView, email, setEmail, password, setPassword, setUsername }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function validateInput(email: string, password: string) {
    if (!email || !password) {
      setError('Please enter your email and password');
      return false;
    }
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return false;
    }
    return true;
  }

  async function handleLogin(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);

    if (!validateInput(email, password)) {
      return setLoading(false);
    }

    const result = await authUtil.loginUser(email, password);

    logging.logDebug('handleLogin -> result: ', result);

    if (result.status === 'confirm_sign_in_with_new_password_required') {
      console.log('confirm_sign_in_with_new_password_required');
      setPassword('');
      setView(LoginView.CONFIRM_NEW_PASSWORD);
      return;
    }

    if (result.status === 'failed') {
      const cognitoStatus = await getCognitoUserStatusByEmail(email);
      console.log('cognitoStatus: ', cognitoStatus);
      if (cognitoStatus?.status === 'UNCONFIRMED') {
        setUsername(cognitoStatus.username);
        setView(LoginView.VERIFICATION_PENDING);
      } else if (
        cognitoStatus !== undefined &&
        cognitoStatus.status === 'CONFIRMED' &&
        cognitoStatus.username &&
        cognitoStatus.username.length
      ) {
        result.error = {
          code: 'CLASSIC_INCORRECT_PASSWORD',
          msg: 'Make sure you type your email and password correctly. Both your password and email are case-sensitive.',
        };
      }
    }

    if (result.error) {
      setLoading(false);
      return setError(messageForError(result.error).msg);
    }

    await handleLoggedIn(history);
  }

  return (
    <div className={styles.login_form_container}>
      <form className={styles.login_form} onSubmit={handleLogin}>
        <div className={styles.login_inputs}>
          <input
            className={styles.login_input}
            type="text"
            name="email"
            autoComplete="username"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="email"
          />
          <input
            className={styles.login_input}
            type="password"
            name="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="password"
          />
        </div>

        <div className={styles.error_text}>{error}</div>

        <div className={styles.login_reset_password_buttons}>
          <CustomButton
            height={'40px'}
            onClick={() => {
              setView(LoginView.RESET_PASSWORD);
            }}
            className={styles.login_reset_password_button}
            variant={'secondary'}
            width={'167px'}
          >
            Forgot Password?
          </CustomButton>
          <CustomButton height={'40px'} type="submit" className={styles.login_button} loading={loading} width={'167px'}>
            Login
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
