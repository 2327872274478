import { logError } from '../utils/LoggingUtil';

export const callTopNavBarFunction = ({
  funcName,
  funcArgs,
}: {
  funcName: string;
  funcArgs: any;
}) => {
  try {
    // @ts-expect-error global objects...
    if (typeof window.TopNavBarIframeWrapper !== 'undefined') {
      // @ts-expect-error global objects...
      window.TopNavBarIframeWrapper._getContentWindow().postMessage(
        {
          action: 'CALL_FUNCTION',
          data: { funcName, funcArgs },
        },
        '*',
      );
    } else {
      throw new Error('window.TopNavBarIframeWrapper is not defined');
    }
  } catch (exc) {
    logError('callTopNavBarFunction', exc);
  }
};
