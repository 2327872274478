import React, { useEffect, useMemo, useState } from 'react';

import Check from '../assets/icons/userlib/Check';
import { ICompDefault } from '../utils/types-interfaces';
import ExclamationIcon from '../assets/icons/userlib/Exclamation';
import { logging } from '../../../SharedLib/Util/LoggingUtil';
import { uuidv4 } from '../../../SharedLib/Util/MixedUtil';

import styles from './InputItem.module.scss';

export enum InputItemType {
  Input = 'input',
  Textarea = 'textarea',
}

export interface IProps extends ICompDefault {
  label?: string;
  type?: string;
  domType?: 'input' | 'textarea';
  readOnly?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
  errorText?: string;
  infoText?: string;
  name?: string;
  placeholder?: string;
  displayError?: boolean;
  displayInfo?: boolean;
  displaySearchIcon?: boolean;
  autoCorrect?: boolean;
  errorTextClassName?: string;
  infoTextClassName?: string;
  inputClassName?: string;
  autoCapitalize?: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';
  autoComplete?: string;
  displayValidatedIcon?: boolean;
  value?: string;
  maxLength?: number;
  onValueChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  onEnterPressed?: (event: React.KeyboardEvent) => void;
}

const InputItem = ({
  label = '',
  type = 'text',
  domType = InputItemType.Input,
  required = false,
  errorText = undefined,
  infoText = undefined,
  errorTextClassName = undefined,
  infoTextClassName = undefined,
  inputClassName = undefined,
  displayError = false,
  displayInfo = false,
  displaySearchIcon = false,
  readOnly = false,
  disabled = false,
  placeholder = undefined,
  name = 'input-item',
  displayValidatedIcon,
  defaultValue = undefined,
  autoCorrect = false,
  autoComplete = 'off',
  autoCapitalize = 'none',
  value = undefined,
  maxLength = 524288,
  onValueChange = () => {
    /* */
  },
  onEnterPressed = () => {
    //
  },
  id,
  className,
}: IProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [defaultValueSet, setDefaultValueSet] = useState(false); // to ensure defaultValue is only set once
  const inputName = useMemo(() => {
    return `${name}-${uuidv4()}`;
  }, [name]);

  const onChangeValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    logging.logDebug('InputItem -> onChangeValue -> event', event);
    setInputValue(event.target.value);
    onValueChange(event);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.code.toLowerCase() === 'enter') {
      event.preventDefault();
      event.stopPropagation();

      onEnterPressed(event);
    }
  };

  useEffect(() => {
    if (typeof value === 'string') {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (!defaultValueSet && defaultValue && defaultValue.length > 0) {
      setInputValue(defaultValue);
      setDefaultValueSet(true);
    }
  }, [defaultValue, defaultValueSet]);

  return (
    <div
      id={id ? `${id}-wrapper` : undefined}
      className={`${styles.wrapper}${className ? ` ${className}` : ''}`}
    >
      {label && (
        <label
          htmlFor={inputName}
          className={styles.title}
        >{`${label}${required ? '*' : ''}`}</label>
      )}
      <div className={styles.input_wrapper}>
        {domType === InputItemType.Input && (
          <input
            id={inputName}
            className={`${styles.item}${readOnly ? ` ${styles.disabled}` : ''}${displayError ? ` ${styles.error}` : ''}${
              displaySearchIcon ? ` ${styles.search}` : ''
            }${inputClassName ? ` ${inputClassName}` : ''}`}
            readOnly={readOnly}
            required={required}
            disabled={disabled || readOnly}
            type={type}
            name={inputName}
            autoComplete={autoComplete}
            autoCapitalize={autoCapitalize}
            autoCorrect={(autoCorrect || false).toString()}
            placeholder={placeholder}
            onChange={onChangeValue}
            onKeyDown={onKeyDown}
            value={inputValue}
            maxLength={maxLength}
          />
        )}

        {domType === InputItemType.Textarea && (
          <textarea
            id={inputName}
            readOnly={readOnly}
            required={required}
            disabled={disabled || readOnly}
            name={inputName}
            autoCorrect={(autoCorrect || false).toString()}
            autoCapitalize={autoCapitalize}
            autoComplete={autoComplete}
            placeholder={placeholder}
            className={`${styles.item}${readOnly ? ` ${styles.disabled}` : ''}${displayError ? ` ${styles.error}` : ''}${
              displaySearchIcon ? ` ${styles.search}` : ''
            }${inputClassName ? ` ${inputClassName}` : ''}`}
            onChange={onChangeValue}
            value={value}
            maxLength={maxLength}
          />
        )}

        {!displayInfo && displayValidatedIcon && (
          <Check className={styles.check} />
        )}
        {displayInfo && <ExclamationIcon className={styles.infocheck} />}
        {displaySearchIcon && !displayValidatedIcon && !displayInfo && (
          <svg
            className={styles.searchIcon}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.4073 17.8542L14.9969 13.4438C16.0587 12.0302 16.6319 10.3095 16.63 8.54156C16.63 4.02563 12.9559 0.351562 8.44 0.351562C3.92406 0.351562 0.25 4.02563 0.25 8.54156C0.25 13.0575 3.92406 16.7316 8.44 16.7316C10.208 16.7335 11.9286 16.1603 13.3422 15.0984L17.7527 19.5089C17.9759 19.7085 18.2671 19.815 18.5664 19.8066C18.8658 19.7983 19.1506 19.6756 19.3623 19.4639C19.574 19.2521 19.6967 18.9673 19.7051 18.668C19.7135 18.3687 19.6069 18.0775 19.4073 17.8542ZM2.59 8.54156C2.59 7.38454 2.9331 6.2535 3.5759 5.29148C4.21871 4.32945 5.13235 3.57964 6.2013 3.13687C7.27025 2.69409 8.44649 2.57825 9.58128 2.80397C10.7161 3.02969 11.7584 3.58685 12.5766 4.40499C13.3947 5.22312 13.9519 6.2655 14.1776 7.40028C14.4033 8.53507 14.2875 9.71131 13.8447 10.7803C13.4019 11.8492 12.6521 12.7629 11.6901 13.4057C10.7281 14.0485 9.59702 14.3916 8.44 14.3916C6.88906 14.3897 5.40217 13.7728 4.30548 12.6761C3.2088 11.5794 2.59186 10.0925 2.59 8.54156Z"
              fill="black"
            />
          </svg>
        )}
      </div>

      {displayError && (
        <div
          className={`${styles.error_txt}${errorTextClassName ? ` ${errorTextClassName}` : ''}`}
        >
          {errorText}
        </div>
      )}

      {displayInfo && (
        <div
          className={`${styles.info_txt}${infoTextClassName ? ` ${infoTextClassName}` : ''}`}
        >
          {infoText}
        </div>
      )}
    </div>
  );
};

export default InputItem;
