import React from 'react';
import { Input, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { IUserProfile } from '../../../userlib_new/utils/profile-interfaces';
import { AccordionSection } from './AccordionSection';
import { Select as SelectWithSearch } from 'chakra-react-select';
import { useProfile } from '../../../../context/profileProvider';
import DatePickerComponent from '../../../ReactDatePicker/ReactDatePicker';

interface IPersonaliaProps {
  userProfile: IUserProfile;
  inputBgColor?: string;
}
export const Personalia = ({ userProfile, inputBgColor }: IPersonaliaProps) => {
  const profileData = useProfile();
  return (
    <AccordionSection title="Personalia" sectionKey="personal">
      <FormControl>
        <FormLabel htmlFor="first-name">
          First Name:{' '}
          <Text as="b">{userProfile.personal?.firstName ?? ''}</Text>
        </FormLabel>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="last-name">
          Last Name: <Text as="b">{userProfile.personal?.lastName ?? ''}</Text>
        </FormLabel>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="job-title">Current Job Title</FormLabel>
        <Input
          id="job-title"
          placeholder="Your current job title"
          bg={inputBgColor}
          value={userProfile.personal?.currentJobTitle ?? ''}
          onChange={(e: any) =>
            profileData.handlePersonalChange('currentJobTitle', e.target.value)
          }
          _dark={{ bg: '#3E4252' }}
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="job-title">Date Of Birth</FormLabel>
        <DatePickerComponent
          id="date-of-birth"
          selected={
            userProfile.personal?.dateOfBirth
              ? userProfile.personal.dateOfBirth
              : null
          }
          onChange={(date) =>
            profileData.handlePersonalChange('dateOfBirth', date)
          }
          dateFormat="dd-MM-yyyy"
          placeholderText="dd-MM-yyyy"
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={100}
          scrollableYearDropdown
        />
      </FormControl>

      <FormControl>
        <FormLabel>Country</FormLabel>
        <SelectWithSearch
          maxMenuHeight={150}
          selectedOptionStyle="check"
          menuPortalTarget={document.body}
          menuPlacement="auto"
          options={profileData.countryData}
          value={profileData.countryData.find((country) =>
            Object.keys(userProfile.personal?.country ?? {}).includes(
              country.value,
            ),
          )}
          onChange={(e: any) => {
            if (e) {
              profileData.onCountryChange(e.value);
            }
          }}
        />
      </FormControl>
    </AccordionSection>
  );
};
