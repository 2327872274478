import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import type { FC, ReactNode } from 'react';
import { logging } from '../utils/LoggingUtil';
import {
  fetchSkillsWithLessons,
  getAllAvailableSkillsAndSubskills,
} from '../utils/skills';
import {
  ISkill,
  ParentSkill,
  SkillsWithLessons,
  SoftSkill,
  isParentSkill,
  isSoftSkill,
  softSkillsSkillId,
} from '../Component/userlib_new/utils/types-interfaces';

interface SkillsImmutableContextType {
  /**
   * A list of all available skills of all typess
   */
  availableSkills: ISkill[];

  /**
   * A sorted list of all available skills of type 'skill'
   */
  availableParentSkills: readonly Readonly<ParentSkill>[];

  softSkill?: Readonly<SoftSkill>;

  /**
   * Boolean to check if data is still being fetched
   */
  isLoading: boolean;

  /**
   * Mapping of topics (skills), chapters(subskills) and lessons
   * @type {SkillsWithLessons}
   * @readonly
   */
  skillsWithLessons: Readonly<SkillsWithLessons>;

  /**
   * Get skill name by id
   */
  getSkillName: (skillId: string) => string;

  getSkillById: (skillId: string) => Readonly<ISkill> | undefined;
}

const SkillsImmutableContext = createContext<
  SkillsImmutableContextType | undefined
>(undefined);

export const SkillsImmutableProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [availableSkills, setAvailableSkills] = useState<ISkill[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [skillsWithLessons, setSkillsWithLessons] = useState<SkillsWithLessons>(
    {
      topics: {},
      chapters: {},
      lessons: {},
    },
  );

  useEffect(() => {
    const getAllSkillsData = async () => {
      try {
        setIsLoading(true);

        const [skills, skillsWithLessons] = await Promise.all([
          getAllAvailableSkillsAndSubskills(),
          fetchSkillsWithLessons(),
        ]);

        setAvailableSkills(skills);
        setSkillsWithLessons(skillsWithLessons);
      } catch (error) {
        logging.logError('Error fetching skills:', error);
      } finally {
        setIsLoading(false);
      }
    };

    return void getAllSkillsData();
  }, []);

  const availableParentSkills = useMemo(
    () =>
      availableSkills
        .filter(isParentSkill)
        .sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
        ),
    [availableSkills],
  );

  const availableSkillsMap = useMemo(
    () => new Map(availableSkills.map((skill) => [skill.id, skill])),
    [availableSkills],
  );

  const getSkillName = useCallback(
    (skillId: string) => availableSkillsMap.get(skillId)?.name ?? '',
    [availableSkillsMap],
  );

  const getSkillById = useCallback(
    (skillId: string) => availableSkillsMap.get(skillId),
    [availableSkillsMap],
  );

  const softSkill = useMemo(() => {
    const skill = getSkillById(softSkillsSkillId);
    if (skill && isSoftSkill(skill)) {
      return skill;
    }
  }, [getSkillById]);

  return (
    <SkillsImmutableContext.Provider
      value={{
        availableSkills,
        availableParentSkills,
        softSkill,
        isLoading,
        skillsWithLessons,
        getSkillName,
        getSkillById,
      }}
    >
      {children}
    </SkillsImmutableContext.Provider>
  );
};

export const useSkillsImmutable = () => {
  const context = useContext(SkillsImmutableContext);
  if (!context) {
    throw new Error(
      'useSkillsImmutable must be used within a SkillsImmutableProvider',
    );
  }
  return context;
};

export default SkillsImmutableProvider;
