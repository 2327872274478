import React from 'react';
import DangerExlamation from '../../SharedLib/Component/Icons/DangerExlamation/DangerExclamation';
import SuccessCheck from '../../SharedLib/Component/Icons/SuccessCheck/SuccessCheck';
import styles from './Alert.module.scss';

export interface IAlertProps {
  message: string;
  type: 'success' | 'warning' | 'danger' | 'info';
  className?: string;
}

const Alert: React.FC<IAlertProps> = ({ message, type }) => {
  function iconForType() {
    switch (type) {
      case 'success':
        return <SuccessCheck size={16} />;
      case 'warning':
        return <DangerExlamation size={16} fill="#282A35" />;
      case 'danger':
        return <DangerExlamation size={16} fill="#D9212C" />;
      case 'info':
        return <DangerExlamation size={16} fill="#2171d9" />;
      default:
        return '';
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.iwrp + ' ' + styles[type]}>
        {iconForType()}
        {message}
      </div>
    </div>
  );
};

export default Alert;
