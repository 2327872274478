import React from 'react';

export interface IConfettiProps {
  width?: string;
  height?: string;
  viewBox?: string;
}

const Confetti: React.FC<IConfettiProps> = ({
  width = '128',
  height = '118',
  viewBox = '0 0 128 118',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.8661 85.1922L7.59532 115.834C6.88119 116.23 5.72414 115.165 6.05967 114.421L32.0065 56.7966"
        fill="#B8A7F8"
      />
      <path
        d="M57.7555 80.4899L9.22242 107.396L6.05961 114.421C5.7243 115.165 6.88113 116.23 7.59548 115.834L62.8663 85.1919L57.7555 80.4899Z"
        fill="#8160FF"
      />
      <path
        d="M63.818 84.5628C67.23 80.8545 63.0364 71.4448 54.4513 63.5457C45.8663 55.6466 36.1407 52.2493 32.7287 55.9576C29.3167 59.6658 33.5104 69.0755 42.0954 76.9746C50.6805 84.8737 60.4061 88.271 63.818 84.5628Z"
        fill="#04AA6D"
      />
      <path
        d="M54.4491 63.5434C45.8647 55.6446 36.1393 52.2475 32.727 55.9559C31.6717 57.1027 31.3454 58.7958 31.6499 60.8197C36.1803 61.3043 42.4187 64.5673 48.1505 69.8416C54.0803 75.2979 57.9126 81.474 58.5925 85.9949C60.8468 86.2359 62.6801 85.7925 63.8141 84.5603C67.2263 80.8519 63.0334 71.4421 54.4491 63.5434Z"
        fill="#46C48F"
      />
      <path
        d="M36.1298 91.4899C38.4732 91.4899 40.3728 89.5902 40.3728 87.2468C40.3728 84.9035 38.4732 83.0038 36.1298 83.0038C33.7864 83.0038 31.8867 84.9035 31.8867 87.2468C31.8867 89.5902 33.7864 91.4899 36.1298 91.4899Z"
        fill="#FFF4A3"
      />
      <path
        d="M19.8017 101.046C22.145 101.046 24.0447 99.146 24.0447 96.8026C24.0447 94.4593 22.145 92.5596 19.8017 92.5596C17.4583 92.5596 15.5586 94.4593 15.5586 96.8026C15.5586 99.146 17.4583 101.046 19.8017 101.046Z"
        fill="#FFF4A3"
      />
      <path
        d="M55.3873 62.131C51.0382 58.1292 46.2441 55.0874 41.888 53.5656C36.9804 51.8516 33.0507 52.2406 30.8231 54.6617C28.5951 57.0825 28.5337 61.0308 30.6496 65.7793C32.5276 69.9941 35.957 74.5192 40.3064 78.5212C44.6555 82.523 49.4495 85.5648 53.8056 87.0866C54.0825 87.1832 54.3555 87.2717 54.6258 87.3551L8.69704 112.818L26.5497 72.8905C27.0031 71.8764 26.5486 70.6865 25.5345 70.2331C24.5197 69.7802 23.3305 70.234 22.8771 71.2483L3.91204 113.663C2.79033 116.154 5.86365 118.989 8.25729 117.661L62.5593 87.7144C63.4355 87.2577 64.2196 86.6979 64.8705 85.9905C69.1695 81.3186 65.0928 71.0612 55.3873 62.131C65.0928 71.0612 51.0382 58.1292 55.3873 62.131ZM61.91 83.2668C60.9838 84.2616 57.5185 84.1225 55.1324 83.2888C51.2826 81.9441 46.985 79.1995 43.0306 75.561C39.0761 71.9226 35.9845 67.8671 34.3247 64.1427C32.9442 61.0442 32.7421 58.5185 33.7841 57.3861C34.3084 56.8161 35.2105 56.5311 36.3862 56.5311C37.5459 56.5311 38.9719 56.8086 40.5617 57.3641C44.4115 58.7088 48.7091 61.4534 52.6635 65.092C61.3764 73.1093 63.9339 81.0925 61.91 83.2668Z"
        fill="black"
      />
      <path
        d="M49.5948 49.6686C50.2997 50.1646 51.3936 50.0955 51.9979 49.5173C54.1849 47.4253 55.826 45.052 56.8753 42.4628C57.7422 40.3239 58.9297 37.3946 57.9983 34.0442C57.355 31.7316 56.0393 30.3791 54.9821 29.2924C54.0162 28.2994 53.5107 27.74 53.4867 26.8536C53.432 24.8484 56.0715 22.8516 58.2957 21.5299C59.0577 21.0768 59.228 20.2029 58.6755 19.5776C58.1229 18.9525 57.0579 18.8132 56.2952 19.266C52.0998 21.7599 50.0083 24.3337 50.079 26.9161C50.1299 28.7874 51.2423 29.9306 52.3181 31.0362C53.2458 31.9897 54.2053 32.9759 54.6752 34.6659C55.3949 37.2542 54.4629 39.5527 53.6407 41.5808C52.7316 43.8242 51.3081 45.8821 49.4104 47.6974C48.7978 48.2839 48.8805 49.1661 49.5948 49.6686C49.9162 49.8947 48.8805 49.1661 49.5948 49.6686Z"
        fill="black"
      />
      <path
        d="M61.4039 45.2421C61.9015 45.5575 62.615 45.6033 63.2759 45.4115C63.632 45.3081 63.973 45.1357 64.2565 44.8994C67.2894 42.3716 71.219 38.2105 72.7175 32.7397C73.4988 29.8861 73.5034 27.0783 72.7308 24.3942C72.5094 23.625 71.4444 23.2574 70.3525 23.5729C70.3511 23.5733 70.35 23.5736 70.3491 23.5739C69.2587 23.8904 68.5545 24.7689 68.7755 25.5371C69.4384 27.8413 69.4354 30.2481 68.7665 32.6905C67.4818 37.3813 64.0971 40.962 61.483 43.1403C60.6739 43.8153 60.6382 44.7562 61.4039 45.2421Z"
        fill="black"
      />
      <path
        d="M72.8847 49.4237C74.7482 48.3391 76.4384 48.2131 78.3954 48.0673C80.4383 47.915 82.7537 47.7426 85.4854 46.4079C89.2519 44.5678 92.3874 41.233 94.805 36.4963C95.2176 35.6879 94.9541 34.6658 94.2168 34.2135C93.4787 33.7616 92.5468 34.0505 92.1344 34.8584C90.0462 38.9504 87.39 41.8052 84.24 43.344C82.0044 44.4363 80.1505 44.5744 78.1879 44.7204C76.0747 44.8782 73.8896 45.0408 71.4498 46.4608C67.5812 48.7119 64.7569 53.2239 63.0554 59.8718C62.8386 60.7191 63.277 61.6315 64.0376 61.9136C64.8576 62.2175 65.768 61.6918 66.0017 60.7782C67.4683 55.048 69.7842 51.2279 72.8847 49.4237C74.7482 48.3391 69.7842 51.2279 72.8847 49.4237Z"
        fill="black"
      />
      <path
        d="M95.5361 64.7131C92.9678 66.3645 90.6211 66.9655 88.5628 66.4998C87.2737 66.2077 86.4929 65.5956 85.5041 64.8203C84.7235 64.2083 83.8389 63.5146 82.6348 62.9594C79.0012 61.284 74.3868 61.8048 68.9195 64.5068C68.1152 64.9045 67.774 65.9027 68.1574 66.7366C68.5406 67.5703 69.5032 67.9237 70.308 67.5268C74.8849 65.2642 78.5912 64.7562 81.3236 66.0159C82.1885 66.4148 82.8534 66.9363 83.5578 67.4885C84.6523 68.3467 85.8927 69.3197 87.8747 69.7681C88.5579 69.9226 89.2543 70 89.9631 70C92.2704 70 94.7081 69.1821 97.2368 67.5558C97.9942 67.0689 98.2271 66.0376 97.7574 65.2527C97.2878 64.4676 96.2929 64.2262 95.5361 64.7131Z"
        fill="black"
      />
      <path
        d="M104.241 45.0287C102.622 45.2887 101.063 45.8209 99.6064 46.6094C97.5888 47.7021 95.7662 49.3098 94.3357 51.2593C93.8488 51.9225 93.8966 52.913 94.4462 53.5146C95.084 54.2126 96.1629 54.1493 96.7276 53.3794C97.8799 51.8092 99.3449 50.5158 100.965 49.6389C102.133 49.0059 103.385 48.5793 104.686 48.3701C105.525 48.235 106.106 47.3776 105.984 46.4548C105.861 45.5318 105.081 44.8945 104.241 45.0287C105.081 44.8945 102.622 45.2889 104.241 45.0287Z"
        fill="black"
      />
      <path
        d="M81.9512 54.8217C81.9512 56.0664 82.9637 57.0789 84.2084 57.0789C85.4531 57.0789 86.4657 56.0664 86.4657 54.8217C86.4657 53.577 85.4531 52.5645 84.2084 52.5645C82.9634 52.5645 81.9512 53.5772 81.9512 54.8217Z"
        fill="black"
      />
      <path
        d="M108.257 18.514C109.502 18.514 110.514 17.5015 110.514 16.2568C110.514 15.0123 109.502 14 108.257 14C107.013 14 106 15.0125 106 16.2568C106 17.5013 107.013 18.514 108.257 18.514Z"
        fill="black"
      />
      <path
        d="M63.4877 18.6766C64.7324 18.6766 65.745 17.6641 65.745 16.4198C65.745 15.1751 64.7324 14.1626 63.4877 14.1626C62.243 14.1626 61.2305 15.1751 61.2305 16.4198C61.2305 17.6639 62.243 18.6766 63.4877 18.6766Z"
        fill="black"
      />
      <path
        d="M110.07 56.7307C108.825 56.7307 107.812 57.7432 107.812 58.9875C107.812 60.2322 108.825 61.2447 110.07 61.2447C111.314 61.2447 112.327 60.2322 112.327 58.9875C112.327 57.7434 111.314 56.7307 110.07 56.7307Z"
        fill="black"
      />
      <path
        d="M42.3825 87.2471C42.3825 83.7984 39.5765 80.9924 36.1278 80.9924C32.679 80.9924 29.873 83.7984 29.873 87.2471C29.873 90.6959 32.679 93.5019 36.1278 93.5019C39.5765 93.5019 42.3825 90.6959 42.3825 87.2471ZM33.8959 87.2471C33.8959 86.0167 34.8971 85.0155 36.1275 85.0155C37.358 85.0155 38.3592 86.0167 38.3592 87.2471C38.3592 88.4776 37.358 89.4788 36.1275 89.4788C34.8971 89.4788 33.8959 88.4774 33.8959 87.2471Z"
        fill="black"
      />
      <path
        d="M19.0785 98.925C18.0669 98.4664 16.8743 98.915 16.4162 99.9266C15.9576 100.938 16.4062 102.13 17.4178 102.589C18.2529 102.967 19.1281 103.147 19.9904 103.147C22.3716 103.146 24.6515 101.78 25.6964 99.4741C26.3858 97.9523 26.442 96.2533 25.8539 94.6896C25.2655 93.1261 24.1034 91.885 22.5816 91.1956C21.5689 90.7363 20.3774 91.1854 19.9193 92.1972C19.4607 93.209 19.9093 94.4012 20.9209 94.8595C21.4638 95.1058 21.8784 95.5483 22.0884 96.1061C22.2984 96.6639 22.2785 97.2704 22.0324 97.8134C21.5245 98.934 20.2005 99.4322 19.0785 98.925Z"
        fill="black"
      />
      <path
        d="M41.0489 31.9271C41.3483 31.0057 42.6517 31.0057 42.9511 31.9271L43.1226 32.4549C43.2564 32.8669 43.6404 33.1459 44.0736 33.1459H44.6287C45.5974 33.1459 46.0002 34.3855 45.2164 34.9549L44.7674 35.2812C44.4169 35.5358 44.2703 35.9872 44.4041 36.3992L44.5757 36.927C44.875 37.8484 43.8205 38.6145 43.0368 38.0451L42.5878 37.7188C42.2373 37.4642 41.7627 37.4642 41.4122 37.7188L40.9632 38.0451C40.1795 38.6145 39.125 37.8484 39.4243 36.9271L39.5959 36.3992C39.7297 35.9872 39.5831 35.5358 39.2326 35.2812L38.7836 34.9549C37.9998 34.3855 38.4026 33.1459 39.3713 33.1459H39.9264C40.3596 33.1459 40.7436 32.8669 40.8774 32.4549L41.0489 31.9271Z"
        fill="black"
      />
      <path
        d="M79.0489 77.9271C79.3483 77.0057 80.6517 77.0057 80.9511 77.9271L81.1226 78.4549C81.2564 78.8669 81.6404 79.1459 82.0736 79.1459H82.6287C83.5974 79.1459 84.0002 80.3855 83.2164 80.9549L82.7674 81.2812C82.4169 81.5358 82.2703 81.9872 82.4041 82.3992L82.5757 82.927C82.875 83.8484 81.8205 84.6145 81.0368 84.0451L80.5878 83.7188C80.2373 83.4642 79.7627 83.4642 79.4122 83.7188L78.9632 84.0451C78.1795 84.6145 77.125 83.8484 77.4243 82.9271L77.5959 82.3992C77.7297 81.9872 77.5831 81.5358 77.2326 81.2812L76.7836 80.9549C75.9998 80.3855 76.4026 79.1459 77.3713 79.1459H77.9264C78.3596 79.1459 78.7436 78.8669 78.8774 78.4549L79.0489 77.9271Z"
        fill="black"
      />
      <path
        d="M85.5489 15.9271C85.8483 15.0057 87.1517 15.0057 87.4511 15.9271L87.9593 17.4914C88.0932 17.9034 88.4772 18.1824 88.9104 18.1824H90.5552C91.524 18.1824 91.9267 19.422 91.143 19.9914L89.8123 20.9582C89.4618 21.2128 89.3152 21.6642 89.449 22.0762L89.9573 23.6406C90.2567 24.5619 89.2022 25.328 88.4185 24.7586L87.0878 23.7918C86.7373 23.5372 86.2627 23.5372 85.9122 23.7918L84.5815 24.7586C83.7978 25.328 82.7433 24.5619 83.0427 23.6406L83.551 22.0762C83.6848 21.6642 83.5382 21.2128 83.1877 20.9582L81.857 19.9914C81.0733 19.422 81.476 18.1824 82.4448 18.1824H84.0896C84.5228 18.1824 84.9068 17.9034 85.0407 17.4914L85.5489 15.9271Z"
        fill="black"
      />
      <path
        d="M81.2568 4.51448C82.5015 4.51448 83.514 3.50198 83.514 2.25724C83.514 1.01251 82.5015 0 81.2568 0C80.0123 0 79 1.01251 79 2.25724C79 3.50198 80.0125 4.51448 81.2568 4.51448Z"
        fill="black"
      />
      <path
        d="M118.049 31.9271C118.348 31.0057 119.652 31.0057 119.951 31.9271L120.796 34.5279C120.93 34.9399 121.314 35.2188 121.747 35.2188H124.482C125.451 35.2188 125.853 36.4585 125.07 37.0279L122.857 38.6353C122.507 38.8899 122.36 39.3413 122.494 39.7533L123.339 42.3541C123.638 43.2754 122.584 44.0415 121.8 43.4721L119.588 41.8647C119.237 41.6101 118.763 41.6101 118.412 41.8647L116.2 43.4721C115.416 44.0415 114.362 43.2754 114.661 42.3541L115.506 39.7533C115.64 39.3413 115.493 38.8899 115.143 38.6353L112.93 37.0279C112.147 36.4585 112.549 35.2188 113.518 35.2188H116.253C116.686 35.2188 117.07 34.9399 117.204 34.5279L118.049 31.9271Z"
        fill="black"
      />
    </svg>
  );
};

export default Confetti;
