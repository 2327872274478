/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@chakra-ui/react';
import { BsCloudCheck } from 'react-icons/bs';
import {
  ICompDefault,
  KEYPRESS_WAIT,
  TIMEOUT,
} from '../../userlib_new/utils/types-interfaces';
// import ProfilePicture from '../../userlib_new/ProfilePicture';
import { authUtil as AuthUtil } from '../../../SharedLib/Util/AuthUtil';
import { logging } from '../../../SharedLib/Util/LoggingUtil';
import { fetchProfile } from '../../../SharedLib/Util/InternalProjects/ProfileUtil';
import { UserDataType } from '../../../SharedLib/Util/InterfaceAndTypeUtil';
import ModuleWrapper from '../ModuleWrapper/ModuleWrapper';
import CheckBox from '../../userlib/buttons/CheckBox/CheckBox';
import InputItem from '../../userlib_new/InputItem';
import {
  nameValid,
  validateEmail,
} from '../../../SharedLib/Util/ValidationUtil';
import {
  getCurrentUts,
  fetchAndCacheCurrentUts,
} from '../../../SharedLib/Util/TimeUtil';
import { AuthError } from '../../../SharedLib/Util/Interfaces';
import { IFetchResult } from '../../../SharedLib/Util/FetchUtil';

import { NotificationStateContext } from '../../../context/notification-context';
import { NotificationType } from '../../userlib_new/Notifications/utils/TypesInterfaces';

import { UserStateContext } from '../../../context/user-context';
import { PublicProfileStateContext } from '../../../context/public-profile-context';
import Modal from '../../userlib_new/Modal';

import styles from './Profile.module.scss';
import { getSocialProviders } from '../../../utils/get-social-providers';

interface IProps extends ICompDefault {
  refElement: React.RefObject<HTMLDivElement>;
  userIsLoggedIn: boolean;
  publicProfileElementId: string;
  doneLoading?: (value: boolean) => void;
  loadingSet: (value: boolean) => void;
  loadingSetPublicProfile: (value: boolean) => void;
  errorSavingPublicProfile: (errorString: string) => void;
  errorLoadingUser: (errorString: string) => void;
  errorSavingUser: (value: boolean, errorString: string) => void;
  hasPublicProfileError?: boolean;
  isPublicProfileInitLoading: boolean;
  hasErrorInPublicProfile: boolean;
  isInEditNickMode: boolean;
}

const Profile = ({
  refElement,
  className = undefined,
  id = undefined,
  publicProfileElementId,
  userIsLoggedIn,
  doneLoading = () => {
    //
  },
  loadingSet,
  errorLoadingUser,
  loadingSetPublicProfile,
  hasPublicProfileError = false,
  errorSavingPublicProfile,
  errorSavingUser,
  isPublicProfileInitLoading,
  hasErrorInPublicProfile,
  isInEditNickMode,
}: IProps) => {
  const { addNNotification, updateNotification } = useContext(
    NotificationStateContext,
  );
  const { loadedUserData, setUserData, userData } =
    useContext(UserStateContext);
  const {
    loadedPublicProfileData,
    publicProfileUserData,
    setPublicProfileUserData,
    errorLoadingPublicProfileData,
    savePublicProfileData,
    setDisablePublicProfile,
    setErrorSavingPublicProfileData,
  } = useContext(PublicProfileStateContext);

  // const [user, setUser] = useState<any | unknown>(null); // cognito user data
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const [hasNameChangesBeenMade, setHasNameChangesBeenMade] = useState(false);
  const [hasEmailChangesBeenMade, setHasEmailChangesBeenMade] = useState(false);

  const [firstName, setFirstName] = useState<string>('');
  const [firstNameValid, setFirstNameValid] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState('');
  const [displayFirstNameError, setDisplayFirstNameError] = useState(false);

  const [lastName, setLastName] = useState<string>('');
  const [lastNameValid, setLastNameValid] = useState(false);
  const [lastNameErrorText, setLastNameErrorText] = useState('');
  const [displayLastNameError, setDisplayLastNameError] = useState(false);

  const [email, setEmail] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string | undefined>();
  const [currentEmail, setCurrentEmail] = useState<string | undefined>();
  const [disableEmail, setDisableEmail] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');
  const [displayEmailError, setDisplayEmailError] = useState(false);
  const [displayEmailInfo, setDisplayEmailInfo] = useState(false);
  const [checkUpdatedCachedEmail, setCheckUpdatedCachedEmail] = useState(false);

  const [requestExpiresUts, setRequestExpiresUts] = useState<number>(0);
  const [currentUtsCached, setCurrentUtsCached] = useState(false);

  const [password, setPassword] = useState<string>('');
  const [isSubscriptionsChecked, setIsSubscriptionsChecked] = useState(false);

  const [showResetPswrdModal, setShowResetPswrdModal] = useState(false);

  const [isExternal, setIsExternal] = useState(false);
  const [isFeideUser, setIsFeideUser] = useState(false);

  const timeoutId = useRef<NodeJS.Timeout>();
  const savePublicProfileRef = useRef(false);

  /**
   * Set loading value for page
   * @param value
   */
  const setLoading = useCallback((value: boolean) => {
    setIsLoading(value);
    loadingSet(value);
  }, []);

  const setLoadingPublicProfile = useCallback((value: boolean) => {
    loadingSetPublicProfile(value);
    setDisable(value);
    if (savePublicProfileRef.current) {
      setDisableEmail(value);
    }
  }, []);

  useEffect(() => {
    if (
      disable ||
      displayEmailInfo ||
      //!loadedPublicProfileData ||
      //errorLoadingPublicProfileData ||
      isInEditNickMode ||
      hasErrorInPublicProfile
    ) {
      setDisableEmail(true);
    } else if (
      !disable &&
      !displayEmailInfo /*  && loadedPublicProfileData */
    ) {
      setDisableEmail(false);
    }
  }, [
    disable,
    displayEmailInfo,
    /* loadedPublicProfileData, */ isInEditNickMode,
    hasErrorInPublicProfile,
  ]);

  // hook for getting currentUtsCached used in email checking
  useEffect(() => {
    // on init
    fetchAndCacheCurrentUts((currentUts, origin) => {
      logging.logDebugAlt(
        'SettingsPage -> Profile -> fetchAndCacheCurrentUts -> currentUts, origin: ',
        {
          currentUts,
          origin,
        },
      );
    })
      .then(() => {
        setCurrentUtsCached(true);
      })
      .catch((error) => {
        const notifId = uuidv4();
        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'error',
          header: 'Error Fetching Current UTS',
          body: error as string,
        };

        addNNotification(notifProp);
      });
  }, []);

  // hook for disabling/enabling save button
  useEffect(() => {
    if (
      (hasNameChangesBeenMade ||
        hasEmailChangesBeenMade ||
        isSubscriptionsChecked !== userData?.emailConsent) &&
      firstNameValid &&
      lastNameValid &&
      emailValid &&
      !isLoading &&
      !displayEmailInfo &&
      !hasErrorInPublicProfile &&
      !isInEditNickMode
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [
    firstNameValid,
    lastNameValid,
    emailValid,
    hasNameChangesBeenMade,
    hasEmailChangesBeenMade,
    isLoading,
    isSubscriptionsChecked,
    userData?.emailConsent,
    displayEmailInfo,
    hasErrorInPublicProfile,
    isInEditNickMode,
  ]);

  // hook for setting page disabled
  useEffect(() => {
    if (
      isInEditNickMode ||
      //hasErrorInPublicProfile ||
      !loadedUserData ||
      isLoading
      //(!loadedPublicProfileData && !errorLoadingPublicProfileData)
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [
    loadedUserData,
    isLoading,
    /* loadedPublicProfileData,  hasErrorInPublicProfile,*/ isInEditNickMode,
  ]);

  // hook for loading intitial user data
  useEffect(() => {
    const loadUser = async () => {
      try {
        /* if (loadedUserData || !userIsLoggedIn || !currentUtsCached) {
          return;
        } */

        setLoading(true);
        const _user = await AuthUtil.getCurrentUser(); // cognito user meta
        // setUser(_user);

        const current_user_info_res = await AuthUtil.getCurrentUserInfo();

        logging.logDebug(
          'SettingsPage -> loadUser -> [_user, current_user_info_res]: ',
          _user,
          current_user_info_res,
        );

        let checkingNewEmail = false;

        if (
          typeof current_user_info_res.data.attributes[
            'custom:change_email'
          ] !== 'undefined'
        ) {
          try {
            const change_email_req_meta: any = JSON.parse(
              current_user_info_res.data.attributes['custom:change_email'],
            );

            const current_uts = getCurrentUts();

            if (current_uts < change_email_req_meta.expires_uts) {
              setNewEmail(change_email_req_meta.email);
              setEmail(change_email_req_meta.email);
              setDisplayEmailInfo(true);

              setRequestExpiresUts(change_email_req_meta.expires_uts);
              setCheckUpdatedCachedEmail(true);
              checkingNewEmail = true;
            }
          } catch (error) {
            const exc = error as any;
            logging.logDebug(
              'Parsing change_email_req_meta failed -> exc: ',
              exc,
            );

            const notifId = uuidv4();
            const notifProp: NotificationType = {
              id: notifId,
              dismissed: false,
              autohide: false,
              closeButton: true,
              type: 'error',
              header: 'Changing email',
              body: exc,
            };
            errorLoadingUser(exc);

            addNNotification(notifProp);
          }
        }
        const userDataFetchRes = await fetchProfile<UserDataType>({
          method: 'GET',
          url: '/user',
          timeout: TIMEOUT,
        });
        logging.logDebugAlt(
          'SettingsPage -> loadUser -> userDataFetchRes: ',
          userDataFetchRes,
        );

        if (
          userDataFetchRes.error.code === '0' &&
          userDataFetchRes.data.username !== undefined
        ) {
          setUserData(userDataFetchRes.data);

          setFirstName(userDataFetchRes.data.firstName);
          setFirstNameValid(true);

          setLastName(userDataFetchRes.data.lastName);
          setLastNameValid(true);

          setEmailValid(true);
          setCurrentEmail(userDataFetchRes.data.email);

          if (!checkingNewEmail) {
            setEmail(userDataFetchRes.data.email);
            setDisplayEmailInfo(false);
            /* !loadedPublicProfileData && !errorLoadingPublicProfileData &&*/ setDisableEmail(
              false,
            );
          }

          setIsSubscriptionsChecked(userDataFetchRes.data.emailConsent);
          setPassword('12345678901234567890');

          setIsExternal(
            getSocialProviders().includes(
              userDataFetchRes.data.username.split('_')[0],
            ),
          );
          setIsFeideUser(
            userDataFetchRes.data.username.split('_')[0] === 'Feide',
          );
        } else {
          errorLoadingUser(
            userDataFetchRes.error.description || 'An unkonown error occured',
          );
          logging.logDebug(
            'SettingsPage -> loadUser -> error: ',
            userDataFetchRes.error,
          );

          const notifId = uuidv4();
          const notifProp: NotificationType = {
            id: notifId,
            dismissed: false,
            autohide: false,
            closeButton: true,
            type: 'error',
            header: 'Loading My Profile',
            body: userDataFetchRes.error.description,
          };

          addNNotification(notifProp);
        }

        // else {
        //     setUserData({
        //         emailConsent: true,
        //     } as UserDataType);
        // }
      } catch (error: unknown) {
        const exc = error as any;
        errorLoadingUser(exc.message as string);

        logging.logDebug('SettingsPage -> loadUser -> exc: ', exc);

        const notifId = uuidv4();
        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'error',
          header: 'Loading My Profile',
          body: error as string,
        };

        addNNotification(notifProp);
      }

      setLoading(false);

      doneLoading(true);
    };

    if (!loadedUserData && userIsLoggedIn && currentUtsCached) {
      loadUser();
    }
  }, [userIsLoggedIn, currentUtsCached, loadedUserData]);

  // hook for checking if email is verified
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;

    if (checkUpdatedCachedEmail) {
      interval = setInterval(() => {
        logging.logDebug('CheckUpdatedCachedEmail');

        logging.logDebugAlt('requestExpiresUts: ', requestExpiresUts);

        if (requestExpiresUts) {
          const current_uts = getCurrentUts();
          logging.logDebugAlt('current_uts: ', current_uts);
          logging.logDebugAlt(
            'current_uts > requestExpiresUts: ',
            current_uts > requestExpiresUts,
          );

          if (current_uts > requestExpiresUts) {
            setNewEmail(undefined);
            setCheckUpdatedCachedEmail(false);
            setDisplayEmailInfo(false);
            return;
          }
        }

        setDisplayEmailInfo(true);

        // const updated_cached_email = checkUpdatedCachedEmail();
        const updated_cached_email_res =
          AuthUtil.getCachedUserAttribute('email');
        logging.logDebugAlt(
          'updated_cached_email_res: ',
          updated_cached_email_res,
        );
        logging.logDebugAlt('current_email: ', currentEmail);
        logging.logDebugAlt('newEmail: ', newEmail);

        if (
          updated_cached_email_res.error.code === '0' &&
          updated_cached_email_res.data &&
          updated_cached_email_res.data === newEmail
        ) {
          const new_current_email = updated_cached_email_res.data as string;

          setCurrentEmail(new_current_email);
          setEmail(new_current_email);
          setEmailValid(true);
          setNewEmail(undefined);
          setDisplayEmailInfo(false);
          /* !loadedPublicProfileData && errorLoadingPublicProfileData &&*/ setDisableEmail(
            false,
          );
          setCheckUpdatedCachedEmail(false);
        }
      }, 5000);
    } else {
      if (interval) {
        clearInterval(interval);
        interval = undefined;
      }
      // setDisplayEmailInfo(false);
      // setDisableEmail(false);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
        interval = undefined;
      }
    };
  }, [checkUpdatedCachedEmail, requestExpiresUts]);

  /**
   * Changes to first name
   * @param event
   */
  const firstNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }

    timeoutId.current = setTimeout(() => {
      const pattern = /^[^\s]+(\s+[^\s]+)*$/;
      const hasWhiteSpace = !pattern.test(event.target.value);

      logging.logDebug(
        'SettingsPage -> Profile -> firstNameChanged -> [event, hasWhiteSpace]',
        event,
        hasWhiteSpace,
      );

      if (
        nameValid(event.target.value) &&
        !event.target.validity.valueMissing &&
        !hasWhiteSpace
      ) {
        setFirstNameValid(true);
        setFirstNameErrorText('');
        setDisplayFirstNameError(false);
        if (
          event.target.value !== userData?.firstName ||
          lastName !== userData.lastName
        ) {
          setHasNameChangesBeenMade(true);
        } else {
          setHasNameChangesBeenMade(false);
        }
      } else if (event.target.validity.valueMissing) {
        setFirstNameErrorText('Missing value');
        setFirstNameValid(false);
        setDisplayFirstNameError(true);
      } else if (hasWhiteSpace) {
        setFirstNameErrorText('Can not start or end with whitespace');
        setFirstNameValid(false);
        setDisplayFirstNameError(true);
      } else {
        setFirstNameErrorText('Not a valid first name');
        setFirstNameValid(false);
        setDisplayFirstNameError(true);
      }

      setFirstName(event.target.value);
      timeoutId.current = undefined;
    }, KEYPRESS_WAIT);
  };

  /**
   * Changes to last name
   * @param event
   */
  const lastNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }

    timeoutId.current = setTimeout(() => {
      const pattern = /^[^\s]+(\s+[^\s]+)*$/;
      const hasWhiteSpace = !pattern.test(event.target.value);

      logging.logDebug(
        'SettingsPage -> Profile -> lastNameChanged -> [event, hasWhiteSpace]',
        event,
        hasWhiteSpace,
      );

      if (
        nameValid(event.target.value) &&
        !event.target.validity.valueMissing &&
        !hasWhiteSpace
      ) {
        setLastNameValid(true);
        setLastNameErrorText('');
        setDisplayLastNameError(false);
        if (
          event.target.value !== userData?.lastName ||
          firstName !== userData.firstName
        ) {
          setHasNameChangesBeenMade(true);
        } else {
          setHasNameChangesBeenMade(false);
        }
      } else if (event.target.validity.valueMissing) {
        setLastNameErrorText('Missing value');
        setLastNameValid(false);
        setDisplayLastNameError(true);
      } else if (hasWhiteSpace) {
        setLastNameErrorText('Can not start or end with whitespace');
        setLastNameValid(false);
        setDisplayLastNameError(true);
      } else {
        setLastNameErrorText('Not a valid last name');
        setLastNameValid(false);
        setDisplayLastNameError(true);
      }

      setLastName(event.target.value);
      timeoutId.current = undefined;
    }, KEYPRESS_WAIT);
  };

  /**
   * Uploaded new profile picture
   */
  const uploadProfilePicture = (url: string) => {
    const ppUserData = { ...publicProfileUserData! };
    ppUserData.profilePicture = url;
    setPublicProfileUserData(ppUserData);

    const notifId = uuidv4();
    const notifProp: NotificationType = {
      id: notifId,
      dismissed: false,
      autohide: true,
      closeButton: false,
      type: 'success',
      header: 'Uploading profile picture',
      body: 'Successfully uploaded profile picture',
    };

    addNNotification(notifProp);
  };

  // hook for deleting profile picture
  const [executeDeleteProfilePicture, setExecuteDeleteProfilePicture] =
    useState(false);
  useEffect(() => {
    const execute = async () => {
      try {
        const saveResponse = await savePublicProfileData(
          firstName,
          lastName,
          email,
        );
        const response = saveResponse as IFetchResult;

        logging.logDebug(
          'SettingsPage -> Profile -> deleteProfilePicture -> response',
          response,
        );

        if (response.error.code === '0') {
          const notifId = uuidv4();
          const notifProp: NotificationType = {
            id: notifId,
            dismissed: false,
            autohide: true,
            closeButton: false,
            type: 'success',
            header: 'Deleting profile picture',
            body: 'Successfully removed profile picture',
          };

          addNNotification(notifProp);
          setIsLoading(false);
          setLoadingPublicProfile(false);
        } else {
          const notifId = uuidv4();
          const notifProp: NotificationType = {
            id: notifId,
            dismissed: false,
            autohide: false,
            closeButton: true,
            type: 'error',
            header: 'Deleting profile picture',
            body: response.error.description,
          };

          errorSavingPublicProfile(
            response.error.description || 'Unkonown error',
          );
          addNNotification(notifProp);
          setIsLoading(false);
          setLoadingPublicProfile(false);
        }
      } catch (error) {
        const notifId = uuidv4();
        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'error',
          header: 'Deleting profile picture',
          body: error as string,
        };

        errorSavingPublicProfile((error as string) || 'Unkonown error');
        addNNotification(notifProp);
        setIsLoading(false);
        setLoadingPublicProfile(false);
      }
    };

    if (executeDeleteProfilePicture) {
      setLoadingPublicProfile(true);
      setExecuteDeleteProfilePicture(false);
      execute();
    }
  }, [executeDeleteProfilePicture, firstName, lastName, email]);

  /**
   * Remove profile picture
   */
  const deleteProfilePicture = async () => {
    setIsLoading(true);

    const ppData = { ...publicProfileUserData! };
    ppData.profilePicture = '';
    setPublicProfileUserData(ppData);
    setExecuteDeleteProfilePicture(true);
  };

  /**
   * Changes on email
   * @param event
   */
  const emailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }

    timeoutId.current = setTimeout(() => {
      const target = event.target as HTMLInputElement;
      const pattern = /^[^\s]+(\s+[^\s]+)*$/;
      const hasWhiteSpace = !pattern.test(event.target.value);

      logging.logDebug(
        'SettingsPage -> Profile -> emailChanged -> [event, hasWhiteSpace]',
        event,
        hasWhiteSpace,
      );

      if (
        validateEmail(target.value) &&
        !target.validity.valueMissing &&
        !hasWhiteSpace
      ) {
        setEmailErrorText('');
        setEmailValid(true);
        setDisplayEmailError(false);
        if (event.target.value !== userData?.email) {
          setHasEmailChangesBeenMade(true);
        } else {
          setHasEmailChangesBeenMade(false);
        }
      } else if (target.validity.valueMissing) {
        setEmailErrorText('Missing value');
        setEmailValid(false);
        setDisplayEmailError(true);
      } else if (hasWhiteSpace) {
        setEmailErrorText('Can not start or end with whitespace');
        setEmailValid(false);
        setDisplayEmailError(true);
      } else {
        setEmailErrorText('Not a valid email pattern');
        setEmailValid(false);
        setDisplayEmailError(true);
      }

      setEmail(target.value);
      timeoutId.current = undefined;
    }, KEYPRESS_WAIT);
  };

  /**
   * Clicked subscription checkbox
   * @param checked
   */
  const subscriptionChanged = (checked: boolean) => {
    setIsSubscriptionsChecked(checked);
  };

  /**
   * Save any changes to names or subscriptions
   * @returns
   */
  const saveNameChanges = () => {
    const updateRes = AuthUtil.updateName(firstName, lastName);

    const data = { firstName, lastName, emailConsent: isSubscriptionsChecked };

    const reqRes = fetchProfile<UserDataType[]>({
      method: 'POST',
      url: '/user',
      timeout: TIMEOUT,
      data,
    });

    const notifHeader = 'Saving name/subscription';

    return Promise.all([updateRes, reqRes])
      .then((values) => {
        logging.logDebug(
          'SettingsPage -> Profile -> clickSave -> done saving',
          values,
        );

        let allGood = 0;

        if (values[0] === 'SUCCESS') {
          // notification all good
          allGood += 1;
        } else {
          // notification error
        }

        if (values[1].error.code === '0') {
          // notification all good
          allGood += 1;

          const myUserData = { ...userData };
          myUserData.emailConsent = isSubscriptionsChecked;
          myUserData.firstName = firstName;
          myUserData.lastName = lastName;

          setUserData(myUserData);

          if (publicProfileUserData) {
            savePublicProfileRef.current = true;
          }
        } else {
          //notification error
        }

        if (allGood === 2) {
          setHasNameChangesBeenMade(false);

          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        const notifId = uuidv4();

        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'error',
          header: notifHeader,
          body:
            error.message ||
            (typeof error === 'string'
              ? error
              : 'Something went wrong saving email'),
        };

        addNNotification(notifProp);
        errorSavingUser(
          true,
          error.message ||
            (typeof error === 'string'
              ? error
              : 'Something went wrong saving email'),
        );

        return Promise.reject();
      });
  };

  /**
   * Save any changes to email
   * @returns
   */
  const saveEmailChanges = () => {
    const notifHeader = 'Saving email';
    const notifId = uuidv4();

    return fetchProfile<any>({
      method: 'POST',
      url: '/change-email',
      timeout: TIMEOUT,
      data: {
        new_email: email,
      },
    })
      .then((res) => {
        if (res.error.code === '0') {
          if (publicProfileUserData) {
            savePublicProfileRef.current = true;
          }

          setRequestExpiresUts(res.data.expires_uts);
          setNewEmail(email);
          setDisplayEmailInfo(true);
          setCheckUpdatedCachedEmail(true);
          setHasEmailChangesBeenMade(false);

          return Promise.resolve();
        } else {
          // something wrong

          const notifProp: NotificationType = {
            id: notifId,
            dismissed: false,
            autohide: false,
            closeButton: true,
            type: 'error',
            header: notifHeader,
            body: 'Something went wrong saving email',
          };

          addNNotification(notifProp);
          errorSavingUser(true, 'Something went wrong saving email');

          return Promise.reject();
        }
      })
      .catch((error) => {
        // notif whats wrong

        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'error',
          header: notifHeader,
          body:
            error.message ||
            (typeof error === 'string'
              ? error
              : 'Something went wrong saving email'),
        };

        addNNotification(notifProp);
        errorSavingUser(
          true,
          error.message ||
            (typeof error === 'string'
              ? error
              : 'Something went wrong saving email'),
        );

        return Promise.reject();
      });
  };

  const onOpenResetPswrdModal = () => {
    setShowResetPswrdModal(true);
  };

  const onCloseResetPswrdModal = () => {
    setShowResetPswrdModal(false);
  };

  const onResetPassword = async () => {
    try {
      setIsLoading(true);
      const response = await AuthUtil.sendResetPassword(email);
      logging.logDebug('Profile -> onResetPassword -> response', response);

      const notifId = uuidv4();

      if (response === true) {
        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'success',
          header: 'Reset password',
          body: (
            <div>
              <div>{`We’ve sent an email to ${email} with instructions.`}</div>
              <br />
              <div>
                If the email doesn't show up soon, check your spam folder. We
                sent it from <strong>login@w3schools.com</strong>.
              </div>
            </div>
          ),
        };

        addNNotification(notifProp);
      } else {
        let errorString =
          'Something went wrong with sending the reset password email.';
        // eslint-disable-next-line no-prototype-builtins
        if (
          typeof response === 'object' &&
          Object.prototype.hasOwnProperty.call(response, 'msg')
        ) {
          errorString = (response as AuthError).msg;
        }

        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'error',
          header: 'Reset password',
          body: errorString,
        };

        addNNotification(notifProp);
      }
      setIsLoading(false);
      onCloseResetPswrdModal();
    } catch (error) {
      const notifId = uuidv4();
      const notifProp: NotificationType = {
        id: notifId,
        dismissed: false,
        autohide: false,
        closeButton: true,
        type: 'error',
        header: 'Reset password',
        body: error as string,
      };

      errorSavingPublicProfile((error as string) || 'Unkonown error');
      addNNotification(notifProp);

      setIsLoading(false);
      onCloseResetPswrdModal();
    }
  };

  /**
   * Click on save button. Initiate save changes
   * @param event
   */
  const clickSave = async (event: any) => {
    logging.logDebug('SettingsPage -> Profile -> clickSave -> event', event);

    event.preventDefault();
    const promiseArray = [];
    setLoading(true);
    setDisablePublicProfile(true);
    if (
      hasNameChangesBeenMade ||
      isSubscriptionsChecked !== userData?.emailConsent
    ) {
      promiseArray.push(saveNameChanges());
    }

    const changeEmail = hasEmailChangesBeenMade;

    if (hasEmailChangesBeenMade) {
      promiseArray.push(saveEmailChanges());
    }

    let allGood = true;

    Promise.allSettled(promiseArray)
      .then((result) => {
        for (let i = 0; i < result.length; i++) {
          if (result[i].status === 'rejected') {
            allGood = false;
            break;
          }
        }

        if (
          savePublicProfileRef.current &&
          allGood &&
          publicProfileUserData &&
          publicProfileUserData.nick
        ) {
          setLoading(false);
          setLoadingPublicProfile(true);
          return savePublicProfileData(firstName, lastName, email);
        }

        return Promise.resolve({
          status: 200,
          rawData: '',
          data: '',
          error: { code: '0' },
        } as IFetchResult);
      })
      .then((response) => {
        savePublicProfileRef.current = false;

        if (allGood && (response as IFetchResult).error.code === '0') {
          const notifId = uuidv4();

          const notifProp: NotificationType = {
            id: notifId,
            dismissed: false,
            autohide: true,
            closeButton: false,
            type: 'success',
            header: 'Saving',
            body: 'Successfully saved profile changes.',
          };

          addNNotification(notifProp);
        } else if (allGood) {
          const notifId = uuidv4();

          const notifProp: NotificationType = {
            id: notifId,
            dismissed: false,
            autohide: false,
            closeButton: true,
            type: 'error',
            header: 'Saving public profile',
            body: (response as IFetchResult).error.description,
          };

          addNNotification(notifProp);
        }

        if (!changeEmail) {
          /* !loadedPublicProfileData && !errorLoadingPublicProfileData &&*/ setDisableEmail(
            false,
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        const notifId = uuidv4();

        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'error',
          header: 'Saving',
          body: error as string,
        };

        addNNotification(notifProp);
      })
      .finally(() => {
        setLoading(false);
        setLoadingPublicProfile(false);

        if (publicProfileUserData && publicProfileUserData.nick) {
          setDisablePublicProfile(false);
        }
      });
  };

  const enterPressedInInput = (event: React.KeyboardEvent) => {
    clickSave(event);
  };

  const errorSavingPublicProfilePicture = (value: boolean, text: string) => {
    errorSavingPublicProfile(text);
    setErrorSavingPublicProfileData(value);
  };

  return (
    <div
      id={id}
      ref={refElement}
      className={`${styles.wrapper}${className ? ` ${className}` : ''}`}
    >
      <ModuleWrapper
        title="User Name"
        subTitle="For Account and Certificates"
        className={styles.module_wrapper}
      >
        <InputItem
          label="First Name"
          disabled={disable /* || errorLoadingPublicProfileData */}
          defaultValue={firstName}
          onValueChange={firstNameChanged}
          onEnterPressed={enterPressedInInput}
          displayError={displayFirstNameError}
          displayValidatedIcon={firstNameValid}
          errorText={firstNameErrorText}
          placeholder="Add your first name"
          autoCapitalize="none"
          name="profile-firstname"
          id={'profile-firstname'}
        />
        <InputItem
          label="Last Name"
          disabled={disable /* || errorLoadingPublicProfileData */}
          defaultValue={lastName}
          onValueChange={lastNameChanged}
          onEnterPressed={enterPressedInInput}
          displayError={displayLastNameError}
          displayValidatedIcon={lastNameValid}
          errorText={lastNameErrorText}
          placeholder="Add your last name"
          autoCapitalize="none"
          name="profile-lastname"
          id={'profile-lastname'}
        />
      </ModuleWrapper>

      <ModuleWrapper
        title={isExternal ? 'Email' : 'Login'}
        subTitle={
          isExternal
            ? 'Your email address linked to W3schools.com'
            : 'Your W3schools.com Login Credentials'
        }
        className={`${styles.module_wrapper} ${styles.padding_top_0}`}
      >
        <InputItem
          label={isExternal ? '' : 'Email'}
          disabled={disableEmail || isPublicProfileInitLoading || isExternal}
          defaultValue={email}
          onValueChange={emailChanged}
          onEnterPressed={enterPressedInInput}
          displayError={displayEmailError}
          displayInfo={displayEmailInfo}
          displayValidatedIcon={emailValid}
          errorText={emailErrorText}
          infoText="Check your email to confirm the new email address"
          placeholder="e.g. jane@example.com"
          autoCapitalize="none"
          name="profile-email"
          id={'profile-email'}
        />
        {!isExternal && (
          <div className={styles.password_wrapper}>
            <InputItem
              label="Password"
              disabled={true}
              defaultValue={password}
              autoCapitalize="none"
              name="password"
              type="password"
              readOnly
            />

            <div
              className={`${styles.reset_pwrd}${disable ? ` ${styles.disabled}` : ''}`}
              onClick={onOpenResetPswrdModal}
            >
              Reset Password
            </div>
          </div>
        )}
      </ModuleWrapper>

      {!isFeideUser && (
        <ModuleWrapper
          title="Your Subscriptions"
          subTitle=""
          className={`${styles.module_wrapper} ${styles.padding_top_0}`}
        >
          <CheckBox
            checked={isSubscriptionsChecked}
            onChange={subscriptionChanged}
            disabled={disable}
            size="md"
            ariaLabel="email me with news and updates"
          >
            Email me with news and updates
          </CheckBox>
        </ModuleWrapper>
      )}

      <div className={styles.action_wrapper}>
        <Button
          isDisabled={isSaveDisabled}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={2}
          size={'md'}
          height={'44px'}
          minWidth={'116px'}
          _disabled={{ color: '#b9bfc1', backgroundColor: '#e7e7e7' }}
          _hover={
            !isSaveDisabled
              ? { borderColor: 'gray.400' }
              : {}
          }
          variant={'outline'}
          onClick={clickSave}
          isLoading={isLoading}
        >
          <BsCloudCheck /> Save
        </Button>
      </div>

      <Modal
        loading={isLoading}
        show={showResetPswrdModal}
        displayCancel
        displayOk
        handleClose={onCloseResetPswrdModal}
        handleOk={onResetPassword}
        title="Reset password"
      >
        Do you want to reset your password?
      </Modal>
    </div>
  );
};

export default Profile;
