/*
  Base code copied from https://bobbyhadz.com/blog/react-check-if-element-in-viewport
**/

import React, { useState, useMemo, useEffect } from 'react';

const useIsInViewport = (ref: React.RefObject<any>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        // console.log(entry);
        setIsIntersecting(entry.isIntersecting);
      }),
    [],
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

export default useIsInViewport;
