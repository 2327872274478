import React, { useEffect } from 'react';
import { logging } from '../../Util/LoggingUtil';
import { authUtil } from '../../Util/AuthUtil';
import { getSanitizedRedirectUrl } from '../../Util/RedirectUtil';
import LoadingModal from '../LoadingModal/LoadingModal';


export interface LoginFullPageProps {
    start_signup?: boolean
}

function LogoutFullPage() {

    const redirectUrl = getSanitizedRedirectUrl({ loggedOut: true, fallback: '/' });

    useEffect(() => {
        const logout = async () => {
            logging.logInfo("Logging out and redirecting to: ", redirectUrl)
            await authUtil.logOut({
              context: 'LFPC',
              reason: {
                landedOnLogOutPage: true,
              },
            });

            window.location.href = redirectUrl!;
        }

        logout()
    }, [authUtil])

    return (
        <div>
            <LoadingModal
                header="Logging out..."
                message="You are now being logged out from W3Schools"
                show={true}
                full_page={true}
                close={() => { }} />
        </div>
    )
}
export default LogoutFullPage
