import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { BsCalendar } from 'react-icons/bs';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './ReactDatePicker.module.css';

interface DatePickerComponentProps extends Partial<ReactDatePickerProps> {
  selected: Date | null;
  onChange: (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined,
  ) => void;
  dateFormat: string;
  placeholderText: string;
}

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  selected,
  onChange,
  dateFormat,
  placeholderText,
  ...rest
}) => {
  return (
    <DatePicker
      selected={selected}
      onChange={onChange}
      dateFormat={dateFormat}
      placeholderText={placeholderText}
      showIcon
      {...{ toggleCalendarOnIconClick: true }}
      isClearable
      icon={<BsCalendar className={`${styles.icon_position}`} />}
      portalId="root-portal"
      className={`chakra-input border border-gray-300 rounded-lg pl-4 w-full focus:border-blue-500 dark:bg-gray-700 dark:focus:border-blue-500`}
      wrapperClassName="w-full focus:border-blue-500"
      closeOnScroll={true}
      todayButton="Today"
      clearButtonClassName="right-50"
      maxDate={new Date()}
      minDate={new Date('1950-01-01')}
      {...rest}
    />
  );
};

export default DatePickerComponent;
