import React from 'react';
import { useColorMode } from '@chakra-ui/react';

export interface IW3CoinIcon {
  className?: string;
  size?: string;
  color?: string;
}

function W3CoinIcon({ className, size, color }: IW3CoinIcon) {
  const { colorMode } = useColorMode();
  color = color ? color : colorMode === 'dark' ? '#fff' : '#000';
  const iconSize = size ?? '16';
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={'0 0 16 16'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ''}
    >
      <g clipPath="url(#clip0_80_2346)">
        <path
          d="M8 15C4.16 15 1 11.84 1 8C1 4.16 4.16 1 8 1C11.84 1 15 4.16 15 8C15 11.84 11.84 15 8 15ZM8 16C12.389 16 16 12.389 16 8C16 3.611 12.389 0 8 0C3.611 0 0 3.611 0 8C0 12.389 3.611 16 8 16Z"
          fill={color}
        />
        <path
          d="M8 13.5C4.983 13.5 2.5 11.017 2.5 8C2.5 4.983 4.983 2.5 8 2.5C11.017 2.5 13.5 4.983 13.5 8C13.5 11.017 11.017 13.5 8 13.5ZM8 14C11.292 14 14 11.292 14 8C14 4.708 11.292 2 8 2C4.708 2 2 4.708 2 8C2 11.292 4.708 14 8 14Z"
          fill={color}
        />
        <path
          d="M10.8407 7.00237C10.8407 7.00237 11.0487 7.22637 11.3407 7.22637C11.5707 7.22637 11.7377 7.08737 11.7377 6.89637C11.7377 6.66237 11.5247 6.55437 11.2657 6.55437H11.1137L11.0227 6.34837L11.4267 5.86937C11.5127 5.76637 11.5857 5.70137 11.5857 5.70137C11.5857 5.70137 11.5207 5.70437 11.3897 5.70437H10.7327V5.35938H12.0787V5.61137L11.5387 6.23737C11.8437 6.28037 12.1417 6.49737 12.1417 6.88137C12.1417 7.25737 11.8567 7.60637 11.3657 7.60637C10.8967 7.60637 10.6367 7.31137 10.6367 7.31137L10.8407 7.00237Z"
          fill={color}
        />
        <path
          d="M8.21338 7.46812L9.99237 10.6411L10.8694 9.07312L9.19037 6.07812H7.23638L6.42638 7.52312L5.61638 6.07812H3.85938L6.41637 10.6411L6.42638 10.6241L6.43637 10.6411L8.21338 7.46812Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_80_2346">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default W3CoinIcon;
