import React, { ReactNode } from 'react';
import {
  Box,
  Stack,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Select,
  useColorModeValue,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import {
  visibilityOptions,
  visibilityOptionsVisual,
  IUserProfileSettings,
} from '../../../userlib_new/utils/profile-interfaces';
import { useProfile } from '../../../../context/profileProvider';
import W3Info from '../../../icons/W3Info/W3Info';

interface AccordionSectionProps {
  title: string;
  sectionKey: keyof IUserProfileSettings;
  children: ReactNode;
}

export const AccordionSection: React.FC<AccordionSectionProps> = ({
  title,
  sectionKey,
  children,
}) => {
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { userProfileSettings, handleProfileSettingsChange } = useProfile();

  return (
    <AccordionItem
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      boxShadow="lg"
      my={3}
      p={{ base: 2, md: 4 }}
      _dark={{ borderColor: 'white', bg: '#282A35' }}
      bg={'white'}
    >
      <AccordionButton _expanded={{ borderRadius: 'lg' }}>
        <AccordionIcon mr={2} />
        <Box flex="1" textAlign="left" fontWeight="medium">
          {title}
        </Box>

        {sectionKey !== 'company' && (
          <>
            <Select
              id={`description-${sectionKey}`}
              w={{ base: '150px', sm: '200px' }}
              ml={4}
              value={userProfileSettings[sectionKey]}
              onChange={(e) =>
                handleProfileSettingsChange(
                  sectionKey,
                  e.target.value as visibilityOptions,
                )
              }
              onClick={(e) => e.stopPropagation()}
            >
              {Object.entries(visibilityOptions).map(([key, value]) => (
                <option key={key} value={value}>
                  {
                    visibilityOptionsVisual[
                      key as keyof typeof visibilityOptionsVisual
                    ]
                  }
                </option>
              ))}
            </Select>
            <Tooltip
              label={
                <Stack>
                  <strong>Visibility Options:</strong>
                  <Text>
                    <strong>Public:</strong> This section will be visible to 
                    anyone if you have a public profile.{' '}
                  </Text>
                  <Text>
                    <strong>Private:</strong> This section will only be visible 
                    to you. It won&apos;t be accessible to other users.
                  </Text>
                </Stack>
              }
            >
              <Box as="span">
                <W3Info />
              </Box>
            </Tooltip>
          </>
        )}
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Stack spacing={{ base: 3, md: 4 }}>{children}</Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};
