import React from 'react';
import { getClassicUrl } from '../../../Util/EnvironmentUtil';
import { sendGoogleAnalyticsEvent } from '../../../Util/GoogleAnalyticsUtil';
import W3Logo from '../../Icons/W3Logo/W3Logo';

interface IW3LogoButtonProps {
  loggedIn: boolean,
  mimicTopNavBarLikePlacement?: boolean,
  className?: string,
  width?: string,
  height?: string,
  viewBox?: string,
}

function W3LogoButton({
  loggedIn,
  className,
  mimicTopNavBarLikePlacement = false,
  width,
  height,
  viewBox,
}: IW3LogoButtonProps) {
  const styles: React.CSSProperties = {};

  if (mimicTopNavBarLikePlacement) {
    styles.margin = '18px 40px';
    styles.display = 'inline-block';
  }

  return (
    <a
      className={className ? className : ''}
      style={styles}
      href={getClassicUrl()}
      onClick={() =>
        sendGoogleAnalyticsEvent({
          name: `Clicked on logo in topbar ${
            loggedIn ? 'when logged in' : 'anonymously'
          }`,
          category: loggedIn ? 'Topbar after login' : 'Topbar anonymously'
        })
      }
      aria-label='W3Schools logo'
    >
      <W3Logo width={width} height={height} viewBox={viewBox} />
    </a>
  );
}

export default W3LogoButton;
