import {
  ComponentTypes,
  IActivity,
  ICertificates,
  IDefault,
  IDiscord,
  IInterests,
  ISpaces,
  IUserProfileConfiguration,
  SocialMediaTypes,
} from '../../Component/userlib_new/utils/db-interfaces';

export const DEFAULT_ACTIVITY_DATA: IActivity = {
  type: ComponentTypes.Activity,
  visible: false,
  stars: 0,
  lessonsRead: 0,
  exercisePoints: 0,
  quizPoints: 0,
  totalPoints: 0,
};

export const DEFAULT_SAVE_ACTIVITY_DATA: IDefault = {
  type: ComponentTypes.Activity,
  visible: false,
};

export const DEFAULT_DISCORD_DATA: IDiscord = {
  type: ComponentTypes.Discord,
  visible: false,
  user: '',
  role: '',
  messages: '',
  experience: '',
  level: 0,
};

export const DEFAULT_CERTIFICATES_DATA: ICertificates = {
  type: ComponentTypes.Certificates,
  visible: false,
  certificates: [],
};

export const DEFAULT_SPACES_DATA: ISpaces = {
  type: ComponentTypes.Spaces,
  visible: false,
  spaces: [],
};

export const DEFAULT_INTERESTS_DATA: IInterests = {
  type: ComponentTypes.Interests,
  visible: false,
  tags: [],
};

export const DEFAULT_PUBLIC_PROFILE_DATA: IUserProfileConfiguration = {
  id: undefined,
  userId: undefined,
  createdAt: 1656504039,
  updatedAt: 1656504039,
  basic: {
    firstName: '',
    lastName: '',
    aboutMe: undefined,
    profilePicture: undefined,
    phone: { value: '', visible: false },
    email: { value: '', visible: true },
    website: { value: '', visible: false },
  },
  socialMedias: [
    {
      type: SocialMediaTypes.Email,
      userName: '',
      visible: false,
    },
    {
      type: SocialMediaTypes.Phone,
      userName: '',
      visible: false,
    },
    {
      type: SocialMediaTypes.LinkedIn,
      userName: '',
      visible: false,
    },
    {
      type: SocialMediaTypes.Twitter,
      userName: '',
      visible: false,
    },
    {
      type: SocialMediaTypes.GitHub,
      userName: '',
      visible: false,
    },
  ],
  sections: [
    {
      ...DEFAULT_ACTIVITY_DATA,
    },
    /* {
      ...DEFAULT_DISCORD_DATA
    }, */
    {
      ...DEFAULT_CERTIFICATES_DATA,
    },
    {
      ...DEFAULT_SPACES_DATA,
    },
    {
      ...DEFAULT_INTERESTS_DATA,
    },
  ],
  published: false,
};
