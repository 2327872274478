import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const xl = defineStyle({
  fontSize: 'xl',
  px: '6',
  h: '16',
  borderRadius: 'md',
});

const xxl = defineStyle({
  fontSize: '2xl',
  px: '8',
  h: '20',
  borderRadius: 'md',
});

const CloseButtonStyleConfig = defineStyleConfig({
  sizes: { xl, xxl },
});

export default CloseButtonStyleConfig;
