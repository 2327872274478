import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Spinner, useToast } from '@chakra-ui/react';
import _ from 'lodash';
import {
  IEducation,
  IExperience,
  ErrorType,
  IActivityScore,
  ISpaces,
  ICertification,
  IUserProfile,
  IUserProfileSettings,
  IChakraReactSelect,
  ITagType,
  CompanyField,
  visibilityOptions,
  ICompany,
  IUserPersonal,
  IContact,
  ISkill,
  CroppedArea,
  IExperienceResponse,
  IEducationResponse,
  IUserPersonalResponse,
} from '../Component/userlib_new/utils/profile-interfaces';
import {
  getProfileData,
  getPresignedUrlForUpload,
  uploadFileToS3,
  getPresignedUrlForDownload,
  getMyLearningActivity,
  getCertificationData,
  getSpacesData,
  getOpenToWork,
  setOpenToWork,
  getProfileSettings,
  getInterests,
  getAvailableInterests,
  getCompanies,
  postProfileSettings,
  postProfileData,
  getSkills,
  checkNickAvailability,
  createPublicProfile,
  getPublicProfile,
  changePublicProfileVisibility,
  getPresignedUrlForCompanyLogo,
  getResumeParserState,
  getQuataForProfileSubmission,
  postRefreshSpaceThumbnail,
} from '../utils/profile';
import { ChartData } from '../Component/Charts/RadarChart/RadarChart';
import { v4 as uuidv4 } from 'uuid';
import { getCroppedImg } from '../utils/cropImage';
import { callTopNavBarFunction } from '../lib/topNavBar';
import { getPublicProfileUrl } from '../utils/pathsUtil';
import { fetchKaiTokenBalance } from '../utils/kai';
import { useUnsavedChanges } from './UnsavedChangesProvider';
import {
  getContinentFullName,
  getISODate,
  getMonthYearFromDate,
} from '../utils/dataUtil';
import { COUNTRY_DATA } from '../utils/countryData';

const defaultErrorObject: { hasError: boolean; message: any; callback: any } = {
  hasError: false,
  message: undefined,
  callback: undefined,
};

const defaultContext = {
  activityScore: {} as IActivityScore,
  isLoadingSpaces: false,
  spaces: [] as ISpaces[],
  certifications: [] as ICertification[],
  isLoading: false,
  isOpenToWork: false,
  updateOpenToWorkStatus: (openToWork: boolean) => void 0,
  isLoadingOpenToWork: false,
  error: { ...defaultErrorObject },
  loadData: () => void 0,
  loadDailyQuata: () => void 0,
  loadMyLearningActivity: () => void 0,
  loadCertifications: () => void 0,
  loadSpaces: () => void 0,
  resetError: () => void 0,
  showMessage: (
    message: string | JSX.Element,
    status: 'info' | 'warning' | 'error' | 'success',
  ) => void 0,
  handleUploadCV: async (file: File) => void 0,
  handleLogoUpload: async (file: File, index: number) => void 0,
  selectedFile: null,
  onFileSelected: (file: File) => void 0,
  onCompanyLogoSelected: (file: File, index: number) => void 0,
  handleLogoDelete: (index: number) => void 0,
  handleDownloadCV: async () => void 0,
  isUploading: false,
  isCVUploading: false,
  isDownloading: false,
  chartData: {} as ChartData,
  userProfile: {} as IUserProfile,
  userProfileSettings: {} as IUserProfileSettings,
  loadUserProfileSettings: () => void 0,
  countryData: [] as IChakraReactSelect[],
  onCountryChange: (countryCode: string) => void 0,
  companies: [] as ICompany[],
  loadCompanies: () => void 0,
  addCompany: () => void 0,
  deleteCompany: (index: number) => void 0,
  updateCompany: (index: number, field: string, value: string) => void 0,
  invalidCompanyFields: {},
  invalidExperienceFields: {},
  invalidEducationFields: {},
  invalidPersonalFields: {},
  loadInterests: () => void 0,
  interests: [] as ITagType[],
  loadAvailableInterests: () => void 0,
  availableInterests: [] as IChakraReactSelect[],
  handleInterestChange: (e: any) => void 0,
  handleEducationChange: (
    index: number,
    field: keyof IEducation,
    value: string | Date | null,
  ) => void 0,
  deleteEducationEntry: (index: number) => void 0,
  addEducationEntry: () => void 0,
  addExperienceEntry: () => void 0,
  deleteExperienceEntry: (index: number) => void 0,
  handleExperienceChange: (
    index: number,
    field: keyof IExperience,
    value: string | Date | null,
  ) => void 0,
  handleProfileSettingsChange: (
    sectionKey: keyof IUserProfileSettings,
    value: visibilityOptions,
  ) => void 0,
  disableSaveProfileButton: false,
  handleProfileSubmit: () => void 0,
  handleSaveClick: () => void 0,
  confirmProfileSubmissionModalOpen: false,
  setConfirmProfileSubmissionModalOpen: (value: boolean) => void 0,
  handlePersonalChange: (
    field: keyof IUserPersonal,
    value: string | Date | null,
  ) => void 0,
  handleAboutMeChange: (value: string) => void 0,
  handleContactChange: (
    field: keyof IContact,
    changeType: 'value' | 'visible',
    newValue: string | boolean,
  ) => void 0,
  handleSpaceChange: (
    index: number,
    field: keyof ISpaces,
    value: string | boolean,
  ) => void 0,
  spaceThumbnailPendingReqs: [],
  handleRefreshSpaceThumbnail: (spaceId: string) => void 0,
  handleCertificationChange: (index: number, value: boolean) => void 0,
  mySkills: [] as ISkill[],
  loadSkills: () => void 0,
  handleSkillsChange: (index: number, value: boolean) => void 0,
  nickname: '' as string,
  isNicknameAvailable: null as boolean | null,
  handleNicknameChange: (nickname: string) => void 0,
  publicProfileVisibility: false as boolean,
  handlePublicProfileVisibility: (value: boolean) => void 0,
  loadPublicProfile: () => void 0,
  crop: { x: 0, y: 0 },
  zoom: 1 as number,
  croppedAreaPixels: null as CroppedArea | null,
  isModalOpen: false as boolean,
  selectedImage: null as string | null,
  croppedImage: null as string | null,
  imageType: '' as string,
  handleProfileImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void 0,
  onCropComplete: (croppedArea: any, croppedAreaPixels: any) => void 0,
  handleEditConfirm: () => void 0,
  setIsModalOpen: (value: boolean) => void 0,
  setCrop: (value: { x: number; y: number }) => void 0,
  setZoom: (value: number) => void 0,
  isProfileImageUploading: false,
  isProfileSaving: false,
  accordionIndex: [] as number[],
  setAccordionIndex: (index: number[]) => void 0,
  dailyQuataTotal: 0,
  dailyQuataRemain: 0,
  wallet: 0,
  fetchTokenBalance: () => Promise.resolve(),
};

const ProfileContext = createContext<{
  /**
   * The data for the activity score
   */
  activityScore: IActivityScore;

  /**
   * True if loading spaces details
   */
  isLoadingSpaces: boolean;
  /**
   * The data for the spaces
   */
  spaces: ISpaces[];
  /**
   * The data for the certifications
   */
  certifications: ICertification[];
  /**
   * True if loading new content
   */
  isLoading: boolean;
  /**
   * True if open to work
   */
  isOpenToWork: boolean;
    /**
   * Update the open to work status
   * @returns
   */
  updateOpenToWorkStatus: (openToWork: boolean) => void;
  /**
   * True if loading open to work status
   */
  isLoadingOpenToWork: boolean;
  /**
   * Holds error data if any occurs
   *
   * @type {ErrorType}
   */
  error: ErrorType;
  /**
   * Load the jobs card data
   * @returns
   */
  loadData: () => void;
  /**
   * Load the daily quota
   * @returns
   */
  loadDailyQuata: () => void;
  /**
   * Load the user activity
   * @returns
   */
  loadMyLearningActivity: () => void;
  /**
   * Load the certifications
   * @returns
   */
  loadCertifications: () => void;
  /**
   * Load the spaces
   * @returns
   */
  loadSpaces: () => void;
  /**
   * Reset the hasError flag and errorMessage
   * @returns
   */
  resetError: () => void;
  /**
   * Show a message to the user
   * @returns
   */
  showMessage: (
    message: string | JSX.Element,
    status: 'info' | 'warning' | 'error' | 'success',
  ) => void;
  /**
   * Upload the CV
   * @returns
   */
  handleUploadCV: (file: File) => void;
  /**
   * Upload the company logo
   * @returns
   */
  handleLogoUpload: (file: File, index: number) => void;
  /**
   * The selected file
   */
  selectedFile: File | null;
  /**
   * Set the selected file
   */
  onFileSelected: (file: File) => void;
  /**
   * Set the selected company logo
   */
  onCompanyLogoSelected: (file: File, index: number) => void;
  /**
   * Delete the selected company logo
   */
  handleLogoDelete: (index: number) => void;
  /**
   * Download the CV
   * @returns
   */
  handleDownloadCV: () => void;
  /**
   * True if uploading the Comapny logo
   */
  isUploading: boolean;
  /**
   * True if uploading the logo
   */
  isCVUploading: boolean;
  /**
   * True if downloading the CV
   */
  isDownloading: boolean;
  /**
   * chart data
   */
  chartData: ChartData;
  /**
   * user's basic data
   */
  userProfile: IUserProfile;
  /**
   * user's profile Settings
   */
  userProfileSettings: IUserProfileSettings;
  /**
   * load user profile settings
   */
  loadUserProfileSettings: () => void;
  /**
   * country data
   */
  countryData: IChakraReactSelect[];
  /**
   * On change country data
   */
  onCountryChange: (countryCode: string) => void;
  /**
   * companies
   */
  companies: ICompany[];
  /**
   * Load the companies
   */
  loadCompanies: () => void;
  /**
   * Add a new company
   */
  addCompany: () => void;
  /**
   * Delete a company
   */
  deleteCompany: (index: number) => void;
  /**
   * Update a company
   */
  updateCompany: (index: number, field: string, value: string) => void;
  /**
   * Invalid company fields
   */
  invalidCompanyFields: Record<number, { [key in CompanyField]?: boolean }>;
  /**
   * Invalid experience fields
   */
  invalidExperienceFields: Record<
    number,
    { [key in keyof IExperience]?: boolean }
  >;
  /**
   * Invalid education fields
   */
  invalidEducationFields: Record<
    number,
    { [key in keyof IEducation]?: boolean }
  >;
  /**
   * Invalid personal fields
   */
  invalidPersonalFields: { [key in keyof IUserPersonal]?: boolean };
  /**
   * Load the user interests
   */
  loadInterests: () => void;
  /**
   * user interests
   */
  interests: ITagType[];
  /**
   * Load the available interests
   */
  loadAvailableInterests: () => void;
  /**
   * available interests
   */
  availableInterests: IChakraReactSelect[];
  /**
   * Handle interest change
   */
  handleInterestChange: (e: any) => void;
  /**
   * Handle education change
   */
  handleEducationChange: (
    index: number,
    field: keyof IEducation,
    value: string | Date | null,
  ) => void;
  /**
   * Delete education entry
   */
  deleteEducationEntry: (index: number) => void;
  /**
   * Add education entry
   */
  addEducationEntry: () => void;
  /**
   * Add experience entry
   */
  addExperienceEntry: () => void;
  /**
   * Delete experience entry
   */
  deleteExperienceEntry: (index: number) => void;
  /**
   * Handle experience change
   */
  handleExperienceChange: (
    index: number,
    field: keyof IExperience,
    value: string | Date | null,
  ) => void;
  /**
   * Handle profile settings change
   */
  handleProfileSettingsChange: (
    sectionKey: keyof IUserProfileSettings,
    value: visibilityOptions,
  ) => void;
  /**
   * Disable save profile button
   */
  disableSaveProfileButton: boolean;
  /**
   * Handle profile submit
   */
  handleProfileSubmit: () => void;
  /**
   * Handle save click
   */
  handleSaveClick: () => void;
  /**
   * profile submission confirmation modal open
   */
  confirmProfileSubmissionModalOpen: boolean;
  /**
   * Set profile submission confirmation modal open
   */
  setConfirmProfileSubmissionModalOpen: (value: boolean) => void;
  /**
   * Handle personal change
   */
  handlePersonalChange: (
    field: keyof IUserPersonal,
    value: string | Date | null,
  ) => void;
  /**
   * Handle about me change
   */
  handleAboutMeChange: (value: string) => void;
  /**
   * Handle contact change
   */
  handleContactChange: (
    field: keyof IContact,
    changeType: 'value' | 'visible',
    newValue: string | boolean,
  ) => void;
  /**
   * Handle space change
   */
  handleSpaceChange: (
    index: number,
    field: keyof ISpaces,
    value: string | boolean,
  ) => void;
  /**
   * List of pending spaces thumbnail requests
   */
  spaceThumbnailPendingReqs: string[];
  /**
   * Handle space refresh thumbnail
   */
  handleRefreshSpaceThumbnail: (spaceId: string) => void;
  /**
   * Handle certification change
   */
  handleCertificationChange: (index: number, value: boolean) => void;
  /**
   * User skills
   */
  mySkills: ISkill[];
  /**
   * Load skills
   */
  loadSkills: () => void;
  /**
   * Handle skills change
   */
  handleSkillsChange: (index: number, value: boolean) => void;
  /**
   * User nickname
   */
  nickname: string;
  /**
   * Is nickname available
   */
  isNicknameAvailable: boolean | null;
  /**
   * Handle nickname change
   */
  handleNicknameChange: (nickname: string) => void;
  /**
   *public profile visibility
   */
  publicProfileVisibility: boolean;
  /**
   * Handle public profile visibility
   */
  handlePublicProfileVisibility: (value: boolean) => void;
  /**
   * Load public profile
   */
  loadPublicProfile: () => void;
  /**
   * Cropper
   */
  crop: { x: number; y: number };
  /**
   * Zoom
   */
  zoom: number;
  /**
   * Cropped area pixels
   */
  croppedAreaPixels: CroppedArea | null;
  /**
   * Is modal open
   */
  isModalOpen: boolean;
  /**
   * Selected image
   */
  selectedImage: string | null;
  /**
   * Cropped image
   */
  croppedImage: string | null;
  /**
   * Image type
   */
  imageType: string;
  /**
   * Handle profile image change
   */
  handleProfileImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * On crop complete
   */
  onCropComplete: (croppedArea: any, croppedAreaPixels: any) => void;
  /**
   * Handle edit confirm
   */
  handleEditConfirm: () => void;
  /**
   * Set is modal open
   */
  setIsModalOpen: (value: boolean) => void;
  /**
   * Set crop
   */
  setCrop: (value: { x: number; y: number }) => void;
  /**
   * Set zoom
   */
  setZoom: (value: number) => void;
  /**
   * Is profile image uploading
   */
  isProfileImageUploading: boolean;
  /**
   * Is profile saving
   */
  isProfileSaving: boolean;
  /**
   * Accordion index
   */
  accordionIndex: number[];
  /**
   * Handle accordion change
   */
  setAccordionIndex: (index: number[]) => void;
  /**
   * Daily quota total
   */
  dailyQuataTotal: number;
  /**
   * Daily quota remain
   */
  dailyQuataRemain: number;
  /**
   * Wallet
   */
  wallet: number;
  /**
   * Fetch token balance
   */
  fetchTokenBalance: () => Promise<void>;
}>(defaultContext);

/* eslint-disable @typescript-eslint/ban-types */
export default function ProfileProvider({ children }: PropsWithChildren<{}>) {
  const [activityScore, setActivityScore] = useState<IActivityScore>(
    {} as IActivityScore,
  );
  const [isLoadingSpaces, setIsLoadingSpaces] = useState(false);
  const [spaces, setSpaces] = useState<ISpaces[]>([]);
  const [certifications, setCertifications] = useState<ICertification[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorType>({ ...defaultErrorObject });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isCVUploading, setIsCVUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [chartData, setChartData] = useState<ChartData>({} as ChartData);
  const [isOpenToWork, setIsOpenToWork] = useState(false);
  const [isLoadingOpenToWork, setIsLoadingOpenToWork] = useState(false);
  const [userProfile, setUserProfile] = useState<IUserProfile>(
    {} as IUserProfile,
  );
  const [userProfileSettings, setUserProfileSettings] =
    useState<IUserProfileSettings>({} as IUserProfileSettings);
  const [countryData, setCountryData] = useState<IChakraReactSelect[]>(
    [] as IChakraReactSelect[],
  );
  const [interests, setInterests] = useState<ITagType[]>([] as ITagType[]);
  const [availableInterests, setAvailableInterests] = useState<
    IChakraReactSelect[]
  >([] as IChakraReactSelect[]);
  const [invalidCompanyFields, setInvalidCompanyFields] = useState({});
  const [invalidExperienceFields, setInvalidExperienceFields] = useState({});
  const [invalidEducationFields, setInvalidEducationFields] = useState({});
  const [invalidPersonalFields, setInvalidPersonalFields] = useState({});
  const [disableSaveProfileButton, setDisableSaveProfileButton] =
    useState(false);
  const [companies, setCompanies] = useState<ICompany[]>([] as ICompany[]);
  const [mySkills, setMySkills] = useState<ISkill[]>([] as ISkill[]);
  const [nickname, setNickname] = useState('');
  const [isNicknameAvailable, setIsNicknameAvailable] = useState(null);
  const [publicProfileVisibility, setPublicProfileVisibility] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] =
    useState<CroppedArea | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [imageType, setImageType] = useState<string>('');
  const [isProfileImageUploading, setIsProfileImageUploading] = useState(false);
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [isProfileSaving, setIsProfileSaving] = useState(false);
  const [accordionIndex, setAccordionIndex] = useState([] as number[]);
  const [dailyQuataTotal, setDailyQuataTotal] = useState(0);
  const [dailyQuataRemain, setDailyQuataRemain] = useState(0);
  const [
    confirmProfileSubmissionModalOpen,
    setConfirmProfileSubmissionModalOpen,
  ] = useState(false);
  const [wallet, setWallet] = useState<number>(0);
  const [spaceThumbnailPendingReqs, setSpaceThumbnailPendingReqs] = useState<
    string[]
  >([]);

  // Define a ref to keep track of the previous userProfile state
  const previousUserProfileRef = useRef<IUserProfile>({} as IUserProfile);
  const previousSpacesRef = useRef<ISpaces[]>([]);
  const previousCompaniesRef = useRef<ICompany[]>([]);
  const previousInterestsRef = useRef<ITagType[]>([]);
  const previousCertificationsRef = useRef<ICertification[]>([]);
  const previousUserProfileSettingsRef = useRef<IUserProfileSettings>(
    {} as IUserProfileSettings,
  );
  const previousMySkillsRef = useRef<ISkill[]>([] as ISkill[]);
  const previousNicknameRef = useRef<string>('');
  const previousPublicProfileVisibilityRef = useRef<boolean>(false);

  const { setHasUnsavedChanges } = useUnsavedChanges();

  // to keep track of the state of companies
  const companiesRef = useRef(companies);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isCVUploadingRef = useRef(isCVUploading);

  const toast = useToast();
  const userID = userProfile?.userId;

  const showMessage = useCallback(
    (
      message: string | JSX.Element,
      status: 'info' | 'warning' | 'error' | 'success',
    ) => {
      toast({
        duration: 10000,
        status,
        title: status.charAt(0).toUpperCase() + status.slice(1),
        description: message,
      });
    },
    [toast],
  );

  const resetError = useCallback(() => {
    const myError = { ...error };
    myError.hasError = false;
    myError.message = undefined;
    myError.callback = undefined;
    setError(myError);
  }, [error]);

  const fetchTokenBalance = useCallback(async () => {
    try {
      const {
        balance: { tokens },
      } = await fetchKaiTokenBalance();
      setWallet(tokens);
      return Promise.resolve();
    } catch (error: any) {
      const myError = { ...defaultErrorObject };
      myError.hasError = true;
      myError.message = error.message;
      setError(myError);
      return Promise.reject(error.message);
    }
  }, []);

  useEffect(() => {
    const fieldsToCheck = [
      {
        current: userProfileSettings,
        previous: previousUserProfileSettingsRef.current,
      },
      { current: userProfile, previous: previousUserProfileRef.current },
      { current: companies, previous: previousCompaniesRef.current },
      { current: spaces, previous: previousSpacesRef.current },
      { current: interests, previous: previousInterestsRef.current },
      { current: certifications, previous: previousCertificationsRef.current },
      { current: mySkills, previous: previousMySkillsRef.current },
      { current: nickname, previous: previousNicknameRef.current },
      {
        current: publicProfileVisibility,
        previous: previousPublicProfileVisibilityRef.current,
      },
    ];
    const anyChangesDetected = fieldsToCheck.some(
      (field) => !_.isEqual(field.current, field.previous),
    );

    setHasUnsavedChanges(anyChangesDetected);
  }, [
    userProfileSettings,
    userProfile,
    nickname,
    publicProfileVisibility,
    companies,
    spaces,
    interests,
    certifications,
    mySkills,
    setHasUnsavedChanges,
  ]);

  const loadData = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const data = await getProfileData();

        const experience = mapExperience(data.experience);
        const education = mapEducation(data.education);
        const personal = mapPersonal(data.personal);
        const userProfile: IUserProfile = {
          userId: data.userId,
          personal: personal,
          contacts: data.contacts,
          experience: experience,
          education: education,
          aboutMe: data.aboutMe,
        };
        setUserProfile(userProfile);
        previousUserProfileRef.current = userProfile;
      } catch (err) {
        console.error('Error getting profile data:', err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const loadDailyQuata = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const data = await getQuataForProfileSubmission();

        setDailyQuataTotal(data.limit);
        setDailyQuataRemain(data.remainingCount);
      } catch (err) {
        console.error('Error:', err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const mapExperience = (experienceData?: IExperienceResponse[]) =>
    experienceData?.map<IExperience>((exp) => {
      const {
        duties = [],
        startDate: startDateString = '',
        endDate: endDateString = '',
        ...rest
      } = exp;
      // Convert duties array to a string
      const dutiesString = duties.join(', ');

      let startDate: Date | null = null;
      let endDate: Date | null = null;

      const startDateParsed = Date.parse(startDateString);
      const endDateParsed = Date.parse(endDateString);

      if (!isNaN(startDateParsed)) {
        startDate = new Date(startDateParsed);
      }

      if (!isNaN(endDateParsed)) {
        endDate = new Date(endDateParsed);
      }

      return {
        ...rest,
        dutiesString,
        startDate,
        endDate,
      };
    });

  const mapEducation = (educationData?: IEducationResponse[]) =>
    educationData?.map<IEducation>((edu) => {
      const {
        startDate: startDateString = '',
        endDate: endDateString = '',
        ...rest
      } = edu;

      let startDate: Date | null = null;
      let endDate: Date | null = null;

      const startDateParsed = Date.parse(startDateString);
      const endDateParsed = Date.parse(endDateString);

      if (!isNaN(startDateParsed)) {
        startDate = new Date(startDateParsed);
      }

      if (!isNaN(endDateParsed)) {
        endDate = new Date(endDateParsed);
      }

      return {
        ...rest,
        startDate,
        endDate,
      };
    });

  const mapPersonal = (personalData?: IUserPersonalResponse): IUserPersonal => {
    const { dateOfBirth: dobString = '', ...rest } = personalData ?? {};

    const dobParsed = Date.parse(dobString);
    const dateOfBirth = isNaN(dobParsed) ? null : new Date(dobParsed);

    return {
      ...rest,
      dateOfBirth,
    } as IUserPersonal;
  };

  //needed to format the experience data
  const convertExperience = (mappedExperienceData: IExperience[]) => {
    const convertedExperienceData = mappedExperienceData?.map(
      (exp: IExperience) => {
        // Convert duties string to an array without splitting
        let duties: string[] = [];
        if (exp.dutiesString) {
          duties = [exp.dutiesString];
        }

        //convert start and end date to string
        const startDate = exp.startDate
          ? getMonthYearFromDate(exp.startDate)
          : '';
        const endDate = exp.endDate ? getMonthYearFromDate(exp.endDate) : '';

        return {
          ...exp,
          duties,
          startDate,
          endDate,
        };
      },
    );

    //now remove duteisString
    convertedExperienceData.forEach((exp) => {
      delete exp.dutiesString;
    });
    return convertedExperienceData;
  };

  const convertEducation = (mappedEducationData: IEducation[]) => {
    const convertedEducationData = mappedEducationData?.map(
      (edu: IEducation) => {
        //convert start and end date to string
        const startDate = edu.startDate
          ? getMonthYearFromDate(edu.startDate)
          : '';
        const endDate = edu.endDate ? getMonthYearFromDate(edu.endDate) : '';

        return {
          ...edu,
          startDate,
          endDate,
        };
      },
    );

    return convertedEducationData;
  };

  const convertPersonal = (mappedPersonalData: IUserPersonal) => {
    const dateOfBirthString = mappedPersonalData.dateOfBirth
      ? getISODate(mappedPersonalData.dateOfBirth)
      : '';

    return {
      ...mappedPersonalData,
      dateOfBirth: dateOfBirthString,
    };
  };

  const handleProfileImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];

        if (!file.type.startsWith('image/')) {
          console.warn('You can only upload image files!');
          return;
        }

        setImageType(file.type);

        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            setSelectedImage(reader.result);
            setIsModalOpen(true);
          }
        };
        reader.readAsDataURL(file);
      }
    },
    [],
  );

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const handleEditConfirm = useCallback(async () => {
    setIsProfileImageUploading(true);
    try {
      const croppedData = await getCroppedImg(
        selectedImage!,
        croppedAreaPixels!,
        imageType,
      );
      setCroppedImage(croppedData.url);
      setIsModalOpen(false);

      const imageBlob = await fetch(croppedData.url).then((response) =>
        response.blob(),
      );
      const fileName = croppedData.fileName;
      const file = new File([imageBlob], fileName, { type: imageType });

      const data = await getPresignedUrlForCompanyLogo(
        file.name,
        file.type,
        'profile-pictures',
      );
      const success = await uploadFileToS3(data.url, file);
      if (success) {
        setUserProfile((currentProfile) => {
          const updatedPersonal = currentProfile.personal
            ? { ...currentProfile.personal, ['profilePicture']: data.fileName }
            : currentProfile.personal;

          return { ...currentProfile, personal: updatedPersonal };
        });

        setSubmitTrigger(true);

        callTopNavBarFunction({
          funcName: 'renderProfilePicture',
          funcArgs: [`${getPublicProfileUrl()}/${data.fileName}`],
        });
      } else {
        showMessage('Something went wrong!', 'error');
      }
    } catch (e) {
      console.error(e);
    }
  }, [selectedImage, croppedAreaPixels, imageType, showMessage]);

  const validatePersonalFields = useCallback(() => {
    const invalidFields: { [key in keyof IUserPersonal]?: boolean } = {};
    const personal = userProfile.personal;
    if (!personal?.firstName) {
      invalidFields.firstName = true;
    }
    if (!personal?.lastName) {
      invalidFields.lastName = true;
    }
    setInvalidPersonalFields(invalidFields);
    return Object.keys(invalidFields).length === 0;
  }, [userProfile.personal]);

  const handlePersonalChange = useCallback(
    (field: keyof IUserPersonal, value: string | Date | null) => {
      setUserProfile((currentProfile) => {
        const updatedPersonal = currentProfile.personal
          ? { ...currentProfile.personal, [field]: value }
          : currentProfile.personal;

        return { ...currentProfile, personal: updatedPersonal };
      });
    },
    [],
  );

  const handleAboutMeChange = useCallback((value: string) => {
    if (value.length > 0) {
      setUserProfile((currentProfile) => {
        return { ...currentProfile, aboutMe: value };
      });
    } else {
      setUserProfile((currentProfile) => {
        return { ...currentProfile, aboutMe: '' };
      });
    }
  }, []);

  const handleContactChange = useCallback(
    (
      field: keyof IContact,
      changeType: 'value' | 'visible',
      newValue: string | boolean,
    ) => {
      setUserProfile((currentProfile) => {
        if (currentProfile.contacts?.[field]) {
          const updatedField = {
            ...currentProfile.contacts[field],
            [changeType]: newValue,
          };

          const updatedContacts = {
            ...currentProfile.contacts,
            [field]: updatedField,
          };
          return { ...currentProfile, contacts: updatedContacts };
        }
        return currentProfile;
      });
    },
    [],
  );

  const loadMyLearningActivity = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const data = await getMyLearningActivity();
        const activityScores: IActivityScore = {
          stars: data.activity.stars,
          lessonsRead: data.activity.lessonsRead,
          exercisePoints: data.activity.exercisePoints,
          quizPoints: data.activity.quizPoints,
          totalPoints: data.activity.totalPoints,
        };
        setActivityScore(activityScores);
      } catch (err) {
        const activityScores: IActivityScore = {
          stars: 0,
          lessonsRead: 0,
          exercisePoints: 0,
          quizPoints: 0,
          totalPoints: 0,
        };
        setActivityScore(activityScores);
        console.error('Error getting activity data:', err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const loadCertifications = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const data = await getCertificationData();
        setCertifications(data.certifications);
        previousCertificationsRef.current = data.certifications;
      } catch (err) {
        console.error('Error getting certifications data:', err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const loadSpaces = useCallback(async () => {
    if (!isLoadingSpaces) {
      setIsLoadingSpaces(true);

      try {
        const data = await getSpacesData();
        setSpaces(data.spaces);
        previousSpacesRef.current = data.spaces;
      } catch (err) {
        console.error('Error getting spaces data:', err);
      } finally {
        setIsLoadingSpaces(false);
      }
    }
  }, [isLoadingSpaces]);

  const handleSpaceChange = useCallback(
    (index: number, field: keyof ISpaces, value: any) => {
      const updatedSpaces = [...(spaces ?? [])].map((space, spaceIndex) => {
        if (index === spaceIndex) {
          return { ...space, [field]: value };
        }
        return space;
      });
      setSpaces(updatedSpaces);
    },
    [spaces],
  );

  const handleRefreshSpaceThumbnail = useCallback(
    async (spaceId: string) => {
      try {
        setSpaceThumbnailPendingReqs((prePendingReqs) => [
          ...prePendingReqs,
          spaceId,
        ]);
        const data = await postRefreshSpaceThumbnail(spaceId);
        const updatedSpaces = [...(spaces ?? [])].map((space) => {
          if (space.spaceId === spaceId) {
            return { ...space, pictureUrl: data.pictureUrl };
          }
          return space;
        });

        setSpaces(updatedSpaces);
      } catch (err) {
        console.error('Error while refreshing space thumbnail:', err);
      } finally {
        setSpaceThumbnailPendingReqs((prePendingReqs) => [
          ...prePendingReqs.filter((id) => id !== spaceId),
        ]);
      }
    },
    [spaces, spaceThumbnailPendingReqs],
  );

  const loadOpenToWorkStatus = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const data = await getOpenToWork();
        setIsOpenToWork(data.openToWork);
      } catch (err) {
        console.error('Error getting open to work status:', err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const loadUserProfileSettings = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const data = await getProfileSettings();
        const userProfileSettings: IUserProfileSettings = {
          userId: data.userId,
          personal: data.personal,
          contacts: data.contacts,
          experience: data.experience,
          education: data.education,
          activity: data.activity,
          certifications: data.certifications,
          spaces: data.spaces,
          interests: data.interests,
          skills: data.skills,
          aboutMe: data.aboutMe,
        };
        setUserProfileSettings(userProfileSettings);
        previousUserProfileSettingsRef.current = userProfileSettings;
      } catch (err) {
        console.error('Error getting user profile settings:', err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const updateOpenToWorkStatus = useCallback(
    async (openToWork: boolean) => {
      if (!isLoadingOpenToWork) {
        setIsLoadingOpenToWork(true);

        try {
          const data = await setOpenToWork(openToWork);
          setIsOpenToWork(data.openToWork);
        } catch (err) {
          showMessage('An unexpected error occurred', 'error');
        } finally {
          setIsLoadingOpenToWork(false);
        }
      }
    },
    [isLoadingOpenToWork, showMessage],
  );

  // check if resume is getting parsed or not
  const checkResumeParserState = useCallback(async () => {
    // Clear any existing polling process
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    let additionalChecks = 5;
    let intervalDuration = 5000; // 5 seconds in milliseconds
    let totalElapsedTime = 0;
    const maxTimeLimit = 300000; // 5 minutes in milliseconds
    let toastId: number | string | undefined;

    const performCheck = async () => {
      const data = await getResumeParserState();

      if (data.pending === false) {
        if (additionalChecks > 0 && totalElapsedTime < maxTimeLimit) {
          // Perform additional checks with increasing intervals
          additionalChecks--;
          intervalDuration *= 2;
          totalElapsedTime += intervalDuration;

          if (toastId && isCVUploadingRef.current) {
            toast.update(toastId, {
              description:
                'Your CV has been successfully processed and your profile is now updated.',
              status: 'success',
              duration: 5000,
            });
            setIsCVUploading(false);
            isCVUploadingRef.current = false;

            fetchTokenBalance();
            // now trigger re-render
            loadData();
          }

          timeoutRef.current = setTimeout(performCheck, intervalDuration);
        } else if (totalElapsedTime >= maxTimeLimit) {
          // Time limit exceeded notification
          if (toastId) {
            toast.update(toastId, {
              description:
                'The process has taken longer than expected. Please try again later.',
              status: 'warning',
              duration: 5000,
              isClosable: true,
            });
          }
        }
      } else {
        setIsCVUploading(true);
        isCVUploadingRef.current = true;

        // Only create the toast if it hasn't been created yet
        if (toastId === undefined) {
          toastId = toast({
            description: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Spinner size="sm" marginRight="10px" />
                Processing your CV. Please wait, your profile will be updated
                shortly.
              </div>
            ),
            status: 'info',
            duration: null,
            isClosable: true,
          });
        }

        if (totalElapsedTime < maxTimeLimit) {
          totalElapsedTime += intervalDuration;
          timeoutRef.current = setTimeout(performCheck, intervalDuration);
        } else {
          toast.update(toastId!, {
            description:
              'The process has taken longer than expected. Please try again later.',
            status: 'warning',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    // Start the first check
    timeoutRef.current = setTimeout(performCheck, intervalDuration);

    // Cleanup on unmount or before new timeout is set
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [setIsCVUploading, toast, isCVUploadingRef, loadData, fetchTokenBalance]);

  const handleUploadCV = useCallback(
    async (file: File) => {
      if (!isCVUploading) {
        setIsCVUploading(true);
        isCVUploadingRef.current = true;
        try {
          const data = await getPresignedUrlForUpload(file);
          const success = await uploadFileToS3(data, file);
          if (success) {
            showMessage('File uploaded successfully', 'success');

            // now start polling
            await checkResumeParserState();
          } else {
            showMessage('Error uploading the file', 'error');
          }
        } catch (error) {
          if (error instanceof Error) {
            showMessage(`Error: ${error.message}`, 'error');
          } else {
            showMessage('An unexpected error occurred', 'error');
          }
        } finally {
          setIsCVUploading(false);
          isCVUploadingRef.current = false;
        }
      }
    },
    [showMessage, isCVUploading, checkResumeParserState],
  );

  const onFileSelected = useCallback(
    (file: File) => {
      if (file.type !== 'application/pdf') {
        showMessage('Only PDF files are allowed', 'error');
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        showMessage('File size should be less than 2MB', 'error');
        return;
      }

      // Rename the file
      const renamedFile = new File([file], userID + '.pdf', {
        type: file.type,
        lastModified: file.lastModified,
      });
      handleUploadCV(renamedFile);
    },
    [handleUploadCV, showMessage, userID],
  );

  const handleDownloadCV = useCallback(async () => {
    if (!isDownloading) {
      try {
        setIsDownloading(true);
        const url = await getPresignedUrlForDownload(userID + '.pdf');
        if (url) {
          // Open the URL in a new tab
          window.open(url, '_blank');
        } else {
          showMessage('Unable to get the download URL', 'error');
        }
      } catch (error) {
        showMessage(
          'An unexpected error occurred while downloading the CV',
          'error',
        );
      } finally {
        setIsDownloading(false);
      }
    }
  }, [showMessage, userID, isDownloading]);

  const onCountryChange = useCallback(async (countryCode: string) => {
    const countryData = COUNTRY_DATA.find(
      (country) => country.value === countryCode,
    );
    if (countryData) {
      const continent = countryData.extra.continent;
      const countryName = countryData.title;

      const finalCountryData = {
        [countryCode]: {
          regions: {
            [continent]: {
              name: getContinentFullName(continent),
            },
          },
          name: countryName,
        },
      };

      setUserProfile((currentProfile) => {
        const updatedPersonal = currentProfile.personal
          ? { ...currentProfile.personal, ['country']: finalCountryData }
          : currentProfile.personal;
        return { ...currentProfile, personal: updatedPersonal };
      });
    }
  }, []);

  const loadCompanies = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const data = await getCompanies();
        const allCompanies = Array.isArray(data.companies)
          ? data.companies.map((company: ICompany) => company || {})
          : [];
        setCompanies(allCompanies);
        previousCompaniesRef.current = allCompanies;
      } catch (err) {
        console.error('Error getting companies:', err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const validateCompanies = useCallback(() => {
    const requiredFields: CompanyField[] = [
      'name',
      'position',
      'phone',
      'email',
      'contactName',
      'country',
    ];
    let allValid = true;
    const newInvalidFields: Record<
      number,
      { [key in CompanyField]?: boolean }
    > = {};

    if (companies) {
      companies.forEach((company, index) => {
        requiredFields.forEach((field) => {
          if (field === 'country') {
            const location = company[field];
            if (!location || Object.keys(location).length === 0) {
              allValid = false;
              if (!newInvalidFields[index]) {
                newInvalidFields[index] = {};
              }
              newInvalidFields[index][field] = true;
            }
          } else {
            if (company[field] === undefined || company[field]?.trim() === '') {
              allValid = false;
              if (!newInvalidFields[index]) {
                newInvalidFields[index] = {};
              }
              newInvalidFields[index][field] = true;
            } else {
              if (field === 'email') {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(company[field]!)) {
                  allValid = false;
                  if (!newInvalidFields[index]) {
                    newInvalidFields[index] = {};
                  }
                  newInvalidFields[index][field] = true;
                }
              }
            }
          }
        });
      });
    }

    setInvalidCompanyFields(newInvalidFields);
    return allValid;
  }, [companies]);

  const addCompany = useCallback(() => {
    if (validateCompanies()) {
      const newCompanies = [
        ...(companies ?? []),
        { name: '', position: '', phone: '', email: '', logo: '' },
      ];
      setCompanies(newCompanies);
    }
  }, [companies, validateCompanies]);

  const deleteCompany = useCallback(
    (index: number) => {
      if (
        confirm('Are you sure you want to delete this company?') &&
        companies
      ) {
        const updatedCompanies = companies.filter((_, i) => i !== index);
        setCompanies(updatedCompanies);
      }
    },
    [companies],
  );

  const updateCompany = useCallback(
    (index: number, field: string, value: string) => {
      if (field === 'country') {
        const countryData = COUNTRY_DATA.find(
          (country) => country.value === value,
        );
        if (countryData) {
          const continent = countryData.extra.continent;
          const countryName = countryData.title;

          const finalCountryData = {
            [value]: {
              regions: {
                [continent]: {
                  name: getContinentFullName(continent),
                },
              },
              name: countryName,
            },
          };

          const updatedClients = companies?.map((company, companyIndex) => {
            if (index === companyIndex) {
              return { ...company, [field]: finalCountryData };
            }
            return company;
          });
          setCompanies(updatedClients);
        }
      } else {
        const updatedClients = companies?.map((company, companyIndex) => {
          if (index === companyIndex) {
            return { ...company, [field]: value };
          }
          return company;
        });
        setCompanies(updatedClients);
      }
    },
    [companies],
  );

  const handleLogoUpload = useCallback(
    async (file: File, index: number) => {
      if (!isUploading) {
        setIsUploading(true);
        try {
          const data = await getPresignedUrlForCompanyLogo(
            file.name,
            file.type,
            'company-logos',
          );
          const success = await uploadFileToS3(data.url, file);
          if (success) {
            const updatedCompanies = [...(companiesRef.current ?? [])];
            if (updatedCompanies[index]) {
              const updatedCompanies = companiesRef.current.map(
                (company, idx) =>
                  idx === index ? { ...company, logo: data.fileName } : company,
              );
              setCompanies(updatedCompanies);
            }
          } else {
            showMessage('Error uploading the logo', 'error');
          }
        } catch (error) {
          if (error instanceof Error) {
            showMessage(`Error: ${error.message}`, 'error');
            console.error('error', `${error.message}`);
          } else {
            showMessage('An unexpected error occurred', 'error');
          }
        } finally {
          setIsUploading(false);
        }
      }
    },
    [showMessage, isUploading],
  );

  useEffect(() => {
    companiesRef.current = companies;
  }, [companies]);

  const onCompanyLogoSelected = useCallback(
    (file: File, index: number) => {
      if (file.type.startsWith('image/')) {
        // Validate file size
        if (file.size > 2 * 1024 * 1024) {
          showMessage('File size should be less than 2MB', 'error');
          return;
        }

        // Rename the file
        const fileName = `${uuidv4()}.${file.name.split('.').pop()}`;
        const contentType = file.type;
        const renamedFile = new File([file], fileName, {
          type: contentType,
          lastModified: file.lastModified,
        });
        handleLogoUpload(renamedFile, index);
      } else {
        showMessage('Only image files are allowed', 'error');
      }
    },
    [showMessage, handleLogoUpload],
  );

  const handleLogoDelete = useCallback(
    (index: number) => {
      if (confirm('Are you sure you want to delete this logo?') && companies) {
        const updatedCompanies = companies.map((company, companyIndex) =>
          companyIndex === index ? { ...company, logo: '' } : company,
        );
        setCompanies(updatedCompanies);
      }
    },
    [companies],
  );

  const loadInterests = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const data = await getInterests();
        setInterests(data.interests);
        previousInterestsRef.current = data.interests;
      } catch (err) {
        console.error('Error getting interests:', err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const loadAvailableInterests = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const data = await getAvailableInterests();
        if (data.tags) {
          const availableTags = data.tags.map((tag: ITagType) => ({
            value: tag.tagId,
            label: tag.tagName,
          }));
          setAvailableInterests(availableTags);
        }
      } catch (err) {
        console.error('Error getting available interests:', err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const handleInterestChange = useCallback(
    (selectedOptions: IChakraReactSelect[] | null) => {
      if (selectedOptions) {
        // Map the selectedOptions to the new interests format
        const newInterests = selectedOptions.map((option) => ({
          tagId: option.value,
          tagName: option.label,
        }));

        // Set the interests state to exactly match the updated selection
        setInterests(newInterests);
      } else {
        // If no options are selected, set the interests to an empty array
        setInterests([]);
      }
    },
    [],
  );

  const loadSkills = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const data = await getSkills();
        setMySkills(data.skills);
        previousMySkillsRef.current = data.skills;
      } catch (err) {
        console.error('Error getting skills:', err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const handleSkillsChange = useCallback(
    (index: number, value: boolean) => {
      if (mySkills) {
        const updatedSkills = mySkills.map((skill, i) =>
          i === index ? { ...skill, visible: value } : skill,
        );
        setMySkills(updatedSkills);
      }
    },
    [mySkills],
  );

  const validateEducation = useCallback(() => {
    const requiredFields: (keyof IEducation)[] = ['degree', 'institution'];
    let allValid = true;
    const newInvalidFields: Record<
      number,
      { [key in keyof IEducation]?: boolean }
    > = {};

    if (userProfile.education) {
      userProfile.education.forEach((edu, index) => {
        requiredFields.forEach((field) => {
          if (
            typeof edu[field] === 'string' &&
            (edu[field] === undefined || (edu[field] as string).trim() === '')
          ) {
            allValid = false;
            if (!newInvalidFields[index]) {
              newInvalidFields[index] = {};
            }
            newInvalidFields[index][field] = true;
          }
        });
      });
    }

    setInvalidEducationFields(newInvalidFields);
    return allValid;
  }, [userProfile.education]);

  const handleEducationChange = useCallback(
    (index: number, field: keyof IEducation, value: string | Date | null) => {
      setUserProfile((currentProfile) => {
        if (currentProfile.education) {
          const updatedEducation = currentProfile.education.map((edu, i) =>
            i === index ? { ...edu, [field]: value } : edu,
          );
          return { ...currentProfile, education: updatedEducation };
        }
        return currentProfile;
      });
    },
    [],
  );

  const deleteEducationEntry = useCallback((index: number) => {
    if (confirm('Are you sure you want to delete this education entry?')) {
      setUserProfile((currentProfile) => {
        if (currentProfile.education) {
          const updatedEducation = currentProfile.education.filter(
            (_, i) => i !== index,
          );
          return { ...currentProfile, education: updatedEducation };
        } else {
          return currentProfile;
        }
      });
    }
  }, []);

  const addEducationEntry = useCallback(() => {
    if (validateEducation()) {
      setUserProfile((currentProfile) => {
        const newEducationEntry = {
          degree: '',
          institution: '',
          place: '',
          startDate: null,
          endDate: null,
          description: '',
        };
        const updatedEducation = currentProfile.education
          ? [...currentProfile.education, newEducationEntry]
          : [newEducationEntry];
        return { ...currentProfile, education: updatedEducation };
      });
    }
  }, [validateEducation]);

  const validateExperience = useCallback(() => {
    const requiredFields: (keyof IExperience)[] = [
      'company',
      'position',
      'dutiesString',
    ];
    let allValid = true;
    const newInvalidFields: Record<
      number,
      { [key in keyof IExperience]?: boolean }
    > = {};

    if (userProfile.experience) {
      userProfile.experience.forEach((exp, index) => {
        requiredFields.forEach((field) => {
          if (
            typeof exp[field] === 'string' &&
            (exp[field] === undefined || (exp[field] as string).trim() === '')
          ) {
            allValid = false;
            if (!newInvalidFields[index]) {
              newInvalidFields[index] = {};
            }
            newInvalidFields[index][field] = true;
          }
        });
      });
    }

    setInvalidExperienceFields(newInvalidFields);
    return allValid;
  }, [userProfile.experience]);

  const addExperienceEntry = useCallback(() => {
    if (validateExperience()) {
      setUserProfile((currentProfile) => {
        const newExperienceEntry = {
          position: '',
          company: '',
          location: '',
          startDate: null,
          endDate: null,
          dutiesString: '',
        };
        const updatedExperience = currentProfile.experience
          ? [...currentProfile.experience, newExperienceEntry]
          : [newExperienceEntry];
        return { ...currentProfile, experience: updatedExperience };
      });
    }
  }, [validateExperience]);

  const deleteExperienceEntry = useCallback((index: number) => {
    if (confirm('Are you sure you want to delete this experience entry?')) {
      setUserProfile((currentProfile) => {
        if (currentProfile.experience) {
          const updatedExperience = currentProfile.experience.filter(
            (_, i) => i !== index,
          );
          return { ...currentProfile, experience: updatedExperience };
        } else {
          return currentProfile;
        }
      });
    }
  }, []);

  const handleExperienceChange = useCallback(
    (index: number, field: keyof IExperience, value: string | Date | null) => {
      setUserProfile((currentProfile) => {
        if (currentProfile.experience) {
          const updatedExperience = currentProfile.experience.map((exp, i) =>
            i === index ? { ...exp, [field]: value } : exp,
          );
          return { ...currentProfile, experience: updatedExperience };
        }
        return currentProfile;
      });
    },
    [],
  );

  const handleCertificationChange = useCallback(
    (index: number, value: boolean) => {
      if (certifications) {
        const updatedCertifications = certifications.map((cert, i) =>
          i === index ? { ...cert, visible: value } : cert,
        );
        setCertifications(updatedCertifications);
      }
    },
    [certifications],
  );

  const checkNicknameAvailability = async (nickname: string) => {
    const response = await checkNickAvailability(nickname);
    setIsNicknameAvailable((response as any).available);
  };

  const loadPublicProfile = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const data = await getPublicProfile();
        if (data) {
          setNickname(data.id);
          setPublicProfileVisibility(data.published);

          //set previous values
          previousNicknameRef.current = data.id;
          previousPublicProfileVisibilityRef.current = data.published;
        }
      } catch (err) {
        console.error('Error getting public profile data:', err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const handleNicknameChange = useCallback(
    (nickname: string) => {
      if (nickname === '' && publicProfileVisibility) {
        showMessage(
          'Nickname is required to make your profile public',
          'warning',
        );
      }

      setNickname(nickname);
      //check only if char is greater then 1
      if (nickname.length > 1) {
        checkNicknameAvailability(nickname);
      }
    },
    [publicProfileVisibility, showMessage],
  );

  const handlePublicProfileVisibility = useCallback((value: boolean) => {
    setPublicProfileVisibility(value as any);
  }, []);

  // below will be used to update the user profile settings
  const handleProfileSettingsChange = useCallback(
    async (
      newSectionKey: keyof IUserProfileSettings,
      newValue: visibilityOptions,
    ) => {
      const updatedSettings = {
        ...userProfileSettings,
        [newSectionKey]: newValue,
      };
      setUserProfileSettings(updatedSettings);
    },
    [userProfileSettings],
  );

  const findInvalidUserProfileSettings = (
    settings: IUserProfileSettings,
  ): string[] => {
    return Object.entries(settings).reduce((invalidKeys, [key, value]) => {
      if (value === '' || value === null || value === undefined) {
        invalidKeys.push(key);
      }
      return invalidKeys;
    }, [] as string[]);
  };

  const handleSaveClick = useCallback(() => {
    setConfirmProfileSubmissionModalOpen(true);
  }, []);

  // below will be used to update the user profile data
  const handleProfileSubmit = useCallback(async () => {
    setConfirmProfileSubmissionModalOpen(false);
    setIsProfileSaving(true);
    setDisableSaveProfileButton(true);

    // to Save IUserProfile Data
    const payload: Record<string, any> = {};
    const propertiesToCheck: (keyof IUserProfile)[] = [
      'personal',
      'contacts',
      'experience',
      'education',
      'aboutMe',
    ];

    // Check each property and add to payload if changed
    propertiesToCheck.forEach((property) => {
      const current = userProfile[property];
      const previous = previousUserProfileRef.current[property];

      if (!_.isEqual(previous, current)) {
        if (property === 'experience') {
          payload[property] = convertExperience(current as IExperience[]);
        } else if (property === 'education') {
          payload[property] = convertEducation(current as IEducation[]);
        } else if (property === 'personal') {
          payload[property] = convertPersonal(current as IUserPersonal);
        } else {
          payload[property] = current;
        }
      }
    });

    // check if payload has experience property and if so validate it
    if (payload.experience) {
      const isValid = validateExperience();
      if (!isValid) {
        setAccordionIndex([5]); //Open experience accordion
        showMessage(
          'Please fill all the required fields for experience section.',
          'error',
        );
        setIsProfileSaving(false);
        setDisableSaveProfileButton(false);
        return;
      }
    }

    // check if payload has education property and if so validate it
    if (payload.education) {
      const isValid = validateEducation();
      if (!isValid) {
        setAccordionIndex([6]); //Open education accordion
        showMessage(
          'Please fill all the required fields for education section',
          'error',
        );
        setIsProfileSaving(false);
        setDisableSaveProfileButton(false);
        return;
      }
    }

    // validate personal fields
    if (payload.personal) {
      const isValid = validatePersonalFields();
      if (!isValid) {
        setAccordionIndex([1]); //Open personal accordion
        showMessage(
          'Please fill all the required fields for personal section',
          'error',
        );
        setIsProfileSaving(false);
        setDisableSaveProfileButton(false);
        return;
      }
    }

    // check if spaces has changed, as we have seperate API to fetch spaces
    const currentSpaces = spaces;
    const previousSpaces = previousSpacesRef.current;
    if (!_.isEqual(previousSpaces, currentSpaces)) {
      payload.spaces = currentSpaces;
    }

    // check if companies has changed, as we have seperate API to fetch companies
    const currentCompanies = companies;
    const previousCompanies = previousCompaniesRef.current;
    if (!_.isEqual(previousCompanies, currentCompanies)) {
      if (validateCompanies()) {
        payload.companies = currentCompanies;
      } else {
        setAccordionIndex([3]); //Open companies accordion
        showMessage(
          'Please fill all the required fields for company section',
          'error',
        );
        setIsProfileSaving(false);
        setDisableSaveProfileButton(false);
        return;
      }
    }

    // check if interests has changed, as we have seperate API to fetch interests
    const currentInterests = interests;
    const previousInterests = previousInterestsRef.current;
    if (!_.isEqual(previousInterests, currentInterests)) {
      payload.interests = currentInterests;
    }

    // check if certifications has changed, as we have seperate API to fetch certifications
    const currentCertifications = certifications;
    const previousCertifications = previousCertificationsRef.current;
    if (!_.isEqual(previousCertifications, currentCertifications)) {
      payload.certifications = currentCertifications;
    }

    // check if skills has changed, as we have seperate API to fetch skills
    const currentSkills = mySkills;
    const previousSkills = previousMySkillsRef.current;
    if (!_.isEqual(previousSkills, currentSkills)) {
      payload.skills = currentSkills;
    }

    // check profanity in nickname
    if (isNicknameAvailable) {
      if (nickname === '') {
        showMessage(
          'Nickname is required to make your profile public',
          'warning',
        );
        setIsProfileSaving(false);
        setDisableSaveProfileButton(false);
        return;
      }
    }

    const anyErrors = [];
    // check if userProfileSettings has changed, as we have seperate API to fetch userProfileSettings & save
    let anyChangesDetected = false;
    const currentUserProfileSettings = userProfileSettings;
    const previousUserProfileSettings = previousUserProfileSettingsRef.current;
    if (!_.isEqual(previousUserProfileSettings, currentUserProfileSettings)) {
      try {
        const invalidSettings =
          findInvalidUserProfileSettings(userProfileSettings);
        if (invalidSettings.length > 0) {
          showMessage(
            'Please select visibility setting for all sections: ' +
              invalidSettings,
            'error',
          );
          setIsProfileSaving(false);
          setDisableSaveProfileButton(false);
          return;
        } else {
          // Save Profile visibility settings
          await postProfileSettings(userProfileSettings);
          anyChangesDetected = true;
          previousUserProfileSettingsRef.current = userProfileSettings;
        }
      } catch (error: any) {
        let errorMessage = '';
        if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage =
            error.message || 'An error occurred while creating public profile.';
        }

        errorMessage =
          errorMessage.charAt(0).toUpperCase() + errorMessage.substring(1);
        anyErrors.push(errorMessage);
        console.error('Error while setting visibility setting:', errorMessage);
      }
    }

    // check if public profile setting is changed if so save it
    if (isNicknameAvailable) {
      const currentNickname = nickname;
      const previousNickname = previousNicknameRef.current;

      if (currentNickname !== previousNickname) {
        anyChangesDetected = true;
        try {
          await createPublicProfile(nickname, publicProfileVisibility);
          previousNicknameRef.current = nickname;
        } catch (error: any) {
          let errorMessage = '';
          if (error.response?.data?.message) {
            errorMessage = error.response?.data?.message;
          } else {
            errorMessage =
              error.message ||
              'An error occurred while creating public profile.';
          }

          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.substring(1);
          anyErrors.push(errorMessage);
          console.error('error while cretaing public profile', errorMessage);
        }
      }
    } else if (
      publicProfileVisibility !== previousPublicProfileVisibilityRef.current
    ) {
      anyChangesDetected = true;
      try {
        await changePublicProfileVisibility(publicProfileVisibility);
        previousPublicProfileVisibilityRef.current = publicProfileVisibility;
      } catch (error: any) {
        let errorMessage = '';
        if (error.response?.data?.message) {
          errorMessage = error.response?.data?.message;
        } else {
          errorMessage =
            error.message ||
            'An error occurred while updating public profile visibility';
        }

        errorMessage =
          errorMessage.charAt(0).toUpperCase() + errorMessage.substring(1);
        anyErrors.push(errorMessage);
        console.error('Error updating settings:', errorMessage);
      }
    }

    if (Object.keys(payload).length > 0) {
      try {
        anyChangesDetected = true;
        // Make the API call with the changed properties
        const data = await postProfileData(payload as Partial<IUserProfile>);

        const experience = mapExperience(data.experience);
        const education = mapEducation(data.education);
        const personal = mapPersonal(data.personal);
        const userProfile: IUserProfile = {
          userId: data.userId,
          personal: personal,
          contacts: data.contacts,
          experience: experience,
          education: education,
          aboutMe: data.aboutMe,
        };
        setUserProfile(userProfile);
        previousUserProfileRef.current = userProfile;

        setCompanies(data.companies);
        previousCompaniesRef.current = data.companies;

        setInterests(data.interests);
        previousInterestsRef.current = data.interests;

        setCertifications(data.certifications);
        previousCertificationsRef.current = data.certifications;

        setSpaces(data.spaces);
        previousSpacesRef.current = data.spaces;

        loadDailyQuata();

        // as we have seperate API to fetch skills
        await loadSkills();
      } catch (error: any) {
        let errorMessage = '';
        if (error.response?.data?.message) {
          errorMessage = error.response?.data?.message;
        } else {
          errorMessage =
            error.message ||
            'An error occurred while saving profile information';
        }

        errorMessage =
          errorMessage.charAt(0).toUpperCase() + errorMessage.substring(1);
        anyErrors.push(errorMessage);
      }
    }

    if (anyErrors.length > 0) {
      const errMessageHtml = (
        <div style={{ whiteSpace: 'pre-line' }}>
          {anyErrors.map((error, index) => (
            <p key={index}>
              <strong>{error}</strong>
            </p>
          ))}
        </div>
      );
      showMessage(errMessageHtml, 'error');
    } else {
      if (anyChangesDetected) {
        setHasUnsavedChanges(false);
        showMessage('Profile updated successfully', 'success');
      } else {
        showMessage('Everything\'s up-to-date, nothing new to store!', 'info');
      }
    }

    setDisableSaveProfileButton(false);
    setIsProfileSaving(false);
  }, [
    loadSkills,
    validateEducation,
    validateExperience,
    userProfile,
    validateCompanies,
    validatePersonalFields,
    spaces,
    showMessage,
    companies,
    interests,
    certifications,
    userProfileSettings,
    mySkills,
    nickname,
    publicProfileVisibility,
    isNicknameAvailable,
    loadDailyQuata,
  ]);

  useEffect(() => {
    if (submitTrigger) {
      const submitForm = async () => {
        setSubmitTrigger(false);
        await handleProfileSubmit();
        setIsProfileImageUploading(false);
      };
      submitForm();
    }
  }, [submitTrigger, handleProfileSubmit]);

  useEffect(() => {
    loadData();
    loadMyLearningActivity();
    loadCertifications();
    loadSpaces();
    loadUserProfileSettings();
    loadInterests();
    loadAvailableInterests();
    loadCompanies();
    loadSkills();
    loadPublicProfile();
    checkResumeParserState();
    loadDailyQuata();
    fetchTokenBalance();

    const countries = COUNTRY_DATA.map((country) => ({
      value: country.value,
      label: country.title,
    }));
    setCountryData(countries);
  }, []);

  useEffect(() => {
    if (selectedFile) {
      handleUploadCV(selectedFile);
    }
  }, [handleUploadCV, selectedFile]);

  const value = useMemo(
    () => ({
      activityScore,
      isLoadingSpaces,
      spaces,
      certifications,
      isLoading,
      isOpenToWork,
      updateOpenToWorkStatus,
      isLoadingOpenToWork,
      error: {
        hasError: error.hasError,
        message: error.message,
        callback: error.callback,
      },
      resetError,
      loadData,
      loadDailyQuata,
      loadMyLearningActivity,
      loadCertifications,
      loadSpaces,
      showMessage,
      handleUploadCV,
      handleLogoUpload,
      selectedFile,
      onFileSelected,
      onCompanyLogoSelected,
      handleLogoDelete,
      handleDownloadCV,
      isUploading,
      isCVUploading,
      isDownloading,
      chartData,
      userProfile,
      userProfileSettings,
      loadUserProfileSettings,
      countryData,
      onCountryChange,
      companies,
      loadCompanies,
      addCompany,
      deleteCompany,
      updateCompany,
      invalidCompanyFields,
      invalidExperienceFields,
      invalidEducationFields,
      invalidPersonalFields,
      loadInterests,
      interests,
      loadAvailableInterests,
      availableInterests,
      handleInterestChange,
      handleEducationChange,
      deleteEducationEntry,
      addEducationEntry,
      addExperienceEntry,
      deleteExperienceEntry,
      handleExperienceChange,
      handleProfileSettingsChange,
      disableSaveProfileButton,
      handleProfileSubmit,
      handleSaveClick,
      confirmProfileSubmissionModalOpen,
      setConfirmProfileSubmissionModalOpen,
      handlePersonalChange,
      handleContactChange,
      handleSpaceChange,
      spaceThumbnailPendingReqs,
      handleRefreshSpaceThumbnail,
      handleCertificationChange,
      mySkills,
      loadSkills,
      handleSkillsChange,
      nickname,
      isNicknameAvailable,
      handleNicknameChange,
      publicProfileVisibility,
      handlePublicProfileVisibility,
      loadPublicProfile,
      crop,
      setCrop,
      zoom,
      setZoom,
      croppedAreaPixels,
      setCroppedAreaPixels,
      isModalOpen,
      setIsModalOpen,
      selectedImage,
      setSelectedImage,
      croppedImage,
      setCroppedImage,
      imageType,
      setImageType,
      handleProfileImageChange,
      onCropComplete,
      handleEditConfirm,
      isProfileImageUploading,
      handleAboutMeChange,
      isProfileSaving,
      accordionIndex,
      setAccordionIndex,
      dailyQuataTotal,
      dailyQuataRemain,
      wallet,
      fetchTokenBalance,
    }),
    [
      activityScore,
      isLoadingSpaces,
      spaces,
      certifications,
      isLoading,
      isOpenToWork,
      updateOpenToWorkStatus,
      isLoadingOpenToWork,
      error.hasError,
      error.message,
      error.callback,
      resetError,
      loadData,
      loadDailyQuata,
      loadMyLearningActivity,
      loadCertifications,
      loadSpaces,
      showMessage,
      handleUploadCV,
      handleLogoUpload,
      selectedFile,
      onFileSelected,
      onCompanyLogoSelected,
      handleLogoDelete,
      handleDownloadCV,
      isUploading,
      isCVUploading,
      isDownloading,
      chartData,
      userProfile,
      userProfileSettings,
      loadUserProfileSettings,
      countryData,
      onCountryChange,
      companies,
      loadCompanies,
      addCompany,
      deleteCompany,
      updateCompany,
      invalidCompanyFields,
      invalidExperienceFields,
      invalidEducationFields,
      invalidPersonalFields,
      loadInterests,
      interests,
      loadAvailableInterests,
      availableInterests,
      handleInterestChange,
      handleEducationChange,
      deleteEducationEntry,
      addEducationEntry,
      addExperienceEntry,
      deleteExperienceEntry,
      handleExperienceChange,
      handleProfileSettingsChange,
      disableSaveProfileButton,
      handleProfileSubmit,
      handleSaveClick,
      confirmProfileSubmissionModalOpen,
      setConfirmProfileSubmissionModalOpen,
      handlePersonalChange,
      handleContactChange,
      handleSpaceChange,
      spaceThumbnailPendingReqs,
      handleRefreshSpaceThumbnail,
      handleCertificationChange,
      mySkills,
      loadSkills,
      handleSkillsChange,
      nickname,
      isNicknameAvailable,
      handleNicknameChange,
      publicProfileVisibility,
      handlePublicProfileVisibility,
      loadPublicProfile,
      crop,
      setCrop,
      zoom,
      setZoom,
      croppedAreaPixels,
      setCroppedAreaPixels,
      isModalOpen,
      setIsModalOpen,
      selectedImage,
      setSelectedImage,
      croppedImage,
      setCroppedImage,
      imageType,
      setImageType,
      handleProfileImageChange,
      onCropComplete,
      handleEditConfirm,
      isProfileImageUploading,
      handleAboutMeChange,
      isProfileSaving,
      accordionIndex,
      setAccordionIndex,
      dailyQuataTotal,
      dailyQuataRemain,
      wallet,
      fetchTokenBalance,
    ],
  );

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
}

export const useProfile = () => useContext(ProfileContext);
