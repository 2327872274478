export const ExpireAfterDays = 30;

/**
 * Returns the string representation of a month
 * @param month The month as a number
 * @returns
 */
export const getMonth = (month: number): string => {
  let myMonth = 'January';

  switch (month) {
    case 1:
      myMonth = 'February';
      break;
    case 2:
      myMonth = 'March';
      break;
    case 3:
      myMonth = 'April';
      break;
    case 4:
      myMonth = 'May';
      break;
    case 5:
      myMonth = 'June';
      break;
    case 6:
      myMonth = 'July';
      break;
    case 7:
      myMonth = 'August';
      break;
    case 8:
      myMonth = 'September';
      break;
    case 9:
      myMonth = 'October';
      break;
    case 10:
      myMonth = 'November';
      break;
    case 11:
      myMonth = 'December';
      break;
    default:
  }

  return myMonth;
};

/**
 * Return the number of days from todays date until the date given
 * @param date A number or a Date object
 * @returns
 */
export const getDaysUntilDate = (date: number | Date): number => {
  let argDate: number;

  if (typeof date === 'number') {
    const tempDate = new Date(date);
    // Reset hours, minutes, seconds, and milliseconds
    tempDate.setHours(0, 0, 0, 0);
    argDate = tempDate.getTime();
  } else {
    // Reset hours, minutes, seconds, and milliseconds
    (date as Date).setHours(0, 0, 0, 0);
    argDate = (date as Date).getTime();
  }

  // Get today's date
  const today: Date = new Date();
  // Reset hours, minutes, seconds, and milliseconds for today
  today.setHours(0, 0, 0, 0);
  // Calculate the difference in milliseconds
  const differenceInMilliseconds = argDate - today.getTime();
  // Convert the difference to days
  const days = differenceInMilliseconds / 86400_000;

  // Return the number of days (rounded to remove possible floating point inaccuracies)
  return Math.round(days);
};

export const getDateFromEpoch = (epochTime: number) => {
  const givenDate = new Date(epochTime);
  return `${givenDate.getDate()} ${getMonth(givenDate.getMonth())} ${givenDate.getFullYear()}`;
};

export const daysLeftUntilExpired = (isoDate: string) => {
  const date = new Date(isoDate);
  date.setDate(date.getDate() + ExpireAfterDays);
  return getDaysUntilDate(date);
};

export function convertUTStoISO(uts: number): string {
  const date = new Date(uts * 1000);
  return date.toISOString();
}
