import {
  NotificationDismissType,
  NotificationType,
  NotificationUpdateType,
} from './TypesInterfaces';

/**
 * Update an existing notification
 * @param notificationList Existing notification list
 * @param notification Notification data to be updated
 * @returns A new list with the notification updated
 */
export const updateNotification = (
  notificationList: NotificationType[],
  notification: NotificationUpdateType,
): NotificationType | undefined => {
  const myNotif = notificationList.find(
    (notif: NotificationType) => notif.id === notification.id,
  );
  if (myNotif) {
    return { ...myNotif, ...notification };
  }
  return undefined;
};

/**
 * Dismiss an existing notification
 * @param notificationList Existing notification list
 * @param notification Notification to be dismissed
 * @returns A new list with the notification dismissed
 */
export const dismissNotification = (
  notificationList: NotificationType[],
  notification: NotificationDismissType,
): NotificationType | undefined => {
  const myNotif = notificationList.find(
    (notif: NotificationType) => notif.id === notification.id,
  );
  if (myNotif) {
    myNotif.dismissed = true;
    return myNotif;
  }
  return undefined;
};

/**
 * Get a list of active notifications
 * @param notificationList List of notifications
 * @returns
 */
export const getActiveNotifications = (
  notificationList: NotificationType[],
): NotificationType[] =>
  notificationList.filter((notif: NotificationType) => !notif.dismissed);

/**
 * Get a list of upload notifications
 * @param notificationList List of notifications
 * @returns
 */
export const getUploadNotifications = (
  notificationList: NotificationType[],
): NotificationType[] =>
  notificationList.filter(
    (notif: NotificationType) => notif.showProgressBar && !notif.dismissed,
  );
