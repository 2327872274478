/* eslint-disable indent, camelcase */
import React, { useEffect, useState } from 'react';
// import { onComponentDidMount } from '../../Util/ComponentUtil';
import { authUtil as AuthUtil } from '../../Util/AuthUtil';
import './ProfileSettings.module.scss';
import SetName from './Pane/SetName';
import { logging } from '../../Util/LoggingUtil';
import LoadingBox from '../LoadingBox/LoadingBox';
import { SpinnerTypes } from '../Spinner/Spinner';
import ChangeEmail from './Pane/ChangeEmail';
import { fetchProfile } from '../../Util/InternalProjects/ProfileUtil';
import { UserDataType } from '../../Util/InterfaceAndTypeUtil';
import EmailSubscriptions from './Pane/EmailSubscriptions';

export interface ProfileSettingsProps {
  userIsLoggedIn: boolean,
  doneLoading?: () => void,
}

function ProfileSettings({
  userIsLoggedIn,
  doneLoading,
}: ProfileSettingsProps) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null); // cognito user data
  const [userData, setUserData] = useState<UserDataType | null>(null); // user db record (public) data

  const loadUser = async () => {
    try {
      // const userIsLoggedIn = await AuthUtil.userIsLoggedIn();

      if (!userIsLoggedIn) {
        // setLoading(false);

        return;
      }

      const _user = await AuthUtil.getCurrentUser(); // cognito user meta

      logging.logDebug('ProfileSettings -> loadUser -> _user: ', _user);

      setUser(_user);

      const userDataFetchRes = await fetchProfile<UserDataType>({
        method: 'GET',
        url: '/user',
      });

      logging.logDebugAlt('ProfileSettings -> loadUser -> userDataFetchRes: ', userDataFetchRes);

      if (
        userDataFetchRes.error.code === '0'
        && userDataFetchRes.data.username !== undefined
      ) {
        setUserData(userDataFetchRes.data);
      }
      // else {
      //     setUserData({
      //         emailConsent: true,
      //     } as UserDataType);
      // }
    } catch (error) {
      const exc = error as any;
      logging.logDebug('ProfileSettings -> loadUser -> exc: ', exc);
    }

    setLoading(false);

    if (typeof doneLoading !== 'undefined') {
      doneLoading();
    }
  };

  /*onComponentDidMount(() => {
      logging.logDebug('ProfileSettings -> componentDidMount');

      loadUser();
  });
  */

  useEffect(() => {
    logging.logDebug('ProfileSettings -> componentDidMount');

    loadUser();
  }, [userIsLoggedIn]);


  useEffect(() => {
    logging.logDebug('ProfileSettings -> useEffect -> user: ', user);
  }, [user]);

  return (
    <div className='ProfileSettings'>
      <div className='-iwrp'>
        {loading &&
          <LoadingBox
            position='fixed'
            top_displacement='auto'
            spinner_type={SpinnerTypes.RoundEven}
          />
        }

        {user !== null &&
          <SetName
            user={user}
          />
        }

        {user !== null &&
          <ChangeEmail
            user={user}
          // userData={userData}
          />
        }

        {userData !== null &&
          <EmailSubscriptions
            userData={userData}
          />
        }
      </div>
    </div>
  );
}

export default ProfileSettings;
