import React from 'react';

export interface IFacebookIconProps {
  width?: string;
  height?: string;
  viewBox?: string;
}

const FacebookIconColored: React.FC<IFacebookIconProps> = ({
  width = '17',
  height = '20',
  viewBox = '0 0 17 20',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="#0080ff"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_196_3851)">
        <path
          d="M5.7982 11.7172H8.20975V21.6452C8.20975 21.8412 8.36858 22 8.56459 22H12.6535C12.8495 22 13.0083 21.8412 13.0083 21.6452V11.764H15.7806C15.9608 11.764 16.1125 11.6287 16.1331 11.4497L16.5541 7.79467C16.5657 7.69411 16.5338 7.59341 16.4666 7.51797C16.3992 7.44246 16.3028 7.39924 16.2017 7.39924H13.0084V5.10812C13.0084 4.41746 13.3803 4.06723 14.1138 4.06723C14.2184 4.06723 16.2017 4.06723 16.2017 4.06723C16.3977 4.06723 16.5566 3.90834 16.5566 3.71239V0.357465C16.5566 0.161452 16.3977 0.00262581 16.2017 0.00262581H13.3243C13.304 0.00163226 13.259 0 13.1925 0C12.6933 0 10.9579 0.0980064 9.58709 1.3591C8.06824 2.7566 8.27937 4.42988 8.32983 4.71999V7.39917H5.7982C5.60219 7.39917 5.44336 7.55799 5.44336 7.75401V11.3623C5.44336 11.5583 5.60219 11.7172 5.7982 11.7172Z"
          fill="#0080ff"
        />
      </g>
      <defs>
        <clipPath id="clip0_196_3851">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIconColored;
