/* eslint-disable camelcase */
import {
  AuthError,
  AuthUtil,
  IProcessUserSessionProps,
  IProcessUserSessionResult,
  IRefreshUserSessionViaAmplifyResult,
  IUserSessionLogOut,
  LoginStatus,
  LoginStatusClassicUserToBeMigrated,
  UserOrigin,
  UserSessionStatusCodeEnum,
} from './Interfaces';
import { getCookie, removeCookie } from './CookieUtil';
import { Amplify, ResourcesConfig, } from 'aws-amplify';
import { uuidv4 } from './MixedUtil';
import { fetchMyLearningLegacy, getMyLearningLegacyUserStatus } from './InternalProjects/MyLearningLegacyUtil';
import {
  appIsProfile,
  envIsProd,
  // getCurrentUrlClean,
  // getListOfProfileUserPrivatePageUrls,
  getLogoutUrl,
  getProfileUrl,
} from './EnvironmentUtil';
import { logging } from '../Util/LoggingUtil';
import { CaptchaTokenType } from '../Component/Captcha/Captcha';
import { CustomTypeMod, OperationResultType, RequestResponseType, UserCognitoRawAttributeType } from './InterfaceAndTypeUtil';
import { getCognitoConfig, ICognitoConfig } from '../Cfg/CognitoCfg';
import {
  changeStatusCodeInUserSessionCookies,
  deleteBackendUserSession,
  userSessionRedirectionLogicHandler,
  refreshUserInfoToken,
  refreshUserSession,
  refreshUserSessionViaRedirect,
  registerBackendUserSession,
  restartUserSessionViaRedirect,
  upsertActiveUserSessionCookies,
  verifyAccessToken,
  verifyUserSession,
  logOutViaRedirect,
  setUserSessionCookieDebugMeta,
  _tmpDebugUserSession,
} from './UserSessionUtil';
import { fetchProfile } from './InternalProjects/ProfileUtil';
import { fetchMyLearning } from './InternalProjects/MyLearningUtil';
import { Exception, getMetaPreparedFromException } from './ExceptionUtil';
import { getLocalCurrentUts } from './TimeUtil';
import { getKeyAndValueByKeySuffix } from './DataUtil';
import {
  amplifyAuth as AmplifyAuth,
  CognitoHostedUIIdentityProvider,
  CognitoUserSession,
  FederatedSignInOptions,
  FederatedSignInOptionsCustom,
} from './AmplifyAuth';
import { SignUpOutput, autoSignIn, fetchAuthSession  } from 'aws-amplify/auth';

let _amplifyInitialConfigIsSet: boolean | null = null;

const _initAuth = ({ context, cognitoCfg }: { context: string, cognitoCfg?: ICognitoConfig }) => {
  logging.logDebug('Amplify -> initAuth -> context, _amplifyInitialConfigIsSet: ', {
    context,
    _amplifyInitialConfigIsSet,
  });

  if (_amplifyInitialConfigIsSet !== null) {
    return;
  }

  const _appIsProfile = appIsProfile();

  _amplifyInitialConfigIsSet = _appIsProfile;

  if (!_appIsProfile) {
    return;
  }

  if (typeof cognitoCfg === 'undefined') {
    cognitoCfg = getCognitoConfig();
  }

  const amplifyConfig: ResourcesConfig = {
    Auth: {
      Cognito: {
        userPoolId: cognitoCfg.userPoolId,
        userPoolClientId: cognitoCfg.userPoolWebClientId,
        loginWith: {
          oauth: {
            redirectSignIn: [getProfileUrl()],
            redirectSignOut: [getLogoutUrl()],
            domain: cognitoCfg.authDomain,
            scopes: ['openid', 'aws.cognito.signin.user.admin'],
            responseType: 'code',
          },
        },
        signUpVerificationMethod: 'link',
      },
    },

    // storage: localStorage,
  };

  logging.logDebug('Amplify -> initAuth -> amplifyConfig: ', amplifyConfig);

  Amplify.configure(amplifyConfig);
};

export const getCognitoFieldFromLocalStorage = (fieldName: string) => {
  // const localStorageData = JSON.parse(JSON.stringify(localStorage));

  // const cognitoFieldKeyVal = getKeyAndValueByKeySuffix<string>(localStorageData, `.${fieldName}`);

  // if (typeof cognitoFieldKeyVal !== 'undefined') {
  //   return cognitoFieldKeyVal.value;
  // }

  const cognitoLocalStorageContext = getCognitoLocalStorageContext();

  return localStorage.getItem(`${cognitoLocalStorageContext}.${fieldName}`) ?? undefined;
};

let _processUserSessionDethrottleCache: IProcessUserSessionResult | null = null;
let _processUserSessionDethrottleCacheUts = 0;

export const clearProcessUserSessionDethrottleCache = () => {
  _processUserSessionDethrottleCache = null;
  _processUserSessionDethrottleCacheUts = 0;
};

/**
 * To be called only within "processUserSession".
 * If session is expired but can be refreshed we will do it this time the amplify way.
 * When session is ok we submit it to the backend.
 */
const _patchLegacySession = async ({ refreshToken }: { refreshToken: string }) => {
  const output: IProcessUserSessionResult = {
    error: {
      code: 'FPPLS',
      description: 'Failed performing "_patchLegacySession"',
    },
    data: {} as IProcessUserSessionResult['data'],
  };

  let accessToken = getCognitoFieldFromLocalStorage('accessToken');

  logging.logDebug('(1) CognitoUtil -> _patchLegacySession -> accessToken: ', accessToken);

  if (!accessToken) {
    // extra check
    output.error = {
      code: 'USNF', // legacy code
      description: 'User session not found',
      meta: {
        origin: '_patchLegacySession',
      },
    };

    logging.logDebug('(E1) CognitoUtil -> _patchLegacySession -> output.error: ', output.error);

    await logOut({
      context: 'CUPUSPLS1',
      skipBackendUserSessionDeletion: true,
      skipUserSessionStatusCodeCookieChange: true,
      reason: {
        _patchLegacySessionOutput: output,
      },
    });

    return output;
  }

  const cognitoCfg = getCognitoConfig();

  let cognitoAccessTokenVerificationRes = verifyAccessToken({
    cognitoCfg,
    accessToken, // from local storage
  });

  logging.logDebug(
    '(2) CognitoUtil -> _patchLegacySession -> verifyAccessToken -> cognitoAccessTokenVerificationRes: ',
    cognitoAccessTokenVerificationRes,
  );

  const cookieUserSessionVerificationRes = verifyUserSession({
    cognitoCfg,
  });

  logging.logDebug(
    '(3) CognitoUtil -> _patchLegacySession -> verifyUserSession -> cookieUserSessionVerificationRes: ',
    cookieUserSessionVerificationRes,
  );

  let cognitoUserSession: CognitoUserSession;

  if (
    cookieUserSessionVerificationRes.error.code === 'USSBR' || // User session should be refreshed
    cookieUserSessionVerificationRes.error.code === 'USHE' || // User session has expired
    cookieUserSessionVerificationRes.error.code === 'ATHE' || // Access token has expired
    cookieUserSessionVerificationRes.error.code === 'ATSBR' || // Access token should be refreshed
    cognitoAccessTokenVerificationRes.error.code === 'USHE' // User session has expired
  ) {
    // refresh the old way
    const refreshUserSessionRes = await refreshUserSessionViaAmplify();

    logging.logDebug('(4) CognitoUtil -> _patchLegacySession -> refreshUserSessionRes: ', refreshUserSessionRes);

    if (refreshUserSessionRes.error.code !== '0') {
      output.error = {
        code: 'FREFCUS',
        description: 'Failed refreshing current user sessions',
        meta: refreshUserSessionRes.error,
      };

      logging.logDebug('(E2) CognitoUtil -> _patchLegacySession -> output.error: ', output.error);

      await logOut({
        context: 'CUPUSPLS2',
        skipBackendUserSessionDeletion: true,
        skipUserSessionStatusCodeCookieChange: true,
        reason: {
          refreshUserSessionRes,
        },
      });

      return output;
    }

    cognitoUserSession = refreshUserSessionRes.data; // new session

    if (cognitoUserSession === undefined) {
      // there was no refresh
      const getCognitoUserSessionRes = await getCognitoUserSession();

      if (getCognitoUserSessionRes.error.code !== '0') {
        output.error = getCognitoUserSessionRes.error;

        logging.logDebug('(E4) CognitoUtil -> _patchLegacySession -> output.error: ', output.error);

        await logOut({
          context: 'CUPUSPLS4',
          skipBackendUserSessionDeletion: true,
          skipUserSessionStatusCodeCookieChange: true,
          reason: {
            getCognitoUserSessionRes,
          },
        });

        return output;
      }

      cognitoUserSession = getCognitoUserSessionRes.data;
    }

    accessToken = cognitoUserSession.getAccessToken().getJwtToken();

    if (accessToken === undefined) {
      output.error = {
        code: 'AIU',
        description: 'AccessToken is undefined',
      };
      return output;
    }

    cognitoAccessTokenVerificationRes = verifyAccessToken({
      cognitoCfg,
      accessToken, // new one refreshed via amplify
    });

    logging.logDebug('(5) CognitoUtil -> _patchLegacySession -> accessToken: ', accessToken);
  }

  if (cognitoAccessTokenVerificationRes.error.code !== '0') {
    output.error = cognitoAccessTokenVerificationRes.error;

    logging.logDebug('(E3) CognitoUtil -> _patchLegacySession -> output.error: ', output.error);

    await logOut({
      context: 'CUPUSPLS3',
      skipBackendUserSessionDeletion: true,
      skipUserSessionStatusCodeCookieChange: true,
      reason: {
        cognitoAccessTokenVerificationRes,
      },
    });

    return output;
  }

  // we are good so far
  const registerBackendUserSessionRes = await registerBackendUserSession({
    accessToken,
    refreshToken: refreshToken,
    legacy: true,
  });

  logging.logDebug('(8) CognitoUtil -> _patchLegacySession -> registerBackendUserSessionRes: ', registerBackendUserSessionRes);

  if (registerBackendUserSessionRes.error.code !== '0') {
    output.error = {
      code: 'FRCUSIB',
      description: 'Failed registering current user session in backend',
      meta: registerBackendUserSessionRes.error,
    };

    logging.logDebug('(E5) CognitoUtil -> _patchLegacySession -> output.error: ', output.error);

    await logOut({
      context: 'CUPUSPLS5',
      skipBackendUserSessionDeletion: true,
      skipUserSessionStatusCodeCookieChange: true,
      reason: {
        registerBackendUserSessionRes,
      },
    });

    return output;
  }

  const upsertActiveUserSessionCookiesRes = upsertActiveUserSessionCookies({
    accessToken: accessToken,
    userInfoCookie: registerBackendUserSessionRes.data.userInfoCookie,
    sessionId: registerBackendUserSessionRes.data.sessionId,
    sessionLifespan: registerBackendUserSessionRes.data.sessionLifespan,
  });

  if (upsertActiveUserSessionCookiesRes.error.code !== '0') {
    output.error = {
      code: 'FUAUSCPLS',
      description: 'Failed upserting active user session cookies',
      meta: {
        context: 'CognitoUtil -> _patchLegacySession',
        upsertActiveUserSessionCookiesRes,
      },
    };

    logging.logDebug('(E6) CognitoUtil -> _patchLegacySession -> output.error: ', output.error);

    await logOut({
      context: 'CUPUSPLS6',
      skipBackendUserSessionDeletion: false,
      skipUserSessionStatusCodeCookieChange: true,
      reason: {
        registerBackendUserSessionRes,
      },
    });

    return output;
  }

  output.error = {
    code: '0',
  };

  output.data = cognitoAccessTokenVerificationRes.data;

  logging.logDebug('(10) CognitoUtil -> _patchLegacySession -> output.data: ', output.data);

  return output;
};

export const isSocialLogin = () => {
  if (appIsProfile() && window.location.search) {
    const urlQueryStr = window.location.search;

    if (
      (urlQueryStr.indexOf('?code=') !== -1 || urlQueryStr.indexOf('&code=') !== -1) &&
      (urlQueryStr.indexOf('?state=') !== -1 || urlQueryStr.indexOf('&state=') !== -1)
    ) {
      return true;
    }
  }

  return false;
};

export const processUserSession = async ({
  context,
  handleRedirectionLogic = true,
  preferDethrottleCache = true,
}: IProcessUserSessionProps): Promise<IProcessUserSessionResult> => {
  logging.logDebug('(1) CognitoUtil -> processUserSession -> initialized -> context: ', context);

  if (
    preferDethrottleCache &&
    _processUserSessionDethrottleCache !== null &&
    getLocalCurrentUts() - _processUserSessionDethrottleCacheUts < 5 // serve the cached version that is not older than 5 seconds
  ) {
    logging.logDebug('(-1) CognitoUtil -> processUserSession -> _processUserSessionDethrottleCache: ', _processUserSessionDethrottleCache);

    return _processUserSessionDethrottleCache;
  }

  let output: IProcessUserSessionResult = {
    error: {
      code: 'FPPUS',
      description: 'Failed processing "processUserSession"',
    },
    data: {} as IProcessUserSessionResult['data'],
  };

  try {
    const _appIsProfile = appIsProfile();

    const cognitoLocalStorageContext = _appIsProfile ? getCognitoLocalStorageContext() : '';

    const cognitoCfg = getCognitoConfig();

    logging.logDebug('(2) CognitoUtil -> processUserSession -> cognitoCfg: ', cognitoCfg);

    let cookieUserSessionVerificationRes = verifyUserSession({
      cognitoCfg,
    });

    logging.logDebug(
      '(3) CognitoUtil -> processUserSession -> verifyUserSession -> cookieUserSessionVerificationRes: ',
      cookieUserSessionVerificationRes,
    );

    if (_appIsProfile) {
      // some day better to move our social login flow into a separate component initialized under a dedicated route (on social auth redirections)
      const _isSocialLogin = isSocialLogin();
      logging.logDebug('(4.1.1) CognitoUtil -> processUserSession -> _isSocialLogin: ', _isSocialLogin);

      if (_isSocialLogin) {
        const handleSocialLoginRes = await _handleSocialLogin();
        logging.logDebug('(4.1.2) CognitoUtil -> processUserSession -> handleSocialLoginRes: ', handleSocialLoginRes);

        if (handleSocialLoginRes.error.code !== '0') {
          output.error = {
            code: 'FPSNL',
            description: 'Failed processing social network login',
            meta: handleSocialLoginRes.error,
          };

          logging.logDebug('(E_FPSNL) CognitoUtil -> processUserSession -> output.error: ', output.error);

          await logOut({
            context: 'CUPUS_FPSNL',
            skipBackendUserSessionDeletion: false,
            skipUserSessionStatusCodeCookieChange: true,
            reason: {
              handleSocialLoginRes,
            },
          });

          _processUserSessionDethrottleCache = output;
          _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

          return output;
        } else {
          cookieUserSessionVerificationRes = verifyUserSession({
            cognitoCfg,
          });
        }
      }

      _execDevelopmentCleanup();

      if (
        cookieUserSessionVerificationRes.error.code === 'USLA' || // User should login again
        cookieUserSessionVerificationRes.error.code === 'RTHE' || // Refresh token has expired
        (cookieUserSessionVerificationRes.error.code === 'USNF' && // User session not found
          typeof cookieUserSessionVerificationRes.error.meta !== 'undefined' &&
          cookieUserSessionVerificationRes.error.meta.logout === true)
      ) {
        // we ensure older session stuff is wiped out from cookies and profile localStorage
        await logOut({
          context: 'CUPUS0',
          skipBackendUserSessionDeletion: false,
          skipUserSessionStatusCodeCookieChange: true,
          reason: {
            cookieUserSessionVerificationRes,
          },
        });

        changeStatusCodeInUserSessionCookies({
          newStatusCode: UserSessionStatusCodeEnum.LoggedOut,
        });

        cookieUserSessionVerificationRes.error = {
          code: 'USNF',
          description: 'User session not found',
          meta: {
            origin: 'processUserSession',
            legacy: false,
            logOut: false,
            prevError: cookieUserSessionVerificationRes.error,
          },
        };
      }

      // patch on fly legacy sessions
      let refreshToken = getCognitoFieldFromLocalStorage('refreshToken');

      if (refreshToken) {
        // cognito session is present
        const cognitoCfg = getCognitoConfig();

        const userSessionLogicVersion = localStorage.getItem('userSessionVersion'); // custom

        logging.logDebug('(4.2.1) CognitoUtil -> processUserSession -> userSessionLogicVersion, cognitoCfg.userSessionLogicVersion: ', {
          userSessionLogicVersion,
          cognitoCfgUserSessionLogicVersion: cognitoCfg.userSessionLogicVersion,
        });

        if (userSessionLogicVersion !== cognitoCfg.userSessionLogicVersion) {
          // legacy session detected
          logging.logDebug(
            '(4.2.2) CognitoUtil -> processUserSession -> _patchLegacySession -> localStorage: ',
            JSON.stringify(localStorage),
          );

          const patchLegacySessionRes = (await _patchLegacySession({
            refreshToken,
          })) as IProcessUserSessionResult;

          if (patchLegacySessionRes.error.code !== '0') {
            patchLegacySessionRes.error.code = `RUS_${patchLegacySessionRes.error.code}`;
          }

          logging.logDebug('(5) CognitoUtil -> processUserSession -> patchLegacySessionRes: ', patchLegacySessionRes);

          // replace refresh token from profile local storage with a dummy value for security reasons
          refreshToken = localStorage.getItem(`${cognitoLocalStorageContext}.refreshToken`) ?? undefined;

          if (refreshToken) {
            // if session still exists
            localStorage.setItem('userSessionVersion', cognitoCfg.userSessionLogicVersion);
            // localStorage.setItem(`${cognitoLocalStorageContext}.refreshToken`, cognitoCfg.userSessionDymmyRefreshToken);
          }

          output = patchLegacySessionRes;

          _processUserSessionDethrottleCache = output;
          _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

          if (handleRedirectionLogic) {
            userSessionRedirectionLogicHandler(output);
          }

          return output;
        }
      }
    }

    // < refresh
    if (
      cookieUserSessionVerificationRes.error.code === 'USSBR' || // User session should be refreshed
      cookieUserSessionVerificationRes.error.code === 'USHE' || // User session has expired
      cookieUserSessionVerificationRes.error.code === 'ATHE' || // Access token has expired
      cookieUserSessionVerificationRes.error.code === 'ATSBR' // Access token should be refreshed
    ) {
      // refresh
      const legacySession =
        typeof cookieUserSessionVerificationRes.error.meta !== 'undefined' &&
        typeof cookieUserSessionVerificationRes.error.meta.legacy !== 'undefined' &&
        cookieUserSessionVerificationRes.error.meta.legacy;

      logging.logDebug('(6.1) CognitoUtil -> processUserSession -> refresh -> legacy: ', legacySession);

      if (!legacySession) {
        // refresh the new way
        const refreshUserSessionRes = await refreshUserSession('CognitoUtil -> processUserSession');

        logging.logDebug('(6.2) CognitoUtil -> processUserSession -> refreshUserSessionRes: ', refreshUserSessionRes);

        if (refreshUserSessionRes.error.code !== '0') {
          output.error = {
            code: 'RUS_FREFCUS1',
            description: 'Failed refreshing current user sessions',
            meta: refreshUserSessionRes.error,
          };

          logging.logDebug('(E2) CognitoUtil -> processUserSession -> output.error: ', output.error);

          await logOut({
            context: 'CUPUS1',
            skipBackendUserSessionDeletion: false,
            skipUserSessionStatusCodeCookieChange: true,
            reason: {
              refreshUserSessionRes,
            },
          });

          _processUserSessionDethrottleCache = output;
          _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

          if (handleRedirectionLogic) {
            userSessionRedirectionLogicHandler(output);
          }

          return output;
        }

        // after refresh check again user session data stored in cookies
        cookieUserSessionVerificationRes = verifyUserSession({
          cognitoCfg,
        });

        logging.logDebug(
          '(7) CognitoUtil -> processUserSession -> verifyUserSession -> cookieUserSessionVerificationRes: ',
          cookieUserSessionVerificationRes,
        );

        if (cookieUserSessionVerificationRes.error.code !== '0') {
          output.error = {
            code: 'RUS_FREFCUS2',
            description: 'Failed refreshing current user sessions',
            meta: cookieUserSessionVerificationRes.error,
          };

          logging.logDebug('(E3.1) CognitoUtil -> processUserSession -> output.error: ', output.error);

          await logOut({
            context: 'CUPUS2.1',
            skipBackendUserSessionDeletion: false,
            skipUserSessionStatusCodeCookieChange: true,
            reason: {
              cookieUserSessionVerificationRes,
            },
          });

          _processUserSessionDethrottleCache = output;
          _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

          if (handleRedirectionLogic) {
            userSessionRedirectionLogicHandler(output);
          }

          return output;
        }
      } else {
        // refresh the old way
        if (_appIsProfile) {
          // it should have been patched by this moment
          output.error = {
            code: 'RUS_FLUSP',
            description: 'Failed legacy user session patch',
            meta: {
              _appIsProfile,
              legacySession,
              cookieUserSessionVerificationRes,
            },
          };

          logging.logDebug('(E3.2) CognitoUtil -> processUserSession -> output.error: ', output.error);

          await logOut({
            context: 'CUPUS2.2',
            skipBackendUserSessionDeletion: false,
            skipUserSessionStatusCodeCookieChange: true,
            reason: {
              _appIsProfile,
              legacySession,
              cookieUserSessionVerificationRes,
            },
          });

          _processUserSessionDethrottleCache = output;
          _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

          if (handleRedirectionLogic) {
            userSessionRedirectionLogicHandler(output);
          }

          return output;
        }
      }
    }
    // > refresh

    if (cookieUserSessionVerificationRes.error.code !== '0') {
      output.error = cookieUserSessionVerificationRes.error;

      logging.logDebug('(E4) CognitoUtil -> processUserSession -> output.error: ', output.error);

      await logOut({
        context: 'CUPUS3',
        skipBackendUserSessionDeletion: false,
        skipUserSessionStatusCodeCookieChange: true,
        reason: {
          cookieUserSessionVerificationRes,
        },
      });

      _processUserSessionDethrottleCache = output;
      _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

      if (handleRedirectionLogic) {
        userSessionRedirectionLogicHandler(output);
      }

      return output;
    }

    if (_appIsProfile) {
      // update on fly local storage access token
      const accessTokenFromLocalStorage = localStorage.getItem(`${cognitoLocalStorageContext}.accessToken`);

      const cookieAccessToken = getCookie('accessToken') as string; // we assume that by this moment "accessToken" cookie will be present

      if (accessTokenFromLocalStorage && accessTokenFromLocalStorage !== cookieAccessToken) {
        localStorage.setItem(`${cognitoLocalStorageContext}.accessToken`, cookieAccessToken);
      }

      const getCognitoUserSessionRes = await getCognitoUserSession();

      if (getCognitoUserSessionRes.error.code !== '0') {
        output.error = getCognitoUserSessionRes.error;

        logging.logDebug('(E5) CognitoUtil -> processUserSession -> output.error: ', output.error);

        await logOut({
          context: 'CUPUS4',
          skipBackendUserSessionDeletion: false,
          skipUserSessionStatusCodeCookieChange: true,
          reason: {
            getCognitoUserSessionRes,
          },
        });

        _processUserSessionDethrottleCache = output;
        _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

        return output;
      }
    }

    // all went fine
    output = cookieUserSessionVerificationRes;

    logging.logDebug('(10) CognitoUtil -> processUserSession -> output: ', output);
  } catch (error) {
    const exc = error as any;
    logging.logDebug('(E1) CognitoUtil -> processUserSession -> exc: ');
    logging.logDebug(exc);

    output.error.meta = getMetaPreparedFromException(exc);

    await logOut({
      context: 'CUPUS5',
      skipBackendUserSessionDeletion: false,
      skipUserSessionStatusCodeCookieChange: true,
      reason: {
        output,
      },
    });
  }

  _processUserSessionDethrottleCache = output;
  _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

  return output;
};

export type IGetCognitoUserSessionResult = CustomTypeMod<
  OperationResultType,
  {
    data: CognitoUserSession,
  }
>;

export const getCognitoUserSession = async () => {
  _initAuth({
    context: 'getCognitoUserSession',
  });

  const output: IGetCognitoUserSessionResult = {
    error: {
      code: '1',
      description: 'Failed performing "getCognitoUserSession"',
    },
    data: undefined as unknown as IGetCognitoUserSessionResult['data'],
  };

  try {
    logging.logDebug('CognitoUtil -> getCognitoUserSession -> localStorage: ', JSON.stringify(localStorage));

    const cognitoUserSession = await AmplifyAuth.currentSession();

    output.data = cognitoUserSession;

    logging.logDebug('CognitoUtil -> getCognitoUserSession -> cognitoUserSession: ', cognitoUserSession);

    if (cognitoUserSession === undefined) {
      output.error = {
        code: 'FRCUS2',
        description: 'Failed retrieving cognito user session',
      };

      logging.logDebug('(E2) CognitoUtil -> getCognitoUserSession -> output.error: ', output.error);

      return output;
    }

    if (!cognitoUserSession.isValid()) {
      output.error = {
        code: 'CUSINV',
        description: 'Cognito user session is not valid',
      };

      logging.logDebug('(E3) CognitoUtil -> getCognitoUserSession -> output.error: ', output.error);

      return output;
    }

    output.error = { code: '0' };
  } catch (error) {
    const exc = error as any;
    logging.logDebug('CognitoUtil -> getCognitoUserSession -> exc: ');
    logging.logDebug(exc);

    output.error = {
      code: 'FRCUS1',
      description: 'Failed retrieving cognito user session',
      meta: getMetaPreparedFromException(exc),
    };

    logging.logDebug('(E1) CognitoUtil -> getCognitoUserSession -> output.error: ', output.error);
  }

  return output;
};

const _registerUserSession = async () => {
  logging.logDebug('_registerUserSession -> init');

  const output: OperationResultType = {
    error: {
      code: '1',
      description: 'Failed performing "_registerUserSession"',
    },
    data: null,
  };

  const accessToken = getCognitoFieldFromLocalStorage('accessToken');
  logging.logDebug('_registerUserSession -> accessToken: ', accessToken);

  const refreshToken = getCognitoFieldFromLocalStorage('refreshToken');

  if (!accessToken || !refreshToken) {
    output.error = {
      code: 'ATORTWNFIBS',
      description: 'Access token / refresh token were not found in browser storage',
      meta: {
        accessToken,
        refreshToken,
      },
    };

    logging.logDebug('(E1) _registerUserSession -> output.error: ', output.error);

    return output;
  }

  const cognitoCfg = getCognitoConfig();

  localStorage.setItem('userSessionVersion', cognitoCfg.userSessionLogicVersion);
  // replace refresh token from profile local storage with a dummy value for security reasons
  // localStorage.setItem(`${getCognitoLocalStorageContext()}.refreshToken`, 'none'); // amplify doesn't like it
  // localStorage.setItem(`${getCognitoLocalStorageContext()}.refreshToken`, cognitoCfg.userSessionDymmyRefreshToken);

  const registerBackendUserSessionRes = await registerBackendUserSession({
    accessToken,
    refreshToken,
    legacy: false,
  });

  logging.logDebug('_registerUserSession -> registerBackendUserSessionRes: ', registerBackendUserSessionRes);

  if (registerBackendUserSessionRes.error.code !== '0') {
    output.error = registerBackendUserSessionRes.error;

    logging.logDebug('(E2) _registerUserSession -> output.error: ', output.error);

    return output;
  }

  const upsertActiveUserSessionCookiesRes = upsertActiveUserSessionCookies({
    accessToken,
    userInfoCookie: registerBackendUserSessionRes.data.userInfoCookie,
    sessionId: registerBackendUserSessionRes.data.sessionId,
    sessionLifespan: registerBackendUserSessionRes.data.sessionLifespan,
  });

  if (upsertActiveUserSessionCookiesRes.error.code !== '0') {
    output.error = {
      code: 'FUAUSCRUS',
      description: 'Failed upserting active user session cookies',
      meta: {
        context: 'CognitoUtil -> _registerUserSession',
        upsertActiveUserSessionCookiesRes,
      },
    };

    logging.logDebug('(E3) _registerUserSession -> output.error: ', output.error);

    return output;
  }

  clearProcessUserSessionDethrottleCache();

  output.error = { code: '0' };

  return output;
};

export const loginUser = async (
  email: string,
  password: string,
  username?: string, // present on first email verification step
): Promise<LoginStatus | LoginStatusClassicUserToBeMigrated> => {
  logging.logDebug('loginUser -> init');

  _initAuth({
    context: 'loginUser',
  });

  // clear data of previous sessions
  await logOut({
    context: 'CULU',
    skipBackendUserSessionDeletion: false,
    skipUserSessionStatusCodeCookieChange: true,
    reason: {
      explicitNewLogin: true,
      loginType: 'Standard',
    },
  });

  // If email/password exists in myLearningLegacy, and match, sign up in cognito and continue login
  // If user is already migrated, myLearningLegacyUserStatus will be undefined
  const myLearningLegacyUserStatus = await getMyLearningLegacyUserStatus(email, password);
  logging.logDebug('loginUser -> myLearningLegacyUserStatus: ', myLearningLegacyUserStatus);

  try {
    const cognitoSignInRes = await AmplifyAuth.signIn(typeof username !== 'undefined' ? username : email, password);
    logging.logDebug('loginUser -> cognitoSignInRes: ', cognitoSignInRes);
    logging.logDebug('loginUser -> localStorage: ', JSON.stringify(localStorage));

    if (cognitoSignInRes.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
      return {
        status: 'confirm_sign_in_with_new_password_required',
      };
    }
    
    // previous action will throw exceptions if something is wrong .. so code below will be executed only on successful login

    const registerUserSessionRes = await _registerUserSession();

    if (registerUserSessionRes.error.code !== '0') {
      logging.logError('loginUser -> registerUserSessionRes: ', registerUserSessionRes);

      return {
        status: 'failed',
        error: {
          code: `CRITICAL_FAILURE_WHILE_REGISTERING_SESSION_${registerUserSessionRes.error.code}`,
          msg: `Encountered an issue while registering your session: ${registerUserSessionRes.error.description}`,
        },
      } as LoginStatus;
    }

    const processUserSessionRes = await processUserSession({
      context: 'CognitoUtil -> loginUser',
      preferDethrottleCache: false,
    });

    logging.logDebug('loginUser -> processUserSessionRes: ', processUserSessionRes);

    if (processUserSessionRes.error.code !== '0') {
      logging.logError('loginUser -> processUserSessionRes: ', processUserSessionRes);

      logging.logDebug('loginUser -> return 1');

      return {
        status: 'failed',
        error: {
          code: processUserSessionRes.error.code,
          msg: processUserSessionRes.error.description,
        },
      } as LoginStatus;
    }

    if (
      typeof myLearningLegacyUserStatus !== 'undefined' &&
      myLearningLegacyUserStatus.exists &&
      !myLearningLegacyUserStatus.cid_present // no cognito id is present
    ) {
      // in mylv2 finalize the migration of legacy user progress
      let reqRes = await fetchMyLearning<any>({
        method: 'POST',
        url: '/user/migrate-from-legacy',
        data: myLearningLegacyUserStatus,
        authBearerToken: processUserSessionRes.data.rawStr, // accessToken
      });

      logging.logDebug('loginUser -> mylv2 legacy migration -> reqRes: ', reqRes);

      // save the cognito user sub in the legacy user record
      reqRes = await fetchMyLearningLegacy<any>({
        method: 'POST',
        url: '/meta-for-default/',
        data: {},
      });

      logging.logDebug('loginUser -> mylv1 legacy migration -> reqRes: ', reqRes);
    }

    logging.logDebug('loginUser -> return 2');
    return {
      status: 'loggedin',
    };
  } catch (error) {
    // Cognito sign in failed
    const err = error as any;
    logging.logDebug('loginUser -> error: ', err);

    if (
      typeof username === 'undefined' && // if no email verification step
      myLearningLegacyUserStatus !== undefined &&
      myLearningLegacyUserStatus.exists !== undefined &&
      myLearningLegacyUserStatus.exists
    ) {
      // No user in Cognito - check auth status from classic
      if (err.code === 'UserNotFoundException') {
        if (myLearningLegacyUserStatus.auth_succeeded === 1) {
          let firstName = '';
          let lastName = '';

          if (myLearningLegacyUserStatus.name !== myLearningLegacyUserStatus.email) {
            [firstName, lastName] = splitName(myLearningLegacyUserStatus.name);
          }

          logging.logDebug('loginUser -> return 3');
          return {
            status: 'classic_user_with_name',
            first_name: firstName,
            last_name: lastName,
            email_verified: myLearningLegacyUserStatus.email_verified === 1,
            signed: myLearningLegacyUserStatus.signed !== undefined ? myLearningLegacyUserStatus.signed : '',
          };
        } else {
          logging.logDebug('loginUser -> return 4');
          return {
            status: 'failed',
            error: {
              code: 'CLASSIC_INCORRECT_PASSWORD',
              msg: 'Sorry, your password is not correct. Please try again - or sign up as a new user with the same email.',
            },
          } as LoginStatus;
        }
      }
    }

    logging.logDebug('loginUser -> return 5');
    return {
      status: 'failed',
      error: {
        code: err.name,
        msg: err.message,
      },
    } as LoginStatus;
  }
};

export const federatedLogin = async (provider: CognitoHostedUIIdentityProvider | string): Promise<LoginStatus> => {
  _initAuth({
    context: 'federatedLogin',
  });

  try {
    let options: FederatedSignInOptions | FederatedSignInOptionsCustom;

    if (typeof provider === 'string') {
      options = { customProvider: provider };
    } else {
      options = { provider };
    }

    await AmplifyAuth.federatedSignIn(options);
    return {
      status: 'loggedin',
    };
  } catch (error) {
    const err = error as any;
    logging.logDebug('federatedlogin -> error: ', err);
    return {
      status: 'failed',
      error: {
        code: 'FEDERATED_LOGIN_FAILED',
        msg: err.message || err,
      },
    } as LoginStatus;
  }
};

type IHandleSocialLoginRes = CustomTypeMod<
  OperationResultType,
  {
    data: {
      providerName: string,
      currentUser: any,
      // userSessionVerificationRes: IVerifyUserSessionResult,
    },
  }
>;

// Check if authentication is from social login and handle it accordingly.
const _handleSocialLogin = async () => {
  logging.logDebug('_handleSocialLogin -> init');

  _initAuth({
    context: '_handleSocialLogin',
  });

  // clear data of previous sessions
  await logOut({
    context: 'CUSLU',
    skipBackendUserSessionDeletion: false,
    skipUserSessionStatusCodeCookieChange: true,
    reason: {
      explicitNewLogin: true,
      loginType: 'SocialNetwork',
    },
  });

  const output: IHandleSocialLoginRes = {
    error: {
      code: '1',
      description: 'Failed processing "_handleSocialLogin"',
    },
    data: {} as IHandleSocialLoginRes['data'],
  };

  try {
    // In the case of a federated login, this will wait for amplify.configure to exchange authorization code for access token
    const currentUser = await AmplifyAuth.currentAuthenticatedUser();

    if (currentUser.attributes?.identities === undefined) {
      output.error = {
        code: 'CUAUNF',
        description: 'Identities attribute not found in current user',
      };

      logging.logDebug('(E1) _handleSocialLogin -> output.error: ', output.error);

      return output;
    }

    const providerName = JSON.parse(currentUser.attributes.identities)[0].providerName;

    if (!['Facebook', 'Google', 'Github', 'Skolon', 'Feide'].includes(providerName)) {
      output.error = {
        code: 'SNPINS',
        description: 'Social network provider is not supported',
        meta: {
          providerName,
        },
      };

      logging.logDebug('(E1) _handleSocialLogin -> output.error: ', output.error);

      return output;
    }

    const registerUserSessionRes = await _registerUserSession();

    if (registerUserSessionRes.error.code !== '0') {
      output.error = registerUserSessionRes.error;

      logging.logDebug('(E2) _handleSocialLogin -> output.error: ', output.error);

      return output;
    }

    output.error = { code: '0' };
  } catch (exc: any) {
    logging.logDebug('(E0) _handleSocialLogin -> exc: ');
    logging.logDebug(exc);

    output.error = getMetaPreparedFromException(exc);

    logging.logDebug('(E0) _handleSocialLogin -> output.error: ', output.error);
  }

  return output;
};

const splitName = (name: string): [string, string] => {
  if (name === undefined || name === null || !name) {
    return ['', ''];
  }

  const names = name.split(' ');

  if (names.length === 1) {
    return [names[0], ''];
  } else if (names.length === 2) {
    return [names[0], names[1]];
  }

  return [names.slice(0, names.length - 1).join(' '), names[names.length - 1]];
};

export const registerUser = async ({
  email,
  password,
  firstName,
  lastName,
  emailConsent,
  user_origin,
  user_origin_signed,
  captchaToken,
}: {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  emailConsent: boolean,
  user_origin: UserOrigin,
  user_origin_signed?: string,
  captchaToken?: CaptchaTokenType,
}): Promise<{
  username: string,
  response: AuthError | SignUpOutput,
}> => {
  _initAuth({
    context: 'registerUser',
  });

  const username = uuidv4();

  try {
    const response = await AmplifyAuth.signUp({
      username,
      password,
      attributes: {
        email: email,
        given_name: firstName,
        family_name: lastName,
        'custom:user_origin': user_origin,
        'custom:user_origin_signed': user_origin_signed,
        'custom:recaptcha_token': JSON.stringify(captchaToken),
        'custom:meta': JSON.stringify({
          emailConsent,
        }),
        'custom:isFirstLogin': 'true',
      },
      autoSignIn: {
        enabled: true,
      },
    });
    logging.logDebug('register resp: ', response);
    return { response, username };
  } catch (error) {
    logging.logDebug(error);
    throw error;
  }
};

/*
 Profile user session termination util
*/
export const logOut = async ({
  context,
  skipBackendUserSessionDeletion = false,
  skipUserSessionStatusCodeCookieChange = false,
  skipAmplifySignOut,
  reason,
}: IUserSessionLogOut): Promise<void> => {
  logging.logDebug('logOut -> init: ', {
    context,
    skipBackendUserSessionDeletion,
    skipUserSessionStatusCodeCookieChange,
    reason,
  });

  if (!appIsProfile()) {
    throw new Exception({
      code: 'PUSTUSBUOIPA',
      description: 'Profile user session termination util should be used only in profile app',
    });
  }

  const currentUserSessionStatusCode = getCookie('userSession');

  setUserSessionCookieDebugMeta({
    origin: window.location.href,
    context,
    description: 'logOut',
    reason,
    prevStatusCode: currentUserSessionStatusCode,
    newStatusCode: skipUserSessionStatusCodeCookieChange ? currentUserSessionStatusCode : UserSessionStatusCodeEnum.LoggedOut,
    extra: {
      skipUserSessionStatusCodeCookieChange,
    },
  });

  await _tmpDebugUserSession(true);

  if (!skipBackendUserSessionDeletion) {
    const deleteBackendUserSessionRes = await deleteBackendUserSession();

    logging.logDebug('logOut -> deleteBackendUserSessionRes', deleteBackendUserSessionRes);
  }

  removeCookie('userSession'); // userSessionStatusCode
  removeCookie('userSessionMeta');
  removeCookie('accessToken');
  removeCookie('__c_u_i_1');
  removeCookie('userSessionDebugMeta');

  // from now on we will always try to "await AmplifyAuth.signOut();"
  // if (typeof skipAmplifySignOut === 'undefined') {
  //   skipAmplifySignOut = false;
  // }

  // if (skipAmplifySignOut) {
  //   return;
  // }

  _initAuth({
    context: 'logOut',
  });

  try {
    await AmplifyAuth.signOut();
  } catch (error) {
    const exc = error as any;
    logging.logDebug('logOut -> AmplifyAuth.signOut() -> skipAmplifySignOut, exc: ', skipAmplifySignOut);
    logging.logDebug(exc);
  }

  // extra cleanup of cognito data stored in local storage
  const cognitoLocalStorageContext = getCognitoLocalStorageContext();

  if (cognitoLocalStorageContext) {
    clearCognitoLocalStorageData(cognitoLocalStorageContext);
  }

  // redirection should be handled via userSessionRedirectionLogicHandler
  // if (!getListOfProfileUserPrivatePageUrls().includes(getCurrentUrlClean())) {
  //   restartUserSessionViaRedirect({
  //     context: `logOut -> context: ${context}`,
  //     originUrl: window.location.href,
  //     reason,
  //   });
  // }
};

export const sendResetPassword = async (email: string): Promise<boolean | AuthError> => {
  logging.logDebug('sendResetPassword', email);

  _initAuth({
    context: 'sendResetPassword',
  });

  try {
    const response = await AmplifyAuth.forgotPassword(email);
    logging.logDebug(response);
    return true;
  } catch (error) {
    const err = error as any;
    logging.logDebug(err);
    return {
      code: err.name,
      msg: err.message,
    } as AuthError;
  }
};

export const verifyResetPassword = async (username: string, referenceId: string, newPassword: string): Promise<boolean | AuthError> => {
  logging.logDebug(newPassword, referenceId);

  _initAuth({
    context: 'verifyResetPassword',
  });

  try {
    const response = await AmplifyAuth.forgotPasswordSubmit(username, referenceId, newPassword);
    logging.logDebug(response);
    return true;
  } catch (error) {
    const err = error as any;
    logging.logDebug(err);
    return {
      code: err.name,
      msg: err.message,
    } as AuthError;
  }
};

export const resendVerificationEmail = async (username: string): Promise<boolean | AuthError> => {
  _initAuth({
    context: 'resendVerificationEmail',
  });

  try {
    logging.logDebug(username);
    const response = await AmplifyAuth.resendSignUp(username);
    logging.logDebug(response);
    return true;
  } catch (error) {
    const err = error as any;
    logging.logDebug(err);
    return false;
  }
};

export const storeTokens = async (auth_code: string): Promise<void> => {
  _initAuth({
    context: 'storeTokens',
  });

  const session = await AmplifyAuth.currentSession();
  const access_token = session.getAccessToken().getJwtToken();
  const refresh_token = getCognitoFieldFromLocalStorage('refreshToken');
  const id_token = session.getIdToken().getJwtToken();

  const body = {
    authorization_code: auth_code,
    access_token: access_token,
    refresh_token: refresh_token,
    id_token: id_token,
  };

  try {
    await fetchProfile({
      method: 'POST',
      url: '/storage',
      data: JSON.stringify(body),
      authenticated: false,
    });
  } catch (error) {
    const err = error as any;
    console.error(err);
  }
};

export const getCurrentUser = async () => {
  _initAuth({
    context: 'getCurrentUser',
  });

  return await AmplifyAuth.currentAuthenticatedUser();
};

export const getCurrentUserSession = async () => {
  _initAuth({
    context: 'getCurrentUserSession',
  });

  return await AmplifyAuth.currentSession();
};

export const updateName = async (firstName: string, lastName: string) => {
  _initAuth({
    context: 'updateName',
  });

  const user = await AmplifyAuth.currentAuthenticatedUser();

  const updateAttrRes = await AmplifyAuth.updateUserAttributes(user, {
    given_name: firstName,
    family_name: lastName,
  });

  if (updateAttrRes === 'SUCCESS') {
    await refreshUserInfoToken(); // update uic when first/last name has changed
  }

  return updateAttrRes;
};

const updateEmail = async (email: string, verified?: boolean): Promise<RequestResponseType> => {
  _initAuth({
    context: 'updateEmail',
  });

  const output: RequestResponseType = {
    error: {
      code: '0',
    },
    data: null,
  };

  try {
    const user = await AmplifyAuth.currentAuthenticatedUser();

    const user_attrs: any = {
      email: email,
    };

    if (typeof verified !== 'undefined') {
      user_attrs.email_verified = verified;
    }

    const req_res = await AmplifyAuth.updateUserAttributes(user, user_attrs);

    if (req_res !== 'SUCCESS') {
      output.error.code = 'AUE_1';
      output.error.description = 'Internal error';

      return output;
    }

    // await refreshUserInfoToken(); this update should happen when the email is confirmed
  } catch (error) {
    const exc = error as any;
    logging.logDebug('Unable to update email -> exc: ', exc);

    output.error.code = 'AUE_2';
    output.error.description = exc.message;
  }

  return output;
};

/**
 * @deprecated Legacy user session refresh logic kept for backwards compatibility
 */
export const refreshUserSessionViaAmplify = async (): Promise<IRefreshUserSessionViaAmplifyResult> => {
  _initAuth({
    context: 'refreshUserSessionViaAmplify',
  });

  const output: IRefreshUserSessionViaAmplifyResult = {
    error: {
      code: 'FPRUSVA',
      description: 'Failed performing "refreshUserSessionViaAmplify"',
    },
    data: null as unknown as IRefreshUserSessionViaAmplifyResult['data'],
  };

  // let user: any;
  // let session: CognitoUserSession;

  try {
    await AmplifyAuth.currentAuthenticatedUser();
    await AmplifyAuth.currentSession();
  } catch (error) {
    const exc = error as any;
    logging.logDebug('refreshUserSessionViaAmplify -> exc1: ', exc);

    output.error = getMetaPreparedFromException(exc);

    return output;
  }

  return new Promise<IRefreshUserSessionViaAmplifyResult>(resolve => {
    try {
      // const sessionAccessToken = session.getAccessToken().getJwtToken();

      // const sessionRefreshToken = session.getRefreshToken().getToken();

      // logging.logDebug('refreshUserSessionViaAmplify -> sessionRefreshToken: ', sessionRefreshToken);

      /*
        user.refreshSession(
          session.getRefreshToken(),
          (exc: unknown, newSession: CognitoUserSession) => {
            if (exc) {
              throw exc;
            }

            logging.logDebug(
              'refreshUserSessionViaAmplify -> newSession: ',
              newSession
            );

            // const newSessionAccessToken = newSession.getAccessToken().getJwtToken();

            // if (sessionAccessToken === newSessionAccessToken) {
            //   logging.logDebug('refreshUserSessionViaAmplify -> sessionAccessToken: ', sessionAccessToken);
            //   logging.logDebug('refreshUserSessionViaAmplify -> newSessionAccessToken: ', newSessionAccessToken);
            // }

            if (!newSession.isValid()) {
              // we assume this will never happen
              logging.logDebug(
                'refreshUserSessionViaAmplify -> new session is not valid'
              );

              output.error = {
                code: 'NGSINV',
                description: 'Newly generated session is not valid',
                meta: {
                  newSession
                }
              };

              resolve(output);
            } else {
              output.data = newSession;

              output.error = { code: '0' };

              resolve(output);
            }
          }
        );*/

      fetchAuthSession({ forceRefresh: true });
    } catch (error) {
      const exc = error as any;
      logging.logDebug('refreshUserSessionViaAmplify -> exc2: ', exc);

      output.error = getMetaPreparedFromException(exc);

      resolve(output);
    }
  });
};

export const getCognitoLocalStorageContext = (): string => {
  let context = 'CognitoIdentityServiceProvider.';

  const config = getCognitoConfig();

  context += config.userPoolWebClientId;

  const active_user = localStorage.getItem(context + '.LastAuthUser');
  // logging.logDebugAlt('active_user: ', active_user);

  if (!active_user) {
    return '';
  }

  return context + '.' + active_user;
};

export const clearCognitoLocalStorageData = (context?: string) => {
  if (typeof context === 'undefined') {
    context = getCognitoLocalStorageContext();
  }

  context = `${context}.`;

  Object.keys(localStorage).forEach(key => {
    if (key.startsWith(context!)) {
      localStorage.removeItem(key);
    }
  });
};

export const updateCachedUserAttribute = (key: string, value: any): OperationResultType => {
  // WARNING! On next versions of Amplify there may be some extra validation done on cached user data, currently we are free to alter it as we want
  _initAuth({
    context: 'updateCachedUserAttribute',
  });

  const output: OperationResultType = {
    error: {
      code: '0',
    },
    data: null,
  };

  try {
    logging.logDebugAlt(key, value);

    const cached_user_data_context = getCognitoLocalStorageContext();
    // logging.logDebugAlt('cached_user_data_context: ', cached_user_data_context);

    if (!cached_user_data_context) {
      output.error.code = 'AUCUATTR_1';
      output.error.description = 'Unable to retrieve cached user data context (localStorage prefix)';

      return output;
    }

    const cached_user_data_str = localStorage.getItem(cached_user_data_context + '.userData');
    // logging.logDebugAlt('cached_user_data_str: ', cached_user_data_str);

    if (!cached_user_data_str) {
      output.error.code = 'AUCUATTR_2';
      output.error.description = 'Unable to retrieve cached user data';

      return output;
    }

    const cached_user_data = JSON.parse(cached_user_data_str);
    // logging.logDebugAlt('cached_user_data: ', cached_user_data);

    cached_user_data.UserAttributes.forEach((attr: UserCognitoRawAttributeType, index: number) => {
      // logging.logDebugAlt('attr: ', attr);
      // logging.logDebugAlt('index: ', index);

      if (attr.Name === key) {
        attr.Value = value;

        cached_user_data.UserAttributes[index] = attr;
      }
    });

    localStorage.setItem(cached_user_data_context + '.userData', JSON.stringify(cached_user_data));

    output.data = cached_user_data;
  } catch (error) {
    const exc = error as any;
    logging.logDebugAlt('Unable to update cached user data -> exc: ', exc);

    output.error.code = 'AUCUATTR_3';
    output.error.description = exc.message;
  }

  return output;
};

export const getCachedUserData = (): OperationResultType => {
  const output: OperationResultType = {
    error: {
      code: '0',
    },
    data: null,
  };

  try {
    const cached_user_data_context = getCognitoLocalStorageContext();
    // logging.logDebugAlt('cached_user_data_context: ', cached_user_data_context);

    if (!cached_user_data_context) {
      output.error.code = 'AGCUATTRS_1';
      output.error.description = 'Unable to retrieve cached user data context (localStorage prefix)';

      return output;
    }

    const cached_user_data_str = localStorage.getItem(cached_user_data_context + '.userData');
    // logging.logDebugAlt('cached_user_data_str: ', cached_user_data_str);

    if (!cached_user_data_str) {
      output.error.code = 'AGCUATTRS_2';
      output.error.description = 'Unable to retrieve cached user data';

      return output;
    }

    output.data = JSON.parse(cached_user_data_str);
  } catch (error) {
    const exc = error as any;
    logging.logDebugAlt('Unable to update cached user data -> exc: ', exc);

    output.error.code = 'AGCUATTRS_3';
    output.error.description = exc.message;
  }

  return output;
};

export const getCachedUserAttribute = (key: string): OperationResultType => {
  const output: OperationResultType = {
    error: {
      code: '0',
    },
    data: null,
  };

  const cached_user_data_res = getCachedUserData();

  if (cached_user_data_res.error.code !== '0') {
    return cached_user_data_res;
  }

  for (const attr of cached_user_data_res.data.UserAttributes as UserCognitoRawAttributeType[]) {
    if (attr.Name === key) {
      output.data = attr.Value;

      break;
    }
  }

  return output;
};

export const getCurrentUserInfo = async () => {
  _initAuth({
    context: 'getCurrentUserInfo',
  });

  const output: RequestResponseType = {
    error: {
      code: '0',
    },
    data: null,
  };

  try {
    // const user = await AmplifyAuth.currentAuthenticatedUser();

    // await AmplifyAuth.userSession(user);

    output.data = await AmplifyAuth.currentUserInfo();
  } catch (error) {
    const exc = error as any;
    logging.logDebug('Unable to get current user info -> exc: ', exc);

    output.error.code = 'GCUI_1';
    output.error.description = exc.message;
  }

  return output;
};

export const userIsLoggedIn = async ({
  context = 'UNKNOWN',
}: {
  context?: string,
} = {}): Promise<boolean> => {
  logging.logDebug('CognitoUtil -> userIsLoggedIn -> context: ', context);

  const processUserSessionRes = await processUserSession({
    context: `CognitoUtil -> userIsLoggedIn -> context: ${context}`,
    // preferDethrottleCache: false,
  });
  logging.logDebug('CognitoUtil -> userIsLoggedIn -> processUserSessionRes: ', processUserSessionRes);
  logging.logDebugAlt('CognitoUtil -> userIsLoggedIn -> localStorage: ', localStorage, true);

  if (processUserSessionRes.error.code === '0') {
    return true;
  }

  logging.logError('CognitoUtil -> userIsLoggedIn -> processUserSessionRes: ', processUserSessionRes);

  return false;
};

interface ICognitoUserStatusResponse {
  'error.code': string,
  'error.description': string,
  exists: number,
  status: string,
  username: string,
}

export const getCognitoUserStatusByEmail = async (email: string): Promise<ICognitoUserStatusResponse | undefined> => {
  try {
    // anonymous call
    const reqRes = await fetchProfile<ICognitoUserStatusResponse>({
      method: 'POST',
      url: '/info',
      data: {
        email,
      },
      authenticated: false,
    });

    logging.logDebug('getCognitoUserStatusByEmail -> reqRes: ', reqRes);

    if (reqRes.status !== 200) {
      logging.logError('getCognitoUserStatusByEmail -> error(1): ', reqRes);
      return;
    }

    const userStatus = reqRes.data;
    logging.logDebug('getCognitoUserStatusByEmail -> userStatus: ', userStatus);

    if (userStatus['error.code'] !== '0') {
      logging.logError('getCognitoUserStatusByEmail -> error(2): ', {
        'error.code': userStatus['error.code'],
        'error.description': userStatus['error.description'],
      });

      return;
    }

    return userStatus;
  } catch (error) {
    const exc = error as any;
    logging.logError('getCognitoUserStatusByEmail -> error(3): ', exc);
  }

  return;
};

/**
 * Cleanup of bugs introduced during development
 * TODO: (mid) remove after WEB-2419 release
 */
const _execDevelopmentCleanup = () => {
  if (envIsProd()) {
    return;
  }

  const localStorageData = JSON.parse(JSON.stringify(localStorage));

  const legacyUserSessionVersionKeyVal = getKeyAndValueByKeySuffix<string>(localStorageData, '.xVersion');

  if (typeof legacyUserSessionVersionKeyVal !== 'undefined') {
    localStorage.removeItem(legacyUserSessionVersionKeyVal.key);
  }

  if (localStorage.getItem('.accessToken') !== null) {
    localStorage.removeItem('.accessToken');
  }

  removeCookie('xAccessToken');
};

async function autoLogin(callback?: () => void) {
  _initAuth({ context: 'autoLogin' });
  const user = await autoSignIn();

  callback && callback();

  const registerUserSessionRes = await _registerUserSession();

  if (registerUserSessionRes.error.code !== '0') {
    logging.logError('autoLogin -> registerUserSessionRes: ', registerUserSessionRes);

    throw new Error('Failed registering user session');
  }

  const processUserSessionRes = await processUserSession({
    context: 'CognitoUtil -> autoLoginr',
    preferDethrottleCache: false,
  });

  logging.logDebug('autoLogin -> processUserSessionRes: ', processUserSessionRes);

  if (processUserSessionRes.error.code !== '0') {
    logging.logError('autoLogin -> processUserSessionRes: ', processUserSessionRes);

    logging.logDebug('autoLogin -> return 1');

    throw new Error('Failed processing user session');
  }

  return user;
}

export const confirmSignInWithNewPassword = async (password: string) => {
  return await AmplifyAuth.confirmSignInWithNewPasswordRequired(password);
};

export const cognitoAuth: AuthUtil = {
  // initAuth: initAuth,
  processUserSession: processUserSession,
  loginUser: loginUser,
  registerUser: registerUser,
  logOutViaRedirect: logOutViaRedirect, // keeping this export for backwards compatibility
  logOut: logOut,
  sendResetPassword: sendResetPassword,
  verifyResetPassword: verifyResetPassword,
  resendVerificationEmail: resendVerificationEmail,
  storeTokens: storeTokens,
  getCurrentUser: getCurrentUser,
  updateName: updateName,
  updateEmail: updateEmail,
  refreshUserSession: refreshUserSession,
  /**
   * @deprecated Legacy user session refresh logic kept for backwards compatibility
   */
  refreshUserSessionViaRedirect: refreshUserSessionViaRedirect,
  restartUserSessionViaRedirect: restartUserSessionViaRedirect,
  userSessionRedirectionLogicHandler: userSessionRedirectionLogicHandler,
  // submitEmailConfirmationCode: submitEmailConfirmationCode,
  getCurrentUserInfo: getCurrentUserInfo,
  updateCachedUserAttribute: updateCachedUserAttribute,
  getCachedUserData: getCachedUserData,
  getCachedUserAttribute: getCachedUserAttribute,
  userIsLoggedIn: userIsLoggedIn,
  federatedLogin: federatedLogin,
  autoLogin: autoLogin,
  confirmSignInWithNewPassword: confirmSignInWithNewPassword,
};
