import React from 'react';

export const ProgressSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    focusable="false"
    aria-hidden="true"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
    <path d="M19 7a2 2 0 1 0 0 -4a2 2 0 0 0 0 4z"></path>
    <path d="M11 19h5.5a3.5 3.5 0 0 0 0 -7h-8a3.5 3.5 0 0 1 0 -7h4.5"></path>
  </svg>
);
