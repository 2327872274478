import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './Plan.module.scss';
import { PlanSelectionStateContext } from '../../context/plan-selection-context';
import { QuestionCircle, ChevronDown } from 'react-bootstrap-icons';
import { Overlay, Tooltip } from 'react-bootstrap';
import { getClassicUrl } from '../../SharedLib/Util/EnvironmentUtil';

interface IFeature {
  text: string | JSX.Element;
  helpText: string;
}

type CardProps = {
  id: string;
  planType: string;
  price: string;
  period: string;
  description: string;
  features: IFeature[];
  isSelected: boolean;
};

const Card: React.FC<CardProps> = ({
  planType,
  price,
  period,
  description,
  features,
  isSelected,
  id,
}) => {
  const { setSelectedPlanId } = useContext(PlanSelectionStateContext);
  const [show, setShow] = useState<boolean[]>(features.map(() => false));
  const [featuresListVisible, setFeaturesListVisible] = useState(false);
  const targets = useRef<(HTMLSpanElement | null)[]>([]);

  const toggleFeaturesListVisibility = () =>
    setFeaturesListVisible(!featuresListVisible);

  const toggleShow = (index: number) => {
    if (planType !== 'ACADEMY') {
      setShow(show.map((s, idx) => (idx === index ? !s : s)));
    }
  };

  const handleSelection = () => {
    if (id !== 'classroom') setSelectedPlanId(id);
  };

  useEffect(() => {
    if (planType === 'ACADEMY') {
      return;
    }
    const handleClickOutside = (event: MouseEvent) => {
      setShow(
        show.map((s, idx) =>
          targets.current[idx] &&
          !targets.current[idx]!.contains(event.target as Node)
            ? false
            : s,
        ),
      );
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, planType]);

  return (
    <div
      className={`${styles.cardContainer} ${isSelected ? styles.selected : ''}  ${planType === 'ACADEMY' ? styles.noScaleNoPointer : ''}`}
      onClick={handleSelection}
    >
      {planType === 'PRO' && (
        <div className={styles.popularRibbon}>Popular</div>
      )}
      <div className={styles.cardHeader}>
        {planType !== 'ACADEMY' && (
          <input
            type="checkbox"
            checked={isSelected}
            className={styles.checkbox}
          />
        )}
        <h5 className={styles.cardTitle}>{planType}</h5>

        {planType === 'ACADEMY' && (
          <p className={styles.cardPrice}>
            <a
              target='_blank'
              href={getClassicUrl() + '/academy'}
              className={styles.classroomLink}
            >
              Read more
            </a>
          </p>
        )}
        {planType !== 'ACADEMY' && (
          <p className={styles.cardPrice}>
            {price}
            <span className={styles.cardPeriod}>{period}</span>
          </p>
        )}

        <hr />
      </div>
      <p
        className={styles.cardDescription}
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>

      {/*featuresListVisible && (
        <ul className={styles.featuresList}>
          {features.map((feature, index) => (
            <li key={index} className={styles.featureItem}>
              <>
                {feature.text}
                {feature.helpText && (
                  <>
                    <span ref={el => targets.current[index] = el} onClick={(e) => { e.stopPropagation(); toggleShow(index); }} style={{ cursor: 'pointer' }}>
                        &nbsp;<QuestionCircle color='gray' />
                    </span>
                    <Overlay target={targets.current[index]} show={show[index]} placement="right">
                      {(props) => (
                        <Tooltip id={`tooltip-${index}`} {...props}>
                          {feature.helpText}
                        </Tooltip>
                      )}
                    </Overlay>
                  </> // Add closing tag for the fragment element
                )}
              </>
            </li>
          ))}
        </ul>
                      )*/}

      {/*<div onClick={toggleFeaturesListVisibility} style={{ textAlign: 'center', cursor: 'pointer', transition: 'transform 0.3s' }} className={featuresListVisible ? styles.chevronUp : ''}>
        <ChevronDown />
      </div>
                    */}
    </div>
  );
};

export default Card;
