import { get, post as postX, errors } from './utilsEx';
import { IUserProfileSettings } from '../Component/userlib_new/utils/profile-interfaces';

interface IResponse {
  status: number;
  data: any;
  statusText?: string;
}

export const getMyLearningActivity = async () => {
  const response = (await get(
    'profile-api/profile/activity',
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getProfileData = async () => {
  const response = (await get(
    'profile-api/profile/basic',
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const postProfileData = async (data: any) => {
  const response = (await postX(
    'profile-api/profile',
    data,
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getCertificationData = async () => {
  const response = (await get(
    'profile-api/profile/certifications',
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getSpacesData = async () => {
  const response = (await get('profile-api/profile/spaces', {
    timeout: 600000,
  })) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const postRefreshSpaceThumbnail = async (spaceId: string) => {
  const response = (await postX('profile-api/refresh-space-thumbnail', {
    spaceId,
  })) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getOpenToWork = async () => {
  const response = (await get(
    'profile-api/profile/open-to-work',
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getProfileSettings = async () => {
  const response = (await get(
    'profile-api/profile-settings',
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const postProfileSettings = async (data: IUserProfileSettings) => {
  const response = (await postX(
    'profile-api/profile-settings',
    data,
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getInterests = async () => {
  const response = (await get(
    'profile-api/profile/interests',
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getAvailableInterests = async () => {
  const response = (await get(
    'profile-api/available-tags',
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getCompanies = async () => {
  const response = (await get(
    'profile-api/profile/companies',
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getSkills = async () => {
  const response = (await get(
    'profile-api/profile/skills',
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const setOpenToWork = async (openToWork: boolean) => {
  const payload = {
    openToWork: openToWork,
  };
  const response = (await postX(
    'profile-api/profile/open-to-work',
    payload,
  )) as unknown as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getPresignedUrlForCompanyLogo = async (
  fileName: string,
  contentType: string,
  categoty: string,
) => {
  const response = (await get(
    `profile-api/upload-presigned-url?fileName=` +
      fileName +
      `&contentType=` +
      contentType +
      `&category=` +
      categoty,
  )) as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getPresignedUrlForUpload = async (file: File) => {
  const payload = {
    fileName: file.name,
    contentType: file.type,
  };
  const response = (await postX(
    `resumeparser-api/presigned-url`,
    payload,
  )) as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getPresignedUrlForDownload = async (file: string) => {
  const response = (await get(
    `resumeparser-api/presigned-url?fileName=` + file,
  )) as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const uploadFileToS3 = async (presignedUrl: string, file: File) => {
  const options = {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  };
  const response = await fetch(presignedUrl, options);
  return response.ok;
};

export const checkNickAvailability = async (nick: string) => {
  const payload = {
    id: nick,
  };
  const response = (await postX(
    `public-profile-api/check-availability`,
    payload,
  )) as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const createPublicProfile = async (id: string, published: boolean) => {
  const payload = {
    id: id,
    published: published,
  };
  const response = (await postX(
    `public-profile-api/profile`,
    payload,
  )) as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const changePublicProfileVisibility = async (published: boolean) => {
  const payload = {
    published: published,
  };
  const response = (await postX(
    `public-profile-api/set-visibility`,
    payload,
  )) as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getPublicProfile = async () => {
  const response = (await get(`public-profile-api/profile`)) as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const migratePublicProfile = async () => {
  const payload = {};
  const response = (await postX(`profile-api/create`, payload)) as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getResumeParserState = async () => {
  const response = (await get(`resumeparser-api/state`)) as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};

export const getQuataForProfileSubmission = async () => {
  const response = (await get(`profile-api/limit`)) as IResponse;

  if (response.status !== 200) {
    return errors(response.status);
  }

  return response.data;
};
