import { getKaiApiUrl, getPathfinderApiUrl } from './pathsUtil';
import { get, post } from './fetchUtil';

interface UserKaiTokenBalance {
  depleted: boolean;
  balance: {
    tokens: number;
    subscriptionTokens: number;
    currentTokenQuota: number;
  };
}

/**
 * Fetch the W3S token wallet for current user
 * @returns
 */
export const fetchKaiTokenBalance = async (): Promise<UserKaiTokenBalance> => {
  const url = `${getPathfinderApiUrl()}/users-api/kai-tokens`;
  const {
    data: {
      depleted,
      balance: { tokens, subscriptionTokens, currentTokenQuota },
    },
  } = await get<{
    depleted: boolean;
    balance: {
      tokens: string;
      subscriptionTokens: string;
      currentTokenQuota: string;
    };
  }>(url);

  const [_tokens, _subscriptionTokens, _currentTokenQuota] = [
    tokens,
    subscriptionTokens,
    currentTokenQuota,
  ].map(parseFloat);

  return {
    depleted,
    balance: {
      tokens: isNaN(_tokens) ? 0 : _tokens,
      subscriptionTokens: isNaN(_subscriptionTokens) ? 0 : _subscriptionTokens,
      currentTokenQuota: isNaN(_currentTokenQuota) ? 0 : _currentTokenQuota,
    },
  };
};

/**
 * Fetch prices used to estimate usage
 * @returns
 */
export const fetchKaiPrices = () => {
  const url = `${getKaiApiUrl()}/prices-api/prices`;
  return get(url);
};

export const chat = async (prompt: string) => {
  const url = `${getKaiApiUrl()}/chat-api-v2/chat`;
  return await post(url, {
    context: 'admin-chat',
    prompt,
  });
};
