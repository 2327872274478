/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { getLoginUrl } from '../../SharedLib/Util/EnvironmentUtil';
import { messageForError } from '../../SharedLib/Util/ErrorUtil';
import {
  ErrorDisplay,
  ModalProps,
  QueryParam,
} from '../../SharedLib/Util/Interfaces';
import { getQueryParamNamed } from '../../SharedLib/Util/QueryParamsUtil';
import { authUtil as AuthUtil } from '../../SharedLib/Util/AuthUtil';
import Alert from '../../SharedLib/Component/Alert/Alert';
import Button from '../../SharedLib/Component/Buttons/Button/Button';
import Celebration from '../../SharedLib/Component/Icons/Celebration/Celebration';
import CloseIcon from '../../SharedLib/Component/Icons/CloseIcon/CloseIcon';
import LoadingModal from '../../SharedLib/Component/LoadingModal/LoadingModal';
import styles from './VerifyEmail.module.scss';
import { logDebug, logError, logging } from '../../SharedLib/Util/LoggingUtil';
import { onComponentDidMount } from '../../SharedLib/Util/ComponentUtil';
import LoadingBox from '../../SharedLib/Component/LoadingBox/LoadingBox';
import { SpinnerTypes } from '../../SharedLib/Component/Spinner/Spinner';
import { fetchProfile } from '../../SharedLib/Util/InternalProjects/ProfileUtil';
import {
  getUserSessionInfo,
  IUserSessionInfo,
  refreshUserInfoToken,
} from '../../SharedLib/Util/UserSessionUtil';
import { IOperationResult } from '../../SharedLib/Util/InterfaceAndTypeUtil';
import TopNavBar from '../../SharedLib/Component/TopNavBar/TopNavBar';
import Loading from '../Loading/Loading';
import { safeRedirect } from '../../SharedLib/Util/RedirectUtil';
import { useHistory } from 'react-router-dom';
import {
  getClassroomInvitationBrowserStorageMeta,
  IClassroomInvitationBrowserStorageMeta,
} from '../ClassroomInvitation/ClassroomInvitationUtil';

export interface IVerifyEmailModalProps extends ModalProps {
  startStatus?: string;
  startError?: ErrorDisplay;
}

function VerifyEmailModal({
  startStatus,
  full_page,
  show,
  close,
  startError,
}: IVerifyEmailModalProps) {
  const [status, setStatus] = useState(
    startStatus !== undefined ? startStatus : 'loading',
  );
  const [error, setError] = useState<ErrorDisplay | undefined>(
    startError ? startError : undefined,
  );

  const username = getQueryParamNamed('username');
  const confirmation_code = getQueryParamNamed('confirmation_code');
  const origin_kv = getQueryParamNamed('origin');

  const [loading, setLoading] = useState(true);
  const [classroomInvitationFetched, setClassroomInvitationFetched] =
    useState<boolean>(false);
  const [classroomInvitation, setClassroomInvitation] =
    useState<IClassroomInvitationBrowserStorageMeta>(
      {} as IClassroomInvitationBrowserStorageMeta,
    );
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const history = useHistory();

  const fetchClassroomInvitation = () => {
    const _classroomInvitation = getClassroomInvitationBrowserStorageMeta();
    logDebug(
      'VerifyEmail -> fetchClassroomInvitation -> classroomInvitation: ',
      _classroomInvitation,
    );

    if (_classroomInvitation !== null) {
      setClassroomInvitation(_classroomInvitation);
    }

    setClassroomInvitationFetched(true);
  };

  const loadUser = async () => {
    setUserIsLoggedIn(await AuthUtil.userIsLoggedIn());

    setLoading(false);
  };

  onComponentDidMount(() => {
    logging.logDebug('VerifyEmail -> componentDidMount');

    fetchClassroomInvitation();

    loadUser();
  });

  const paramValid = (param?: QueryParam) => {
    return (
      param !== undefined && param.value !== undefined && param.value.length > 0
    );
  };

  useEffect(() => {
    async function verifyEmail() {
      if (
        paramValid(username) &&
        paramValid(confirmation_code) &&
        paramValid(origin_kv)
      ) {
        const req_res = await fetchProfile<any>({
          method: 'POST',
          url: '/verify-email',
          data: {
            username: username!.value,
            confirmation_code: confirmation_code!.value,
            origin: origin_kv!.value,
          },
        });

        logging.logDebug('VerifyEmail -> req_res: ', req_res);

        if (req_res.error.code === '0') {
          setStatus('success');

          if (origin_kv!.value === 'change_email') {
            AuthUtil.updateCachedUserAttribute('email', req_res.data.email);

            await refreshUserInfoToken(); // update uic when email has changed
          }
        } else {
          setStatus('failed');
          setError(
            messageForError({
              code: req_res.error.code,
              msg: req_res.error.description as string,
            }),
          );
        }
      } else {
        setStatus('invalid_token');
        setError({
          msg: 'Sorry, we could not verify your email. Please check the URL and try again.',
          placement: 'general',
          variant: 'warning',
        });
      }
    }

    if (startStatus === undefined) {
      verifyEmail();
    }
  }, [setStatus]);

  const handleContinueOnboarding = useCallback(() => {
    if (!classroomInvitationFetched) {
      return;
    }

    if (classroomInvitation !== null) {
      try {
        logDebug(
          'VerifyEmail -> handleContinueOnboarding -> classroomInvitation: ',
          classroomInvitation,
        );

        const redirectUrlObj = new URL(classroomInvitation.url);

        // redirect to path with url query params
        history.push(redirectUrlObj.pathname + redirectUrlObj.search);

        return;
      } catch (error) {
        logError(
          'VerifyEmail -> handleContinueOnboarding -> classroomInvitationRedirect -> error: ',
          error,
        );
      }
    }

    safeRedirect(getLoginUrl()); // ultimately lands in pathfinder if session is ok
  }, [classroomInvitationFetched, classroomInvitation]);

  if (loading) {
    return (
      <LoadingBox
        position="fixed"
        top_displacement="auto"
        spinner_type={SpinnerTypes.RoundEven}
      />
    );
  }

  if (status === 'loading') {
    return (
      <LoadingModal
        show={true}
        close={() => {}}
        header="We’re verifying your email"
        message="Please wait..."
        full_page={full_page}
      />
    );
  }

  return (
    <div
      className={
        styles.modal +
        ' ' +
        (show ? styles.show : '') +
        ' ' +
        (full_page ? styles.full_page : '')
      }
    >
      <div className={styles.modal_inner}>
        <h1>{status === 'success' ? 'Done!' : 'Email verification failed'}</h1>

        {full_page ? (
          ''
        ) : (
          <CloseIcon
            size={30}
            onClick={close}
            className={styles.close_button}
          />
        )}

        {status === 'success' ? (
          <div>
            <div className={styles.celebration_wrapper}>
              <Celebration />
            </div>

            <div style={{ marginTop: '12px' }}>
              <Alert
                // @ts-ignore
                msg={'Success! Your email has been verified.'}
                type="success"
              />
            </div>
          </div>
        ) : (
          ''
        )}

        {error !== undefined && error.placement === 'general' ? (
          <div style={{ marginTop: '30px', marginBottom: '30px' }}>
            <Alert
              // @ts-ignore
              msg={error.msg}
              type={error.variant ? error.variant : 'warning'}
            />
          </div>
        ) : (
          <div className={styles.login_margin}></div>
        )}
        <div className={styles.cta_bottom_box}>
          {error === undefined ? (
            <Button
              variant="primary"
              fullwidth={true}
              disabled={!classroomInvitationFetched}
              onClick={handleContinueOnboarding}
              text="Continue"
            />
          ) : (
            <Button
              as="a"
              variant="primary"
              fullwidth={true}
              href={getLoginUrl()}
              text="Return to login"
            />
          )}
        </div>
      </div>
    </div>
  );
}

function VerifyEmail() {
  const [loading, setLoading] = useState(false);
  const [userSessionSoftLookupDone, setUserSessionSoftLookupDone] =
    useState(false);
  const [userSessionSoftLookupRes, setUserSessionSoftLookupRes] = useState<
    IOperationResult<IUserSessionInfo>
  >({
    error: {
      code: '0',
    },
    data: {
      sessionIsPresent: false,
      subscriptionPlan: 'free',
      noUpsell: false,
    },
  } as IOperationResult<IUserSessionInfo>);

  useEffect(() => {
    const userSessionSoftLookupRes = getUserSessionInfo();
    logDebug(
      'VerifyEmail -> init -> userSessionSoftLookupRes: ',
      userSessionSoftLookupRes,
    );

    setUserSessionSoftLookupRes(userSessionSoftLookupRes);

    setUserSessionSoftLookupDone(true);

    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <TopNavBar
            zIndex={999999}
            userSessionProcessed={userSessionSoftLookupDone} // actual validation / refreshing is done in the inner components
            userSessionMeta={{
              loggedIn: userSessionSoftLookupRes.data.sessionIsPresent!,
              subscriptionPlan: userSessionSoftLookupRes.data.subscriptionPlan,
              featureFlags: {
                noUpsell: userSessionSoftLookupRes.data.noUpsell,
              },
            }}
          />

          <VerifyEmailModal
            show={true}
            close={() => {
              window.history.back();
            }}
            full_page={true}
          />
        </>
      )}
    </>
  );
}

export default VerifyEmail;
