import React, { useEffect } from 'react';
import LoginFullPage from '../LoginFullPageNew/LoginFullPageNew';

function Login() {
  useEffect(() => {
    document.title = 'Log in - W3Schools';
  });

  return (
    <>
      <LoginFullPage />
    </>
  );
}
export default Login;
