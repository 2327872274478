export type StrKeyToAnyMapType = Record<string, any>;

export const currentLogLevel = (): number => {
  if (typeof window !== 'undefined') {
    // to ensure it is run on client side only
    let level = localStorage.getItem('logLevel');

    if (typeof level === 'undefined' || level === null) {
      level = localStorage.getItem('level');
    }

    switch (level) {
      case 'info':
        return 20;
      case 'debug':
        return 10;
      default:
        return 40;
    }
  }

  return 40;
};

export const _logBuffer: StrKeyToAnyMapType = {};

type LogBufferHookType = (strToLog: string) => string;

let _logBufferHook: LogBufferHookType | null = null;

export const setLogBufferHook = (hook: LogBufferHookType) => {
  _logBufferHook = hook;
};

export const clearLogBufferHook = () => {
  _logBufferHook = null;
};

export const flushLogBufferToStorage = (context: string) => {
  if (typeof _logBuffer[context] === 'undefined') {
    return;
  }

  try {
    let logsToFlush = _logBuffer[context].logs;

    if (_logBuffer[context].flushed) {
      const flushedLogsStr = sessionStorage.getItem(`log.${context}`);

      if (typeof flushedLogsStr !== 'undefined' && flushedLogsStr !== null) {
        const flushedLogs = JSON.parse(flushedLogsStr);

        if (
          Object.prototype.toString.call(flushedLogs) === '[object Array]' &&
          flushedLogs.length
        ) {
          logsToFlush = flushedLogs.concat(_logBuffer[context].logs);
        }
      }
    }

    sessionStorage.setItem(`log.${context}`, JSON.stringify(logsToFlush));

    _logBuffer[context].flushed = true;
    _logBuffer[context].logs = [];
  } catch (_exc) {
    const exc = _exc as any;
    logError('flushLogBufferToStorage -> exc: ', exc);
  }
};

export const pushToLogBuffer = (
  context: string,
  logLevel: string,
  message?: any,
  data?: any,
) => {
  if (typeof _logBuffer[context] === 'undefined') {
    _logBuffer[context] = {
      flushed: false,
      logs: [],
    };
  }

  let log = { logLevel, message, data };

  if (_logBufferHook !== null) {
    log = JSON.parse(_logBufferHook(JSON.stringify(log)));
  }

  _logBuffer[context].logs.push(log);

  if (_logBuffer[context].flushed) {
    flushLogBufferToStorage(context);
  }
};

export let _logBufferForwardContext = ''; // logs made under this context will be forwarded to the log buffer

export const intializeLogBufferForwarding = (context: string) => {
  if (_logBufferForwardContext) {
    flushLogBufferToStorage(_logBufferForwardContext);
  }

  _logBufferForwardContext = context;
};

export const finishLogBufferForwarding = (context?: string) => {
  if (_logBufferForwardContext) {
    flushLogBufferToStorage(_logBufferForwardContext);
  }

  if (typeof context !== 'undefined' && context !== _logBufferForwardContext) {
    flushLogBufferToStorage(context);
  }

  _logBufferForwardContext = '';
};

export const clearLogsFromStorage = (context: string) => {
  sessionStorage.removeItem(`log.${context}`);
};

export const bufferlogDebug = (context: string, message?: any, data?: any) => {
  if (currentLogLevel() <= 10) {
    console.log(message, data);
  }

  pushToLogBuffer(context, 'debug', message, data);
};

export const logDebug = (message?: any, ...optionalParams: any[]) => {
  if (currentLogLevel() <= 10) {
    console.log(message, optionalParams);
  }

  if (_logBufferForwardContext) {
    pushToLogBuffer(
      _logBufferForwardContext,
      'debug',
      message,
      optionalParams.length ? optionalParams : undefined,
    );
  }
};

export const logDebugAlt = (
  message?: any,
  data?: any,
  bypassForwarding?: boolean,
) => {
  if (currentLogLevel() <= 10) {
    console.log(message, data);
  }

  if (typeof bypassForwarding === 'undefined') {
    bypassForwarding = false;
  }

  if (_logBufferForwardContext && !bypassForwarding) {
    pushToLogBuffer(_logBufferForwardContext, 'debug', message, data);
  }
};

export const logInfo = (message?: any, ...optionalParams: any[]) => {
  if (currentLogLevel() <= 20) {
    console.log(message, optionalParams);
  }

  if (_logBufferForwardContext) {
    pushToLogBuffer(
      _logBufferForwardContext,
      'info',
      message,
      optionalParams.length ? optionalParams : undefined,
    );
  }
};

export const logAll = (message?: any, ...optionalParams: any[]) => {
  if (currentLogLevel() <= 40) {
    console.log(message, optionalParams);
  }

  if (_logBufferForwardContext) {
    pushToLogBuffer(
      _logBufferForwardContext,
      'all',
      message,
      optionalParams.length ? optionalParams : undefined,
    );
  }
};

export const logError = (message?: any, ...optionalParams: any[]) => {
  console.error(message, optionalParams);

  if (_logBufferForwardContext) {
    pushToLogBuffer(
      _logBufferForwardContext,
      'error',
      message,
      optionalParams.length ? optionalParams : undefined,
    );
  }
};

export const log = (message: string, level: number) => {
  if (currentLogLevel() <= level) {
    console.log(message);
  }

  if (_logBufferForwardContext) {
    pushToLogBuffer(_logBufferForwardContext, `${level}`, message);
  }
};

export const loggingAll = {
  logError: logError,
  logDebug: logDebug,
  logDebugAlt: logDebugAlt,
  logInfo: logInfo,
  logAll: logAll,
  log: log,
  pushToLogBuffer: pushToLogBuffer,
  flushLogBufferToStorage: flushLogBufferToStorage,
  intializeLogBufferForwarding: intializeLogBufferForwarding,
  finishLogBufferForwarding: finishLogBufferForwarding,
  clearLogsFromStorage: clearLogsFromStorage,
  bufferlogDebug: bufferlogDebug,
  setLogBufferHook: setLogBufferHook,
  clearLogBufferHook: clearLogBufferHook,
  currentLogLevel: currentLogLevel,
};

export const logging = {
  logError: logError,
  logDebug: logDebug,
  logInfo: logInfo,
  logAll: logAll,
  log: log,
};
