import React from 'react';

export const AbsolventHatSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="96"
    height="67"
    viewBox="0 0 96 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M80.3997 43.8785L64.3708 49.3503C53.7065 52.9909 42.3509 52.9909 31.6866 49.3503L15.6577 43.8785L23.4057 12.3887H72.6445L80.3925 43.8785H80.3997Z"
      fill="#9763F6"
      stroke="#282A35"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.9171 16.5989L48.0322 6.48438L16.1473 16.5989H1.9834V21.0902L48.0322 35.6888L94.081 21.0902V16.5989H79.9171Z"
      fill="#282A35"
      stroke="#282A35"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.0322 2L1.9834 16.5986L48.0322 31.1973L94.081 16.5986L48.0322 2Z"
      fill="#9763F6"
      stroke="#282A35"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7957 39.2723L31.6868 44.3548C42.3511 47.9955 53.7067 47.9955 64.371 44.3548L79.2622 39.2723L78.0668 34.4277L64.3638 39.1065C53.6995 42.7472 42.3439 42.7472 31.6796 39.1065L17.9766 34.4277L16.7812 39.2723H16.7957Z"
      fill="#FFC0C7"
      stroke="#282A35"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.0322 16.5996L69.6345 24.3495V55.8465"
      stroke="#282A35"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.6345 56.3944C71.3088 56.3944 72.6661 55.0355 72.6661 53.3593C72.6661 51.6831 71.3088 50.3242 69.6345 50.3242C67.9603 50.3242 66.603 51.6831 66.603 53.3593C66.603 55.0355 67.9603 56.3944 69.6345 56.3944Z"
      fill="#282A35"
      stroke="#282A35"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.6345 53.3594C69.6345 53.3594 65.1484 57.4182 65.1484 64.9013H74.1206C74.1206 57.4182 69.6345 53.3594 69.6345 53.3594Z"
      fill="#282A35"
      stroke="#282A35"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
