import React from 'react';
import { Box, Progress, ProgressProps } from '@chakra-ui/react';

interface GradientProgressProps extends ProgressProps {
  filledGradient: string;
  backgroundGradient: string;
  borderRadius: string | number;
  hatched?: boolean;
  value: number;
}

const GradientProgress: React.FC<GradientProgressProps> = ({
  filledGradient,
  backgroundGradient,
  borderRadius,
  hatched = false,
  value,
  ...props
}) => {
  const progressContainerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Box
      position="relative"
      border={hatched ? '1px solid rgba(40, 40, 40, 0.6)' : ''}
      borderRadius={borderRadius}
      overflow="hidden"
      ref={progressContainerRef}
    >
      <Progress
        value={value}
        {...props}
        borderRadius={borderRadius}
        margin={hatched ? '-1px' : ''}
        sx={{
          // Background of the unfilled part
          background: backgroundGradient,
          backdropFilter: 'blur(133.764px)',
          '& > div': {
            // Filled part gradient
            backgroundImage: filledGradient,
            borderRadius: borderRadius,
            border:
              hatched && value && value > 0
                ? '1px solid rgba(40, 40, 40, 0.4)'
                : '',
          },
        }}
      />
    </Box>
  );
};

export default GradientProgress;
