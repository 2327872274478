import { authUtil } from '../SharedLib/Util/AuthUtil';
import { getPathfinderUrl } from '../SharedLib/Util/EnvironmentUtil';
import { getQueryParamNamed } from '../SharedLib/Util/QueryParamsUtil';
import {
  getSanitizedRedirectUrl,
  safeRedirect,
} from '../SharedLib/Util/RedirectUtil';
import { useHistory } from 'react-router-dom';
import { logging } from '../SharedLib';
import {
  clearClassroomInvitationBrowserStorageMeta,
  getClassroomInvitationBrowserStorageMeta,
} from '../Component/ClassroomInvitation/ClassroomInvitationUtil';
import { fetchProfile } from '../SharedLib/Util/InternalProjects/ProfileUtil';
import { pollSubscriptionAndRefreshUserInfoToken } from './utils';

const DEFAULT_REDIRECT_URL = getPathfinderUrl();

const getRedirectUrl = (redirectUrl: string | undefined) => {
  let redirectUrlToUse = redirectUrl;

  if (redirectUrlToUse === undefined) {
    redirectUrlToUse = getSanitizedRedirectUrl();
  }
  if (redirectUrlToUse === undefined) {
    redirectUrlToUse = DEFAULT_REDIRECT_URL;
  }

  const auth_code = getQueryParamNamed('authorization_code') || {
    value: sessionStorage.getItem('authorization_code'),
  };
  const auth_state = getQueryParamNamed('state') || {
    value: sessionStorage.getItem('state'),
  };

  if (auth_code !== undefined && auth_code?.value) {
    if (auth_state !== undefined && auth_state?.value) {
      return `${redirectUrlToUse}?code=${auth_code.value}&state=${auth_state.value}`;
    } else {
      return `${redirectUrlToUse}?code=${auth_code.value}`;
    }
  }

  return redirectUrlToUse;
};

export async function handleLoggedIn(history: ReturnType<typeof useHistory>) {
  const auth_code = getQueryParamNamed('authorization_code') || {
    value: sessionStorage.getItem('authorization_code'),
  };

  if (auth_code.value) {
    await authUtil.storeTokens(auth_code.value);
  }

  const user = await authUtil.getCurrentUser();
  const userAttributes = user.attributes;

  logging.logDebug('handleLoggedIn -> user: ', user);

  const classroomInvitation = getClassroomInvitationBrowserStorageMeta();
  logging.logDebug(
    'handleLoggedIn -> classroomInvitation: ',
    classroomInvitation,
  );

  if (classroomInvitation !== null) {
    if (userAttributes['custom:isFirstLogin'] === 'true') {
      const unsetIsFirstLoginRes = await fetchProfile({
        method: 'PATCH',
        url: '/user/unset-is-first-login',
      });

      logging.logDebug(
        'handleLoggedIn -> unsetIsFirstLoginRes: ',
        unsetIsFirstLoginRes,
      );
    }

    clearClassroomInvitationBrowserStorageMeta();

    sessionStorage.clear(); // Clear session here so that the next login in same browser session dosen't redirect

    return safeRedirect(classroomInvitation.url);
  }

  if (
    userAttributes['custom:isFirstLogin'] === 'true' &&
    !user.username.includes('Feide')
  ) {
    return history.push('/plan-selection');
  }

  if (user.username.includes('Feide')) {
    await pollSubscriptionAndRefreshUserInfoToken();
  }

  if (userAttributes['custom:isFirstLogin'] === 'true') {
    const unsetIsFirstLoginRes = await fetchProfile({
      method: 'PATCH',
      url: '/user/unset-is-first-login',
    });

    logging.logDebug(
      'handleLoggedIn -> unsetIsFirstLoginRes: ',
      unsetIsFirstLoginRes,
    );
  }

  const redirectUrl = getSanitizedRedirectUrl();

  if (userAttributes['custom:showFeideWelcomeMsg'] === 'true' && !redirectUrl) {
    return safeRedirect(getPathfinderUrl() + '?showFeideWelcomeMsg=true');
  }

  const _redirectUrl = getRedirectUrl(redirectUrl);

  sessionStorage.clear(); // Clear session here so that the next login in same browser session dosen't redirect

  safeRedirect(_redirectUrl);
}
