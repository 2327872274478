import { getMyLearningApiUrl, getUserSkillsUrl } from './pathsUtil';
import { get, post } from './fetchUtil';
import { logging } from './LoggingUtil';
import type {
  ErrorType,
  ISkill,
  SkillsWithLessons,
} from '../Component/userlib_new/utils/types-interfaces';

enum MyLearningMaterial {
  LESSON = 'lesson',
  QUIZ = 'quiz',
  EXERCISE = 'exercise',
}

type MyLearning = Record<MyLearningMaterial, { score: number; xp?: number }>;

interface Certificates {
  score: number;
  maxScore: number;
  passingThreshold: number;
}
interface Assessment {
  score: number;
  reliability: number;
}

type UserSkills = Record<
  string,
  {
    level: number;
    mylearning?: MyLearning;
    certificates?: Certificates;
    assessment?: Assessment;
    name?: string;
  }
>;

interface GetMySkillsResponse {
  userSkills: UserSkills;
  mylMigrated: boolean;
  certMigrated: boolean;
}

export const getMySkills = async (): Promise<{
  userSkills: ISkill[];
  mylMigrated: boolean;
  certMigrated: boolean;
}> => {
  const url = `${getUserSkillsUrl()}/competency-api/myskills`;

  const {
    data: { userSkills: _userSkills, ...rest },
  } = await get<GetMySkillsResponse>(url);

  const userSkills = Object.entries(_userSkills).map<ISkill>(
    ([id, { name = '', level, assessment }]) => ({
      id,
      name,
      level,
      type: 'skill',
      assessment,
    }),
  );

  const transformed = {
    ...rest,
    userSkills,
  };

  logging.logDebug('getMySkills -> transformResponseData:', transformed);

  return transformed;
};

export const getAllAvailableSkillsAndSubskills = async (): Promise<
  ISkill[]
> => {
  const url = `${getUserSkillsUrl()}/competency-api/skills`;
  const { data } = await get<ISkill[]>(url);
  return data;
};

export const convertMyLearning = async () => {
  try {
    const { data } = await post<{ isNewUser: boolean }>(
      `${getUserSkillsUrl()}/competency-api/sync-mylearning`,
    );

    return data;
  } catch (error) {
    logging.logError(error);
  }
};

export const convertCertificates = async () => {
  try {
    const { data } = await post<{
      userHasCertificates: boolean;
    }>(`${getUserSkillsUrl()}/competency-api/sync-certificates`);
    return data;
  } catch (error) {
    logging.logError(error);
  }
};

export const fetchSkillsWithLessons = async (): Promise<SkillsWithLessons> => {
  const {
    data: { data, error },
  } = await get<{ data: SkillsWithLessons; error: ErrorType }>(
    `${getMyLearningApiUrl()}/api/pathfinder/struct/skills-with-lessons`,
    { format: 'map' },
  );

  if (error.code !== '0') {
    throw new Error(error.description ?? 'Error fetching skills with lessons');
  }

  return data;
};
