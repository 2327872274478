import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';

interface confirmProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  dailyQuataTotal: number;
  dailyQuataRemain: number;
}

const ConfirmProfileSubmission = ({
  isOpen,
  onClose,
  onContinue,
  dailyQuataTotal,
  dailyQuataRemain,
}: confirmProps) => {
  const usedLimit = dailyQuataTotal - dailyQuataRemain;
  const isQuotaExhausted = dailyQuataRemain === 0;
  const quotaMessage =
    usedLimit === 0
      ? 'You have not used any of your daily allocation today.'
      : `As of now, you have utilized ${usedLimit} out of your ${dailyQuataTotal} daily update opportunities.`;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Your Profile Updates</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Text>
                <strong>Daily Update Allocation:</strong> Your account permits
                up to {dailyQuataTotal} profile updates each day.
              </Text>
              <Text>
                <strong>Current Usage:</strong> {quotaMessage}
              </Text>
            </Box>

            {!isQuotaExhausted && (
              <Text mb={4}>
                By proceeding to save, you will be utilizing one of your
                remaining daily update allocations
              </Text>
            )}

            <Text mb={4} fontSize="xs">
              Note: Your update quota will refresh every 24 hours. This helps us
              maintain optimal performance and service quality for all users.
            </Text>

            {!isQuotaExhausted && (
              <Text>Would you like to proceed with saving your changes?</Text>
            )}
            {isQuotaExhausted && (
              <Text>
                Your daily quota is exhausted. Please try again after the quota
                refreshes.
              </Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose} size="md">
              Close
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              size="md"
              onClick={onContinue}
              isDisabled={isQuotaExhausted}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmProfileSubmission;
