import axios from 'axios';
import { getEnv } from './environment';
import { getCookie } from 'cookies-next';
import { getPathfinderApiUrl } from './pathsUtil';

export interface IEnvs {
  [key: string]: string;
  dev: string;
  stage: string;
  prod: string;
}

export const errors = (statusCode: number) => {
  switch (statusCode) {
    case 401:
      return 'Unauthorized';
    case 404:
      return 'Not Found';
    case 500:
    default:
      return 'Internal Server Error';
  }
};

const getApiEndpoint = () => {
  const env = getEnv();
  return getPathfinderApiUrl(env);
};

export const getAccessToken = () => {
  const myAT: string | undefined = getCookie('accessToken');
  return myAT;
};

// export const baseUrl = `${getApiEndpoint()}`;

export const get = async (route: string, opts: { timeout?: number } = {}) => {
  const accessToken = getAccessToken();
  const baseUrl = getApiEndpoint();
  if (!accessToken) {
    return {
      statusCode: 401,
      body: 'Unauthorized',
    };
  }

  const request = await axios.get(baseUrl + '/' + route, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      // 'Content-Type': 'application/json',
    },
    ...opts,
  });
  return request;
};

export const post = async (route: string, data: any) => {
  const accessToken = getAccessToken();
  const baseUrl = getApiEndpoint();
  if (!accessToken) {
    return {
      statusCode: 401,
      body: 'Unauthorized',
    };
  }

  const request = axios.post(baseUrl + '/' + route, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

  return request;
};

export const put = async (route: string, data: any) => {
  const accessToken = getAccessToken();
  const baseUrl = `${getApiEndpoint()}`;
  if (!accessToken) {
    return {
      statusCode: 401,
      body: 'Unauthorized',
    };
  }

  const request = axios.put(baseUrl + '/' + route, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

  return request;
};

export const del = async (route: string) => {
  const accessToken = getAccessToken();
  const baseUrl = `${getApiEndpoint()}`;
  if (!accessToken) {
    return {
      statusCode: 401,
      body: 'Unauthorized',
    };
  }

  const request = axios.delete(baseUrl + '/' + route, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

  return request;
};

export const getBatchedList = <T>({
  list,
  batchSize,
}: {
  list: T[];
  batchSize: number;
}): T[][] => {
  const batches: T[][] = [];

  for (let i = 0; i < list.length; i += batchSize) {
    batches.push(list.slice(i, i + batchSize));
  }

  console.log('getBatchedList -> batches: ', batches);

  return batches;
};
