import React from 'react';

export interface IExternalLinkProps {
  size: number;
  className?: string;
}

const ExternalLink = ({ size, className }: IExternalLinkProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.5 9.5H13C12.8674 9.5 12.7402 9.55268 12.6464 9.64645C12.5527 9.74021 12.5 9.86739 12.5 10V14.8125C12.5 14.8622 12.4802 14.9099 12.4451 14.9451C12.4099 14.9802 12.3622 15 12.3125 15H1.6875C1.63777 15 1.59008 14.9802 1.55492 14.9451C1.51975 14.9099 1.5 14.8622 1.5 14.8125V4.1875C1.5 4.13777 1.51975 4.09008 1.55492 4.05492C1.59008 4.01975 1.63777 4 1.6875 4H6.5C6.63261 4 6.75979 3.94732 6.85355 3.85355C6.94732 3.75979 7 3.63261 7 3.5V3C7 2.86739 6.94732 2.74021 6.85355 2.64645C6.75979 2.55268 6.63261 2.5 6.5 2.5H1.5C1.10218 2.5 0.720644 2.65804 0.43934 2.93934C0.158035 3.22064 0 3.60218 0 4L0 15C0 15.3978 0.158035 15.7794 0.43934 16.0607C0.720644 16.342 1.10218 16.5 1.5 16.5H12.5C12.8978 16.5 13.2794 16.342 13.5607 16.0607C13.842 15.7794 14 15.3978 14 15V10C14 9.86739 13.9473 9.74021 13.8536 9.64645C13.7598 9.55268 13.6326 9.5 13.5 9.5ZM15.625 0.5H11.375C11.3009 0.500065 11.2284 0.522094 11.1668 0.563304C11.1052 0.604514 11.0572 0.663057 11.0289 0.731538C11.0005 0.80002 10.9931 0.875369 11.0075 0.948068C11.022 1.02077 11.0576 1.08756 11.11 1.14L12.6159 2.64656L4.10969 11.1528C4.07473 11.1877 4.04699 11.229 4.02806 11.2746C4.00913 11.3202 3.99939 11.3691 3.99939 11.4184C3.99939 11.4678 4.00913 11.5167 4.02806 11.5622C4.04699 11.6078 4.07473 11.6492 4.10969 11.6841L4.81687 12.3912C4.85171 12.4262 4.89311 12.4539 4.93869 12.4729C4.98427 12.4918 5.03314 12.5015 5.0825 12.5015C5.13186 12.5015 5.18073 12.4918 5.22631 12.4729C5.27189 12.4539 5.31329 12.4262 5.34812 12.3912L13.8544 3.88469L15.3609 5.39094C15.4135 5.44305 15.4803 5.47845 15.5529 5.49268C15.6255 5.50691 15.7007 5.49933 15.7691 5.47089C15.8374 5.44246 15.8958 5.39444 15.9369 5.33289C15.978 5.27134 15.9999 5.19901 16 5.125V0.875C16 0.775544 15.9605 0.680161 15.8902 0.609835C15.8198 0.539509 15.7245 0.5 15.625 0.5V0.5Z" />
  </svg>
);

ExternalLink.defaultProps = {
  className: undefined,
};
export default ExternalLink;
