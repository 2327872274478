import { IFetchPrs, fetch } from '../SharedLib';
import {
  getSubscriptionPlan,
  refreshUserInfoToken,
} from '../SharedLib/Util/UserSessionUtil';

const timeout = 30_000;
export const post = async <T = unknown>(
  url: string,
  data: unknown,
): Promise<T> => {
  const prs: IFetchPrs = {
    url,
    method: 'POST',
    data,
    timeout,
  };

  const res = await fetch<T>(prs);

  if (res.error.code !== '0') {
    throw new Error(res.error.description ?? 'Unknown error');
  }

  return res.data;
};

export async function pollForSubscription(
  interval: number,
  maxAttempts: number,
) {
  let attempts = 0;

  do {
    const subscriptionPlan = await getSubscriptionPlan();

    if (subscriptionPlan !== 'free') {
      return true;
    }

    attempts++;

    await new Promise((resolve) => setTimeout(resolve, interval));
  } while (attempts < maxAttempts);

  return false;
}

export async function pollSubscriptionAndRefreshUserInfoToken() {
  await pollForSubscription(1000, 3);

  await refreshUserInfoToken();
}
