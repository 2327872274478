import React from 'react';
import {
  Box,
  Text,
  VStack,
  useColorModeValue,
  Link,
  Flex,
  Icon,
  Switch,
} from '@chakra-ui/react';
import { ISkill } from '../../../userlib_new/utils/profile-interfaces';
import { AccordionSection } from './AccordionSection';
import GradientProgress from '../../../Goals/GradientProgress';
import { getSkillIcon } from '../../../../lib/skillIconMap';
import { useProfile } from '../../../../context/profileProvider';

interface ISkillsProps {
  filteredSkills: ISkill[];
}

export const Skills = ({ filteredSkills }: ISkillsProps) => {
  const profileData = useProfile();
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const minSkillNameWidth = ['80px', '150px'];
  return (
    <AccordionSection title="Skills" sectionKey="skills">
      <VStack spacing={4} width="full">
        {filteredSkills.length > 0 ? (
          filteredSkills.map((skill, index) => (
            <Box
              width="full"
              key={index}
              borderWidth="1px"
              borderColor={borderColor}
              borderRadius="lg"
              boxShadow="md"
              p={3}
              my={1}
              _dark={{ bg: '#3E4252' }}
            >
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                align="center"
              >
                <Text
                  minWidth={minSkillNameWidth}
                  fontSize={['12px', '14px']}
                  fontWeight="900"
                  textAlign={{ base: 'center', md: 'left' }}
                  mb={{ base: 3, md: 0 }}
                >
                  <Icon
                    as={getSkillIcon(skill.id)}
                    aria-label={`${skill.name}`}
                    mr={2}
                  />
                  {skill.name}
                </Text>
                <Box position="relative" width="full" flex="1">
                  <GradientProgress
                    value={skill.level ?? 0}
                    h="16px"
                    filledGradient="linear-gradient(270deg, #77CA97 2.96%, #018A57 96.87%)"
                    backgroundGradient="linear-gradient(270deg, #E7FBEE 0.19%, rgba(204, 204, 204, 0.35) 92.98%)"
                    borderRadius="16px"
                  />

                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    px={2}
                    height="16px"
                  >
                    <Box
                      position="absolute"
                      top="50%"
                      left="0"
                      transform="translateY(-50%)"
                      w="full"
                      h="2px"
                      bgColor="gray.300"
                      zIndex="-1"
                    />
                    <Box
                      position="absolute"
                      top="50%"
                      left="25%"
                      transform="translateY(-50%)"
                      w="2px"
                      h="18px"
                      bgColor="gray.300"
                    />
                    <Box
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translateY(-50%)"
                      w="2px"
                      h="14px"
                      bgColor="gray.300"
                    />
                    <Box
                      position="absolute"
                      top="50%"
                      left="75%"
                      transform="translateY(-50%)"
                      w="2px"
                      h="14px"
                      bgColor="gray.300"
                    />
                  </Box>
                  <Flex
                    position="absolute"
                    bottom="0"
                    w="full"
                    justify="space-between"
                    pt={2}
                  >
                    <Text fontSize={['10px', '12px']}></Text>
                    <Text
                      fontSize={['10px', '12px']}
                      display={{ base: 'none', md: 'block' }}
                      position="absolute"
                      left="25%"
                      transform="translateX(-50%)"
                    >
                      Beginner
                    </Text>
                    <Text
                      fontSize={['10px', '12px']}
                      display={{ base: 'none', md: 'block' }}
                      position="absolute"
                      left="50%"
                      transform="translateX(-50%)"
                    >
                      Intermediate
                    </Text>
                    <Text
                      fontSize={['10px', '12px']}
                      display={{ base: 'none', md: 'block' }}
                      position="absolute"
                      left="75%"
                      transform="translateX(-50%)"
                    >
                      Advanced
                    </Text>
                    <Text
                      fontSize={['10px', '12px']}
                      display={{ base: 'none', md: 'block' }}
                      position="absolute"
                      right="0%"
                      mr="-12"
                      transform="translateX(-50%)"
                    >
                      Professional
                    </Text>

                    {/* Abbreviations for smaller screens */}
                    <Text
                      fontSize="10px"
                      display={{ base: 'block', md: 'none' }}
                      position="absolute"
                      left="25%"
                      transform="translateX(-50%)"
                    >
                      25%
                    </Text>
                    <Text
                      fontSize="10px"
                      display={{ base: 'block', md: 'none' }}
                      position="absolute"
                      left="50%"
                      transform="translateX(-50%)"
                    >
                      50%
                    </Text>
                    <Text
                      fontSize="10px"
                      display={{ base: 'block', md: 'none' }}
                      position="absolute"
                      left="75%"
                      transform="translateX(-50%)"
                    >
                      75%
                    </Text>
                    <Text
                      fontSize="10px"
                      display={{ base: 'block', md: 'none' }}
                      position="absolute"
                      right="0%"
                      mr={-2}
                      transform="translateX(-50%)"
                    >
                      100%
                    </Text>
                  </Flex>
                </Box>

                <Flex
                  direction={{ base: 'row', md: 'column' }}
                  justify={{ base: 'flex-end', md: 'normal' }}
                  mt={{ base: 4, md: 0 }}
                  ml={6}
                >
                  <Switch
                    id={`skill-switch-${index}`}
                    isChecked={skill.visible ?? false}
                    colorScheme="green"
                    onChange={(e) =>
                      profileData.handleSkillsChange(index, e.target.checked)
                    }
                  />
                  <Link
                    colorScheme="blue"
                    fontSize={14}
                    mt={{ base: 0, md: 3 }}
                    ml={{ base: 2, md: 0 }}
                    href={`/skills#` + skill.id}
                    isExternal
                  >
                    View
                  </Link>
                </Flex>
              </Flex>
            </Box>
          ))
        ) : (
          <Text alignSelf="flex-start">No skills available.</Text>
        )}
      </VStack>
    </AccordionSection>
  );
};
