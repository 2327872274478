import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import type { InternalAxiosRequestConfig } from 'axios';
import { getAccessToken } from './utilsEx';
import { getProfileUrl } from './pathsUtil';

export interface IBaseFetchData {
  data?: any;
  params?: any;
}

const tokenInterceptor = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    throw {
      statusCode: 401,
      body: 'Unauthorized',
      message: 'Unauthorized',
    };
  }

  const { headers } = config;

  headers.set('Authorization', `Bearer ${accessToken}`);

  if (headers.get('x-access-token')) {
    headers.set('x-access-token', accessToken);
  }

  return config;
};

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(tokenInterceptor);

/**
 * Axios get call with headers and data set
 * @param url
 * @param data
 * @returns
 */
export const get = <T = any>(
  url: string,
  params: Record<string, unknown> = {},
  headers: RawAxiosRequestHeaders = {},
) => axiosInstance.get<T>(url, { params, headers });

/**
 * Axios put call with headers and data set
 * @param url
 * @param data
 * @returns
 */
export const put = <T = any>(url: string, data?: any) =>
  axiosInstance.put<T>(url, data?.data || data || undefined);

/**
 * Axios post call with headers and data set
 * @param url
 * @param data
 * @returns
 */
export const post = <T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
) => axiosInstance.post<T>(url, data, config);

export const del = (url: string) => axiosInstance.delete(url);

/**
 * Axios patch call with headers and data set
 * @param url
 * @param data
 */

export const patch = <T = any, D = any>(url: string, data?: D) =>
  axiosInstance.patch<T>(url, data);

/**
 * Axios patch call to profile
 * @param url
 * @param data
 */
export const patchProfile = <T = any, D = any>(url: string, data?: D) =>
  axiosInstance.patch<T>(getProfileUrl() + url, data, {
    headers: {
      'x-access-token': 'x-access-token',
    },
  });
