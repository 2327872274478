import { v4 } from 'uuid';
import { ICountry, countries, languages } from 'countries-list';
import {
  FilterType,
  ILanguages,
} from '../Component/userlib_new/utils/profile-interfaces';

const getAllCountries = (): FilterType[] => {
  const myData = Object.entries(countries);
  const returnData = myData.map((itemPair) => {
    return {
      id: v4(),
      title: itemPair[1].name,
      value: itemPair[0],
      extra: {
        continent: itemPair[1].continent,
        continents: itemPair[1].continents,
      },
    };
  });

  return returnData;
};

export interface CountryDataType {
  country: Record<string, ICountry>;
}

export const getLanguageList = (): ILanguages =>
  Object.fromEntries(
    Object.entries(languages).map(([code, { name }]) => [code, name]),
  );

export const getCountriesByContinents = (): any => {
  const countriesData = Object.entries(countries);
  const continents: any = {};

  for (const countryEntry of countriesData) {
    const countryCode = countryEntry[0];
    const country = countryEntry[1];
    const continentCode = country.continent;

    if (!continents[continentCode]) {
      continents[continentCode] = {} as Record<string, ICountry>;
    }

    continents[continentCode][countryCode] = { ...country };
  }

  const updatedContinents: any = {};
  for (const continentCode in continents) {
    updatedContinents[continentCode] = {};
    for (const countryCode in continents[continentCode]) {
      updatedContinents[continentCode][countryCode] =
        continents[continentCode][countryCode][1];
    }
  }

  return continents;
};

export const COUNTRY_DATA: FilterType[] = [...getAllCountries()];
