/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect } from 'react';
import W3LogoButton from '../../SharedLib/Component/Buttons/W3LogoButton/W3LogoButton';
import LoadingModal from '../../SharedLib/Component/LoadingModal/LoadingModal';
import { authUtil } from '../../SharedLib/Util/AuthUtil';
import { setUserSessionRefreshFlag } from '../../SharedLib/Util/UserSessionUtil';
import {
  getLoginUrl,
  getProfileSettingsUrl,
} from '../../SharedLib/Util/EnvironmentUtil';
import { logging } from '../../SharedLib/Util/LoggingUtil';
import {
  getSanitizedRedirectUrl,
  safeRedirect,
} from '../../SharedLib/Util/RedirectUtil';

function RefreshSessionPage() {
  useEffect(() => {
    // on init set cookie refresh flag
    setUserSessionRefreshFlag({
      context: 'RefreshSessionPage',
    });

    async function processRefreshSessionAttempt() {
      const userIsLoggedIn = await authUtil.userIsLoggedIn(); // this function call will also refresh the session when possible

      let redirectUrl = getSanitizedRedirectUrl();
      logging.logDebug(
        'RefreshSessionPage -> getSanitizedRedirectUrl(): ',
        redirectUrl,
      );

      if (typeof redirectUrl === 'undefined') {
        redirectUrl = '';
      }

      if (userIsLoggedIn) {
        if (!redirectUrl) {
          redirectUrl = getProfileSettingsUrl();
        }
      } else {
        if (redirectUrl) {
          redirectUrl = `${getLoginUrl()}?redirect_url=${encodeURIComponent(redirectUrl)}`;
        } else {
          redirectUrl = getLoginUrl();
        }
      }

      logging.logDebug('RefreshSessionPage -> redirectUrl: ', redirectUrl);

      safeRedirect(redirectUrl);
    }

    processRefreshSessionAttempt();
  }, []);

  return (
    <>
      <W3LogoButton loggedIn={true} mimicTopNavBarLikePlacement={true} />

      <LoadingModal
        header="Loading..."
        message="Your session is being refreshed"
        show={true}
        full_page={true}
        close={() => {}}
      />
    </>
  );
}

export default RefreshSessionPage;
