import React from 'react';
import styles from './UserProfile.module.scss';
import { authUtil } from '../../Util/AuthUtil';
import CloseIcon from '../Icons/CloseIcon/CloseIcon';
import {
  getAppFromUrl,
  getLogoutUrl,
} from '../../Util/EnvironmentUtil';
import { sendGoogleAnalyticsEvent } from '../../Util/GoogleAnalyticsUtil';
import { logging } from '../../Util/LoggingUtil';
import { handleUserProfileClose } from '../../Util/RedirectUtil';

export interface UserProfileProps {
  full_page_mode?: boolean,
  loggedIn?: boolean,
  className?: string,
}

const DEFAULT_CLOSE_URL = 'https://www.w3schools.com';

function UserProfile({ className, loggedIn }: UserProfileProps) {
  function logOutUser(e: any) {
    e.preventDefault();

    sendGoogleAnalyticsEvent({
      name: 'Clicked on "Log out" in topbar when logged in',
      category: 'Topbar after login'
    });

    asyncLogOutUser();
  }

  async function asyncLogOutUser() {
    await authUtil.logOut({
      context: 'UPC1',
      reason: {
        name: 'Clicked on "Log out" in topbar when logged in',
        category: 'Topbar after login'
      }
    });

    if (['my-learning', 'spaces', 'profile'].includes(getAppFromUrl())) {
      window.location.href = getLogoutUrl();
    } else {
      window.location.href = DEFAULT_CLOSE_URL;
    }
  }

  function handleClickClose() {
    logging.logDebug('UserProfile.tsx -> handleClickClose -> init');

    handleUserProfileClose();
  }

  return (
    <div className={`${className ? ` ${className}` : ''}`}>
      {loggedIn ? (
        <button
          title='Log out'
          className={styles.logout_btn}
          onClick={logOutUser}
        >
          <div>Log out</div>
        </button>
      ) : (
        <button title='Close' onClick={handleClickClose} className='user-profile-component-close-btn'>
          <CloseIcon size={50} />
        </button>
      )}
    </div>
  );
}

export default UserProfile;
