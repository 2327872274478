import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

import UploadSuccess from '../../assets/icons/userlib/UploadSuccess';
import { NotificationType } from '../utils/TypesInterfaces';

import styles from './Notification.module.scss';

export interface INotification {
  notification: NotificationType;
  className?: string;
}

const Notification = ({ notification, className }: INotification) => (
  /* const [progressBarLevel, setProgressBarLevel] = useState<number | undefined>();
    useEffect(() => {
        setProgressBarLevel(notification.progressBarLevel);
    }, [notification.progressBarLevel]); */

  /* const [showProgressBar, setShowProgressBar] = useState(false);
    useEffect(() => {
        setShowProgressBar(notification.showProgressBar || false);
    }, [notification.showProgressBar]); */

  <div
    className={`single-notification ${styles.single}${className ? ` ${className}` : ''}`}
  >
    {notification.body}

    {notification.type !== 'error' && notification.progressBarLevel !== 100 ? (
      <div className={styles.filename}>{notification.filename}</div>
    ) : (
      ''
    )}

    {notification.progressBarLevel === 100 ? (
      <div className={styles.upload_success_icon}>
        <UploadSuccess />
      </div>
    ) : (
      ''
    )}

    {notification.showProgressBar && (
      <ProgressBar
        now={notification.progressBarLevel}
        className={
          notification.progressBarLevel === 100 ? 'progress-success' : ''
        }
        variant={notification.progressBarLevel === 100 ? 'success' : 'info'}
      />
    )}
  </div>
);

Notification.defaultProps = {
  className: undefined,
};

export default Notification;
