import {
  EmploymentType,
  EmploymentTypeVisual,
} from '../Component/userlib_new/utils/types-interfaces';

export const getContinentFullName = (code: string): string => {
  switch (code) {
    case 'AF':
      return 'Africa';
    case 'AN':
      return 'Antarctica';
    case 'AS':
      return 'Asia';
    case 'EU':
      return 'Europe';
    case 'NA':
      return 'North America';
    case 'OC':
      return 'Oceania';
    case 'SA':
      return 'South America';
    default:
      return 'Unknown Continent';
  }
};

export const formatNumber = (number: number) => {
  if (number < 1000) {
    return number.toString();
  } else if (number < 1000000) {
    // Calculate the number of thousands
    const thousands = Math.floor(number / 1000);

    // Calculate the remainder after dividing by 1000
    const remainder = number % 1000;

    if (remainder === 0) {
      return thousands + 'K';
    } else {
      return thousands + '.' + (remainder / 1000).toFixed(1).substring(2) + 'K';
    }
  } else {
    // Format as millions
    const millions = Math.floor(number / 1000000);
    const thousands = Math.floor((number % 1000000) / 1000);

    if (thousands === 0) {
      return millions + 'M';
    } else {
      return millions + '.' + (thousands / 1000).toFixed(1).substring(2) + 'M';
    }
  }
};

/**
 * A helper function to get the enum key by value for string enums
 * @param enumObj
 * @param value
 * @returns
 * @private
 */
function getEnumKeyByEnumValue<T extends Record<string, string>>(
  enumObj: T,
  value: string,
): keyof T | null {
  for (const key in enumObj) {
    if (enumObj[key] === value) {
      return key as keyof T;
    }
  }
  return null;
}

/**
 * Get the corresponding EmploymentTypeVisual value from a EmploymentType value.
 * @param key
 */
export const getEmploymentTypeVisualValue = (key: string) => {
  const typeString: string | null = getEnumKeyByEnumValue(EmploymentType, key);
  const employmentType =
    typeString !== null
      ? EmploymentTypeVisual[
          typeString as unknown as keyof typeof EmploymentTypeVisual
        ]
      : undefined;
  return employmentType;
};

export const camelToKebab = (key: string) =>
  key.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();

export const validateEmail = (email: string) => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
};

export const getMonthYearFromDate = (date: Date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
};

export const getISODate = (date: Date) => {
  return date.toISOString().split('T')[0];
};
