import { CustomTypeMod } from '../InterfaceAndTypeUtil';
import { fetch, IFetchPrs, IFetchResult } from '../FetchUtil';
import { EnvType, getEnv } from '../EnvironmentUtil';

export const getProfileApiBaseUrl = (env?: EnvType): string => {
  if (typeof env === 'undefined') {
    env = getEnv();
  }

  if (env === 'prod') {
    return 'https://api.w3profile.com/';
  } else if (env === 'staging') {
    return 'https://api.w3profile-stage.com/';
  } else if (env === 'develop') {
    return 'https://api.w3profile-dev.com/';
  }

  return 'https://api.w3profile.com/'; // prod fallback
};

export const fetchPublicProfile = async <T>(
  prs: IFetchPrs
): Promise<
  CustomTypeMod<
    IFetchResult,
    {
      data: T,
    }
  >
> => {
  prs.baseURL = getProfileApiBaseUrl();

  if (typeof prs.authHeaderKey === 'undefined') {
    prs.authHeaderKey = 'Authorization';
  }

  return await fetch(prs);
};
