import { v4 as uuidv4 } from 'uuid';

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: { x: number; y: number; width: number; height: number },
  format = 'image/jpeg',
): Promise<any> {
  const image = new Image();
  image.src = imageSrc;

  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('2D context is not available');
  }

  // Draw the image at the correct location on the canvas
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  // Convert the canvas to a Blob, then create a URL for that Blob
  const fileName = `${uuidv4()}.${format.split('/')[1]}`;
  const url = new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }

      (blob as any).name = fileName;
      window.URL.revokeObjectURL(image.src); // Clean up the old image URL
      resolve(window.URL.createObjectURL(blob));
    }, format);
  });

  return {
    url: await url,
    fileName,
  };
}
