import React from 'react';
import GoogleTagManager from 'react-gtm-module';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import Base from './Component/Base/Base';
// import reportWebVitals from './reportWebVitals';
import uiPackageJson from '../package.json';
import sharedLibPackageJson from './SharedLib/package.json';

console.log('Profile version: ', uiPackageJson.version);
console.log('Shared lib version: ', sharedLibPackageJson.version);

GoogleTagManager.initialize({
  gtmId: 'GTM-KTCFC3S',
  dataLayer: {
    js: new Date(),
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Base version={uiPackageJson.version} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
