import React from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { BsCloudCheck } from 'react-icons/bs';
import { useProfile } from '../../../../context/profileProvider';
import { ChevronDownIcon } from '@heroicons/react/solid';
import ConfirmProfileSubmission from './ConfirmProfileSubmission';
import { getPublicProfileUrl } from '../../../../utils/pathsUtil';

interface IProfileProps {
  borderColor?: string;
}

const StickyHeader = ({ borderColor }: IProfileProps) => {
  const profileData = useProfile();

  const handlePublicProfileClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!profileData.nickname) {
      e.preventDefault();
      profileData.showMessage(
        'Please choose a unique nickname to access your public profile.',
        'info',
      );
    }
  };

  return (
    <>
      <Box
        display={{ base: 'flex' }}
        mx="auto"
        maxW={'1150px'}
        alignItems={{ base: 'flex-start', sm: 'center' }}
        flexDir={{ base: 'column-reverse', lg: 'row' }}
        top={0}
        _dark={{ bg: 'gray.800' }}
      >
        <Flex justifyContent="flex-end" width="full" mr={2}>
          <Menu>
            <MenuButton
              px={4}
              py={2}
              mr={2}
              transition="all 0.2s"
              borderRadius="md"
              borderWidth="1px"
              _hover={{ bg: useColorModeValue('gray.100', 'alphaWhite.100') }}
              _expanded={{
                bg: useColorModeValue('gray.100', 'alphaWhite.100'),
              }}
              _focus={{ boxShadow: 'outline' }}
              width="max-content"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Flex align="center" justify="center">
                <Text fontWeight={700}>
                  Preview <Icon as={ChevronDownIcon} ml={2}></Icon>
                </Text>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem
                as="a"
                href={getPublicProfileUrl() + '/' + profileData.nickname}
                target="_blank"
                onClick={handlePublicProfileClick}
              >
                Public Profile
              </MenuItem>
            </MenuList>
          </Menu>
          <Button
            isDisabled={profileData.disableSaveProfileButton}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
            size={'md'}
            height={'44px'}
            minWidth={'116px'}
            _disabled={{ color: '#b9bfc1', backgroundColor: '#e7e7e7' }}
            _hover={
              !profileData.disableSaveProfileButton
                ? { borderColor: 'gray.400' }
                : {}
            }
            variant={'outline'}
            onClick={profileData.handleSaveClick}
            isLoading={profileData.isProfileSaving}
          >
            <BsCloudCheck /> Save
          </Button>

          <ConfirmProfileSubmission
            isOpen={profileData.confirmProfileSubmissionModalOpen}
            onClose={() =>
              profileData.setConfirmProfileSubmissionModalOpen(false)
            }
            onContinue={profileData.handleProfileSubmit}
            dailyQuataTotal={profileData.dailyQuataTotal}
            dailyQuataRemain={profileData.dailyQuataRemain}
          />
        </Flex>
      </Box>
    </>
  );
};

export default StickyHeader;
