import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

export const defaultSpinnerAttributes = {
  thickness: '3px',
  speed: '0.65s',
  emptyColor: 'gray.200',
  color: 'green.600',
  size: 'md',
};

export default function Loading() {
  return (
    <Box>
      <Spinner {...defaultSpinnerAttributes} />
    </Box>
  );
}
