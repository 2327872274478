'use client';
import { defineStyleConfig } from '@chakra-ui/react';

/**
 * Button Style Config reference
 * https://chakra-ui.com/docs/components/button/theming
 */

const disabledStyles = {
  backgroundColor: '#e7e7e7',
  color: '#b9bfc1',
  border: '1px solid #b9bfc1',
  opacity: 1,
  _hover: {
    backgroundColor: '#e7e7e7',
    color: '#b9bfc1',
    border: '1px solid #b9bfc1',
    opacity: 1,
  },
};

const ButtonStyleConfig = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: '25px',
    _disabled: disabledStyles,
    _dark: {
      _disabled: disabledStyles,
    },
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: 'md',
      px: 7, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
    lg: {
      fontSize: 'md',
      px: 10, // <-- these values are tokens from the design system
      py: 5, // <-- these values are tokens from the design system
    },
  },
  variants: {
    primary: {
      backgroundColor: '#04aa6d',
      border: '1px solid #04aa6d',
      color: 'white',
      _hover: {
        backgroundColor: '#059862',
      },
      // _disabled: disabledStyles ,
      // _dark: {
      //   _disabled: disabledStyles,
      // }
    },
    primaryOutline: {
      backgroundColor: 'transparent',
      border: '1px solid #04aa6d',
      color: '#04aa6d',
      _hover: {
        backgroundColor: '#059862',
        color: '#fff',
      },
      // _disabled: disabledStyles ,
      // _dark: {
      //   _disabled: disabledStyles,
      // }
    },
    plain: {
      backgroundColor: 'transparent',
      color: '#000',
      borderColor: 'rgba(0,0,0,0.7)',
      borderWidth: '1px',
      borderRadius: '0.375rem',
      padding: '0.5rem 1rem',
      // _disabled: disabledStyles ,
      _dark: {
        color: '#fff',
        borderColor: 'white',
      },
    },
    plainRounded: {
      backgroundColor: 'transparent',
      color: '#000',
      borderColor: 'rgba(0,0,0,0.7)',
      borderWidth: '1px',
      // _disabled: disabledStyles ,
      _dark: {
        color: '#fff',
        borderColor: 'white',
      },
    },
    dark: {
      backgroundColor: '#282a35',
      border: '1px solid #282a35',
      color: 'white',
      _hover: {
        backgroundColor: 'black',
      },
      // _disabled: disabledStyles,
      // _dark: {
      //   _disabled: disabledStyles,
      // }
    },
    light: {
      backgroundColor: '#fff',
      border: '1px solid #fff',
      color: 'black',
      _hover: {
        backgroundColor: '#f2f2f2',
      },
      // _disabled: disabledStyles,
      // _dark: {
      //   _disabled: disabledStyles,
      // }
    },
    danger: {
      backgroundColor: '#d9212c',
      border: '1px solid #d9212c',
      color: 'white',
      _hover: {
        backgroundColor: '#c5212c',
      },
      // _disabled: disabledStyles,
      // _dark: {
      //   _disabled: disabledStyles,
      // }
    },
    purple: {
      backgroundColor: '#9763f6',
      border: '1px solid #9763f6',
      color: 'white',
      _hover: {
        backgroundColor: '#7d53cc',
      },
      // _disabled: disabledStyles,
      // _dark: {
      //   _disabled: disabledStyles,
      // }
    },
  },
  //The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
});

export default ButtonStyleConfig;
