import { IconType } from 'react-icons';
import {
  FaHtml5,
  FaCss3Alt,
  FaJsSquare,
  FaPython,
  FaUsers,
  FaJava,
  FaReact,
  FaNodeJs,
  FaPhp,
  FaGit,
  FaAws,
  FaVuejs,
  FaAngular,
  FaSass,
  FaBootstrap,
  FaChartBar,
  FaDatabase,
  FaCode,
  FaRegFileExcel,
  FaRProject,
  FaUniversalAccess,
  FaLock,
  FaCloud,
  FaLaravel,
} from 'react-icons/fa';
import {
  SiCplusplus,
  SiDjango,
  SiPandas,
  SiScipy,
  SiCsharp,
  SiC,
  SiKotlin,
  SiTypescript,
  SiMongodb,
  SiMysql,
  SiPostgresql,
  SiThealgorithms,
} from 'react-icons/si';
import { BiChart, BiLogoJquery } from 'react-icons/bi';
import { TbFileTypeXml } from 'react-icons/tb';
import { BsPersonFill } from 'react-icons/bs';

export const skillIconMap: Readonly<Record<string, IconType>> = {
  'b00bea3e-119c-4567-af54-fc288d884f80': SiTypescript,
  'b7545a4d-959b-4845-8439-9bf82dfe42a2': FaRegFileExcel,
  '45abcd1f-b3a6-415d-a443-97a033bfbc54': SiKotlin,
  'e7e4f205-19a0-459c-af34-fd46a5cfd320': SiPandas,
  '61583b4d-86a2-48d7-8982-22d1849b0d7a': BiChart,
  'f4cfc4c9-ed2e-4fd5-ad21-1b681bcd10c7': BsPersonFill,
  '68b05425-6005-4460-b003-0af5cc3d40fc': FaBootstrap,
  'f3a9c7f4-4ad1-4f0a-b000-c9d1005dde52': FaNodeJs,
  '34b87386-2421-4e8f-bbab-fc4ac4404ffc': FaSass,
  '2c268052-53bd-4e42-a4fe-22e87c90a040': FaReact,
  '579de9dc-b9d6-48f2-b127-ee896e9a82f4': SiDjango,
  'afa1cc12-6ecd-475c-a7f1-a130452e617e': SiCsharp,
  '9eb290f1-fc77-470c-988c-c47f26cfcf86': FaHtml5,
  '9cbcc948-ef85-43af-bc45-126225c89ec5': FaDatabase,
  '304a5801-6670-43c1-8e48-3fb45dd31f4c': SiCplusplus,
  'd9d33465-bb26-40af-9181-a949de2f5b44': FaCss3Alt,
  '86f520e1-2c27-4d0b-a2e1-18fd3da3cd90': SiScipy,
  'de59ebd4-808e-4371-b2bc-263c87054e2c': FaGit,
  'e98893f7-8429-4dcb-b3e3-80906687cd2d': BiLogoJquery,
  '6b483f7a-86d9-4116-9431-cf4864c0ce80': FaBootstrap,
  'd126d3f7-c96e-467e-8401-d695ce4cd99a': FaBootstrap,
  '7d8fb088-8e17-445f-b18e-02986ac60a3f': SiPostgresql,
  'b9273482-f720-4bc7-9c0d-718ab794d35d': SiMysql,
  'e7b62fff-97f0-4796-b2fc-7b74f0fb2ddf': FaChartBar,
  'b5f4abcb-e56b-43af-a50b-f7a18b31b68d': FaAws,
  '81496c2f-05f1-4ea0-a6f0-e3daab8657f9': FaAws,
  '0c26289b-3f19-4100-8daf-c8fbd164dffc': FaUsers,
  '1e5d18ce-1c36-4f75-9005-62c37cef89c3': SiC,
  '5298914a-097d-41d0-b9ff-1fbab2423fc8': FaCode,
  '8ac18555-48c1-4c6a-9ba8-57bd9f3a1f42': FaCode,
  '3dc215c3-f868-46a3-8da2-095634c290be': FaLock,
  'bb1b8277-9e7b-4a83-b2e2-ff24b94b629f': FaCss3Alt,
  '75e13f34-a8d9-4e12-8fdf-47f4abaab487': FaJsSquare,
  '2c77239b-0338-43c3-a22f-89335c6d0809': TbFileTypeXml,
  '39e3a5c1-4d5a-49b2-a0bc-87c751fc8f73': FaPhp,
  '54eb1f83-06c3-4601-bec3-8ad8259fc936': FaCloud,
  '63e364b7-cbab-4590-b1d3-aa8fae633099': FaAngular,
  'a391cfc8-cdbc-437f-9a3a-b7cb8bd0543b': SiMongodb,
  '0b45f5e9-e5ad-43f9-8b49-dcc82a33195a': FaPython,
  '5e3956b4-e556-4fd0-99b0-68c5b488157d': FaVuejs,
  'd27d4b50-cec9-469c-b08a-05fb1ab3ec6b': FaPython,
  'bded6322-1096-4338-8c45-53cdeb24fdeb': FaRProject,
  '24f8c774-2263-4403-9894-abae46b5a235': FaUniversalAccess,
  '7fd10b8f-82de-4f93-a382-5a8d30fff1c8': FaJava,
  '78e00084-0212-4f21-b130-481a66e92196': SiThealgorithms,
  'd1f67e4b-8746-41c1-baf6-61c79d9d1a34': FaLaravel,
  unknown: FaCode, // Fallback icon
};

export const getSkillIcon = (skillId: string) => {
  return skillIconMap[skillId] || skillIconMap.unknown; // Fallback to 'unknown' if no icon is found
};

export const determineIcon = (skillId = 'unknown') => skillIconMap[skillId];
