import React from 'react';
import {
  Box,
  VStack,
  HStack,
  Input,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Text,
} from '@chakra-ui/react';
import { IEducation } from '../../../userlib_new/utils/profile-interfaces';
import { AccordionSection } from './AccordionSection';
import { useProfile } from '../../../../context/profileProvider';
import { BsPlusCircle } from 'react-icons/bs';
import DatePickerComponent from '../../../ReactDatePicker/ReactDatePicker';
import 'react-datepicker/dist/react-datepicker.css';

interface IEducationProps {
  educationData: IEducation[];
}

export const Education = ({ educationData }: IEducationProps) => {
  const profileData = useProfile();
  return (
    <AccordionSection title="Education" sectionKey="education">
      <VStack spacing={4} align="stretch">
        {educationData.length > 0 ? (
          educationData.map((edu, index) => (
            <Box key={index} p={5} borderWidth="1px" borderRadius="lg">
              <VStack spacing={3} align="stretch">
                <FormControl
                  isRequired
                  isInvalid={profileData.invalidEducationFields[index]?.degree}
                >
                  <FormLabel htmlFor={`degree-${index}`}>Degree</FormLabel>
                  <Input
                    id={`degree-${index}`}
                    value={edu.degree ?? ''}
                    onChange={(e) =>
                      profileData.handleEducationChange(
                        index,
                        'degree',
                        e.target.value,
                      )
                    }
                    _dark={{ bg: '#3E4252' }}
                  />
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    profileData.invalidEducationFields[index]?.institution
                  }
                >
                  <FormLabel htmlFor={`institution-${index}`}>
                    University / School / Company name
                  </FormLabel>
                  <Input
                    id={`institution-${index}`}
                    value={edu.institution ?? ''}
                    onChange={(e) =>
                      profileData.handleEducationChange(
                        index,
                        'institution',
                        e.target.value,
                      )
                    }
                    _dark={{ bg: '#3E4252' }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor={`place-${index}`}>Location</FormLabel>
                  <Input
                    id={`place-${index}`}
                    value={edu.place ?? ''}
                    onChange={(e) =>
                      profileData.handleEducationChange(
                        index,
                        'place',
                        e.target.value,
                      )
                    }
                    _dark={{ bg: '#3E4252' }}
                  />
                </FormControl>
                <HStack>
                  <FormControl>
                    <FormLabel htmlFor={`from-${index}`}>From</FormLabel>
                    <DatePickerComponent
                      id={`edu-from-${index}`}
                      selected={edu.startDate}
                      onChange={(date) => {
                        profileData.handleEducationChange(
                          index,
                          'startDate',
                          date,
                        );
                      }}
                      dateFormat="MM-yyyy"
                      placeholderText="MM-yyyy"
                      showMonthYearPicker
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor={`to-${index}`}>To</FormLabel>
                    <DatePickerComponent
                      id={`edu-to-${index}`}
                      selected={edu.endDate}
                      onChange={(date) => {
                        profileData.handleEducationChange(
                          index,
                          'endDate',
                          date,
                        );
                      }}
                      dateFormat="MM-yyyy"
                      placeholderText="MM-yyyy"
                      showMonthYearPicker
                    />
                  </FormControl>
                </HStack>
                <FormControl>
                  <FormLabel htmlFor={`description-${index}`}>
                    Description
                  </FormLabel>
                  <Textarea
                    id={`description-${index}`}
                    value={edu.description ?? ''}
                    onChange={(e) =>
                      profileData.handleEducationChange(
                        index,
                        'description',
                        e.target.value,
                      )
                    }
                    _dark={{ bg: '#3E4252' }}
                  />
                </FormControl>
                <Button
                  variant="danger"
                  onClick={() => profileData.deleteEducationEntry(index)}
                  alignSelf="flex-start"
                  size="xs"
                >
                  Delete
                </Button>
              </VStack>
            </Box>
          ))
        ) : (
          <Text>No education available.</Text>
        )}
        <Button
          rightIcon={<BsPlusCircle />}
          onClick={profileData.addEducationEntry}
          alignSelf="flex-start"
          variant="outline"
        >
          Add
        </Button>
      </VStack>
    </AccordionSection>
  );
};
