export enum ComponentTypes {
  Interests = 'interests',
  Spaces = 'spaces',
  Achievements = 'achievements',
  Activity = 'activity',
  Discord = 'discord',
  Certificates = 'certificates',
}

export enum SocialMediaTypes {
  Twitter = 'Twitter',
  LinkedIn = 'LinkedIn',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  GitHub = 'GitHub',
  Phone = 'Phone',
  Email = 'Email',
}

export interface IDefault {
  type: ComponentTypes;
  visible: boolean;
}

export interface ISocialMedia {
  type: SocialMediaTypes;
  userName: string;
  visible: boolean;
}

export interface IBasicSection {
  visible: boolean;
  value: string;
}
export interface IBasic {
  profilePicture?: string;
  firstName: string; // same as in settings/profile page
  lastName: string; // same as in settings/profile page
  aboutMe?: string;
  phone: IBasicSection;
  email: IBasicSection;
  website: IBasicSection;
}

export type TagType = {
  tagId: string;
  tagName: string;
};

export type SpaceType = {
  spaceId: string;
  spaceUrl: string;
  visible: boolean;
  pictureUrl?: string;
  description?: string;
};

export type CertificateType = {
  id: string;
  verifyUrl: string;
  title: string;
  dateCompleted: number;
  visible: boolean;
};

export interface IInterests extends IDefault {
  tags: TagType[];
}

export interface ISpaces extends IDefault {
  spaces: SpaceType[];
}

export interface ICertificates extends IDefault {
  certificates: CertificateType[];
}

export interface IActivity extends IDefault {
  stars: number;
  lessonsRead: number;
  exercisePoints: number;
  quizPoints: number;
  totalPoints: number;
}

export interface IDiscord extends IDefault {
  user: string;
  role: string;
  messages: string;
  experience: string;
  level: number;
}

export interface IUserProfileConfiguration {
  id?: string;
  userId?: string;
  published: boolean;
  basic: IBasic;
  createdAt?: number;
  updatedAt?: number;
  socialMedias?: ISocialMedia[];
  sections: (
    | IDiscord
    | IActivity
    | ICertificates
    | ISpaces
    | IInterests
    | IDefault
  )[];
}

export interface IReportAbuse {
  name: string;
  email: string;
  profile: string;
  description: string;
}

export interface IPublicProfileUserData extends IBasic {
  nick?: string;
  createdAt?: number;
  updatedAt?: number;
}
