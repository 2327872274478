import React, { createContext, useContext, useState, ReactNode } from 'react';

interface UnsavedChangesContextProps {
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (hasChanges: boolean) => void;
}

const UnsavedChangesContext = createContext<UnsavedChangesContextProps>({
  hasUnsavedChanges: false,
  setHasUnsavedChanges: () => void 0,
});

export const useUnsavedChanges = () => useContext(UnsavedChangesContext);

interface UnsavedChangesProviderProps {
  children: ReactNode;
}

export const UnsavedChangesProvider: React.FC<UnsavedChangesProviderProps> = ({
  children,
}) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  return (
    <UnsavedChangesContext.Provider
      value={{ hasUnsavedChanges, setHasUnsavedChanges }}
    >
      {children}
    </UnsavedChangesContext.Provider>
  );
};
