import React from 'react';
import AcademyBanner from '../AcademyBanner/AcademyBanner';
import LoginLayout from '../LoginLayout/LoginLayout';
import NewLoginPage from '../NewLogin/NewLoginPage';
import styles from './LoginFullPage.module.scss';

const LoginFullPage = () => {
  return (
    <>
      <AcademyBanner />
      <div className={styles.loginWrapper}>
        <LoginLayout children={<NewLoginPage />} />
      </div>
    </>
  );
};

export default LoginFullPage;
