import React from 'react';
import {
  Avatar,
  Box,
  ResponsiveValue,
  chakra,
  useColorMode,
  Icon,
} from '@chakra-ui/react';
import { BiBuildings } from 'react-icons/bi';
import { BsCheckCircleFill, BsLockFill } from 'react-icons/bs';

import styles from './W3sAvatar.module.css';
import LowFiIcon from '../icons/LowFiIcon/LowFiIcon';

import LogoIcon from '../icons/LogoIcon/LogoIcon';
import { getSkillIcon } from '../../lib/skillIconMap';

export interface IAvatar {
  useBuildingAsDefault?: boolean;
  useBlankAsDefault?: boolean;
  src: string | undefined;
  className?: string;
  size?: ResponsiveValue<string>;
  displayLock?: boolean;
  displayHeart?: boolean;
  displayCheck?: boolean;
  skillId?: string;
  defaultAvatar?: boolean;
  useLogoIcon?: boolean;
}

const W3sAvatarComponent = ({
  displayHeart = false,
  displayCheck = false,
  displayLock = false,
  size = 'lg',
  useBuildingAsDefault = false,
  useBlankAsDefault = false,
  skillId = undefined,
  src,
  className,
  defaultAvatar = false,
  useLogoIcon = false,
}: IAvatar) => {
  const { colorMode } = useColorMode();
  const regex = /(?:\.([^.]+))?$/;

  let ext: string | undefined;

  if (src) {
    ext = (regex.exec(src) ?? []).at(1);
    // check if first 3 char is svg
    if (ext?.substring(0, 3) === 'svg') {
      ext = 'svg';
    }
  }

  const getIcon = (id?: string) => {
    if (id) {
      const icon = getSkillIcon(id);
      return <Icon as={icon} zIndex={5} />;
    }

    if (useBlankAsDefault) {
      return (
        <BiBuildings
          className={`${styles.building}${size === 'sm' ? ` ${styles.small}` : ''}`}
        />
      );
    } else if (useBuildingAsDefault) {
      return <LowFiIcon />;
    } else if (useLogoIcon) {
      return <LogoIcon />;
    } else {
      return undefined;
    }
  };

  return (
    <Avatar
      borderRadius="full"
      src={src}
      {...(!defaultAvatar && {
        bg:
          useBlankAsDefault || ext === 'svg'
            ? 'transparent'
            : useLogoIcon
              ? 'gray.100'
              : 'green.400',
      })}
      loading="lazy"
      size={size}
      pos={'relative'}
      icon={!defaultAvatar ? getIcon(skillId) : undefined}
      className={className ?? ''}
      border={useLogoIcon ? '1.5px dashed #4C535F' : 'none'}
      sx={{
        '& > img': {
          objectFit: ext === 'svg' || useLogoIcon ? 'contain' : 'cover',
        },
      }}
    >
      {displayLock && (
        <Box pos={'absolute'} right={'-8px'} bottom={'0px'} color={'gray.500'}>
          <BsLockFill />
        </Box>
      )}

      {displayHeart && (
        <Box pos={'absolute'} right={'-14px'} bottom={'5px'}>
          <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.49486 2.9364C6.99535 1.10151 4.49481 0.607928 2.61602 2.28814C0.737233 3.96835 0.472726 6.77758 1.94815 8.76477C3.17486 10.417 6.88733 13.9016 8.10408 15.0295C8.2402 15.1557 8.30827 15.2187 8.38766 15.2435C8.45695 15.2652 8.53278 15.2652 8.60207 15.2435C8.68146 15.2187 8.74953 15.1557 8.88565 15.0295C10.1024 13.9016 13.8149 10.417 15.0416 8.76477C16.517 6.77758 16.2848 3.95067 14.3737 2.28814C12.4626 0.625602 9.99438 1.10151 8.49486 2.9364Z"
              fill="white"
              stroke="#282A35"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.5962 6.45204C13.4765 5.14723 11.6095 4.79624 10.2066 5.99105C8.8038 7.18587 8.6063 9.18354 9.70795 10.5967C10.6239 11.7716 13.3959 14.2495 14.3044 15.0516C14.406 15.1413 14.4568 15.1862 14.5161 15.2038C14.5679 15.2192 14.6245 15.2192 14.6762 15.2038C14.7355 15.1862 14.7863 15.1413 14.888 15.0516C15.7965 14.2495 18.5684 11.7716 19.4844 10.5967C20.586 9.18354 20.4126 7.1733 18.9857 5.99105C17.5588 4.8088 15.7158 5.14723 14.5962 6.45204Z"
              fill="white"
              stroke="#282A35"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      )}

      {displayCheck && (
        <Box
          pos={'absolute'}
          right={'-14px'}
          bottom={'5px'}
          color={'blackAlpha.700'}
          className={colorMode === 'dark' ? styles.check_dark : ''}
        >
          <BsCheckCircleFill />
        </Box>
      )}
    </Avatar>
  );
};

const W3sAvatar = chakra(W3sAvatarComponent);

export default W3sAvatar;
