import React, { useCallback, useMemo } from 'react';
import {
  Input,
  Stack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  Switch,
  Box,
} from '@chakra-ui/react';
import {
  IContact,
  IContactField,
} from '../../../userlib_new/utils/profile-interfaces';
import { IconType } from 'react-icons';
import { AccordionSection } from './AccordionSection';
import {
  FaEnvelope,
  FaGithub,
  FaLinkedin,
  FaPhone,
  FaTwitter,
} from 'react-icons/fa';
import { useProfile } from '../../../../context/profileProvider';

interface IContactInfoProps {
  contactData: IContact;
  inputBgColor?: string;
}

const allFields = {
  email: {
    icon: FaEnvelope,
    label: 'Email',
  },
  phone: {
    icon: FaPhone,
    label: 'Phone Number',
  },
  linkedin: {
    icon: FaLinkedin,
    label: 'LinkedIn Username',
  },
  twitter: {
    icon: FaTwitter,
    label: 'Twitter Username',
  },
  github: {
    icon: FaGithub,
    label: 'GitHub Username',
  },
};

export const ContactInfo = ({
  contactData,
  inputBgColor,
}: IContactInfoProps) => {
  const profileData = useProfile();

  const renderContactInputGroup = useCallback(
    (
      field: keyof IContact,
      icon: IconType,
      label: string,
      contactField?: IContactField,
    ) => {
      return (
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={icon} color="gray.500" />
          </InputLeftElement>
          <Input
            id={`contact-${field}`}
            placeholder={label}
            bg={inputBgColor}
            value={contactField?.value ?? ''}
            onChange={(e) =>
              profileData.handleContactChange(field, 'value', e.target.value)
            }
            _dark={{ bg: '#3E4252' }}
          />
          <InputRightElement width="4.5rem">
            <Switch
              id={`switch-${field}`}
              size="md"
              colorScheme="green"
              isChecked={contactField?.visible ?? false}
              onChange={(e) =>
                profileData.handleContactChange(
                  field,
                  'visible',
                  e.target.checked,
                )
              }
            />
          </InputRightElement>
        </InputGroup>
      );
    },
    [inputBgColor, profileData],
  );

  const contactInputGroup = useMemo(() => {
    return Object.entries(allFields).map(([field, fieldInfo]) => {
      return (
        <Box key={field}>
          {' '}
          {renderContactInputGroup(
            field as keyof IContact,
            fieldInfo.icon,
            fieldInfo.label,
            contactData[field as keyof IContact],
          )}
        </Box>
      );
    });
  }, [contactData, renderContactInputGroup]);

  return (
    <AccordionSection title="Contact Information" sectionKey="contacts">
      <Stack spacing={4}>{contactInputGroup}</Stack>
    </AccordionSection>
  );
};
