import { VStack } from '@chakra-ui/react';
import { Select as SelectWithSearch } from 'chakra-react-select';
import React from 'react';
import { useProfile } from '../../../../context/profileProvider';
import { ITagType } from '../../../userlib_new/utils/profile-interfaces';
import { AccordionSection } from './AccordionSection';

interface IInterestsProps {
  interests: ITagType[];
}

export const Interests = ({ interests }: IInterestsProps) => {
  const profileData = useProfile();

  return (
    <AccordionSection title="Interest" sectionKey="interests">
      <VStack align="stretch" spacing={4}>
        <SelectWithSearch
          id="interests"
          maxMenuHeight={150}
          options={profileData.availableInterests}
          menuPortalTarget={document.body}
          placeholder="Add..."
          onChange={profileData.handleInterestChange}
          isMulti
          variant="outline"
          menuPlacement="auto"
          value={interests.map((interest) => ({
            value: interest.tagId,
            label: interest.tagName,
          }))}
        />
      </VStack>
    </AccordionSection>
  );
};
