import { EffectCallback, useEffect } from 'react';

type ConditionalWrapperProps = {
    children: React.ReactElement;
    condition: boolean;
    wrapper: (children: React.ReactElement) => JSX.Element;
};

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children

// eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
export const onComponentDidMount = (effect: EffectCallback) => useEffect(
    effect,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentDidMount']
)
